import get from 'lodash/get';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { DATETIME_FORMAT, DATE_FORMAT } from './constants';

type Formatter = (val: any) => string;
interface GetValue {
  (item?: object, path?: string, formatter?: Formatter): any;
}

const NA = 'Nema podataka';

interface GetValueNumber {
  (item?: object, path?: string, formatter?: Formatter): number;
}

export const getValueNumber: GetValueNumber = (item, path, formatter) => {
  if (!item || !path) {
    return NA;
  }
  const value = get(item, path, NA);

  if (formatter && value !== NA) {
    return formatter(value);
  }

  return value;
};

const getValue: GetValue = (item, path, formatter) => {
  if (!item || !path) {
    return NA;
  }
  const value = get(item, path, NA) || NA;

  if (formatter && value !== NA) {
    return formatter(value);
  }

  return value;
};

export const getValueOrEmptyString: GetValue = (item, path, formatter) => {
  if (!item || !path) {
    return '';
  }
  const value = get(item, path) || '';

  if (formatter && value !== '') {
    return formatter(value);
  }

  return value;
};

export const getValueOrNull: GetValue = (item, path, formatter) => {
  if (!item || !path) {
    return null;
  }
  const value = get(item, path) || null;

  if (formatter && value !== null) {
    return formatter(value);
  }

  return value;
};

export const getPhoneNumberValue = (phoneNumber: string) => {
  if (!phoneNumber) return NA;

  if (phoneNumber === NA) return NA;

  if (phoneNumber[0] !== '+') {
    return `+${phoneNumber}`;
  }
  return phoneNumber;
};

export const attributesJoin = (object: any, ...accessors: string[]) => {
  const result: string[] = [];
  accessors.forEach(accessor => result.push(getValue(object, accessor)));

  return uniq(result).join(' ');
};

export const getDateTime = (date: string, noDateText?: string) => {
  const formatedDate = moment(date).format(DATETIME_FORMAT);

  if (formatedDate === 'Invalid date') {
    return noDateText || 'Nema podataka o datumu';
  }
  return formatedDate;
};

export const getDate = (date: string, noDateText?: string) => {
  const formatedDate = moment(date).format(DATE_FORMAT);

  if (formatedDate === 'Invalid date') {
    return noDateText || 'Nema podataka o datumu';
  }
  return formatedDate;
};

export default getValue;
