import { observable, action, runInAction } from 'mobx';
import { FormikProps } from 'formik';

import to from 'lib/awaitTo';
import { Bundle, ClientBundle } from 'data/types';
import { navigate } from 'lib/routerHelper';

import clientBundlesRepo from 'data/repositories/ClientBundlesRepo';
import clientsRepo from 'data/repositories/ClientsRepo';

import TableStore from 'data/stores/TableStore';
import { authStore } from 'data/stores/AuthStore';
import { warehouseStore } from 'pages/Warehouse/WarehousePageStore';
import { translationStore } from 'data/stores/TranslationStore';
import { bundlesStore } from 'data/stores/BundlesStore';
import clientBundlesAnonymousRepo from 'data/repositories/ClientBundlesAnonymousRepo';
import { Toast } from 'lib/dialogs';

class BuyPackageStore extends TableStore<any> {
  @observable successfulPurchase = false;

  @observable clientMobileNumber = '';

  @observable fullFormData: any = null;

  @observable bundleId = '';

  @observable payslipFile = {} as any;

  @observable createdClientBundle = {} as ClientBundle;

  @observable anonymousInformations = {} as any;

  @observable showSendEmailModal = false;

  @observable selectedBundleIndex = null as number | null;

  @observable selectedBundle = undefined as Bundle | undefined;

  @observable isItIno = false;

  @observable boughtBundle = undefined as Bundle | undefined;

  @observable isPromo = false;

  @action
  setSelectedBundleIndex = (bundle: Bundle, isPromo: boolean = false) => {
    this.isPromo = isPromo;
    const { promoBundles, bundles } = bundlesStore;
    this.selectedBundleIndex = isPromo
      ? promoBundles.findIndex(b => b.id === bundle.id)
      : bundles.findIndex(b => b.id === bundle.id);
  };

  @action
  resetStoreState = () => {
    this.successfulPurchase = false;
    this.selectedBundle = undefined;
    this.isItIno = false;
    this.selectedBundleIndex = null;
    this.isPromo = false;
  };

  @action
  setSelectedBundle = () => {
    const { bundles, promoBundles } = bundlesStore;
    if (this.isPromo) {
      this.selectedBundle = promoBundles[this.selectedBundleIndex!];
    } else {
      this.selectedBundle = bundles[this.selectedBundleIndex!];
      this.isItIno = this.selectedBundle.bundleType === 'ino';
    }
    bundlesStore.splitFeaturesByCategoryTitle(this.selectedBundle!.features);
  };

  @action
  activateBundle = async (formData: any, bundleId: any, history: any) => {
    this.boughtBundle = this.selectedBundle;
    const { translations } = translationStore;
    const { isTypePayslipPayment, company } = authStore;
    const { promoBundles } = bundlesStore;

    this.bundleId = bundleId;
    this.fullFormData = {
      ...formData,
      smsMessage: translations.smsMessage
    };

    // Promo Bundle can't be Payslip payment
    let isPromoBundle = false;
    if (isTypePayslipPayment && promoBundles.length > 0 ) {
      isPromoBundle = !!promoBundles.find(b => b.id === bundleId);
    }

    const [response, error] = await to(
      clientBundlesRepo.activateClientBundle(
        this.fullFormData,
        bundleId,
        company.id,
        isPromoBundle ? false : isTypePayslipPayment
      )
    );

    if (error) {
      if (
        error.status === 'error' &&
        error.errors.key === 'TWILIO_SERVICE_ERROR'
      ) {
        navigate('boughtPackagePage', { history }, { bundleId });
      }
    }

    if (response) {
      runInAction(() => {
        this.successfulPurchase = true;
        this.clientMobileNumber = formData.phoneNum;
        this.createdClientBundle = response;
      });

      // For New Payslip Payment
      if (response.digitalAffiliatePayslip) {
        runInAction(() => {
          this.payslipFile = {
            fileName: response.digitalAffiliatePayslip.fileName,
            filePath: response.digitalAffiliatePayslip.filePath
          };
        });
      }

      // Fetch again how many cards does MapObject have
      warehouseStore.fetchWarehouseData(authStore.mapObject.id);
      navigate('boughtPackagePage', { history }, { bundleId });
    }
  };

  checkDoesClientExistsByMobileNum = async (
    mobileNum: string,
    formikProps: FormikProps<any>
  ) => {
    // Set submtting so fields for name and email will be disabled until the request is done
    formikProps.setSubmitting(true);
    if (mobileNum === '' || mobileNum === '381') {
      return null;
    }
    const qParams = {
      include: ['user']
    };
    const [res, err] = await to(
      clientsRepo.checkClientByMobileNum(mobileNum, { params: qParams })
    );

    if (res) {
      formikProps.setSubmitting(false);
      if (res.user) {
        formikProps.setFieldValue('userFirstName', res.user.firstName);
        formikProps.setFieldValue('userLastName', res.user.lastName);
        formikProps.setFieldValue('userEmail', res.user.email);
      }
    } else if (err) {
      formikProps.setSubmitting(false);
      formikProps.setFieldValue('userFirstName', '');
      formikProps.setFieldValue('userLastName', '');
      formikProps.setFieldValue('userEmail', '');
    }

    return null;
  };

  @action
  createAnonymousPackage = async (
    bundleId: string,
    history: any,
    voucher?: string
  ) => {
    this.boughtBundle = this.selectedBundle;
    const { company } = authStore;

    this.bundleId = bundleId;

    this.fullFormData = {
      bundleId,
      companyId: company.id,
      voucher: voucher || null
    };
    const [response, error] = await to(
      clientBundlesAnonymousRepo.create(this.fullFormData)
    );

    if (error) {
      if (
        error.status === 'error' &&
        error.errors.key === 'TWILIO_SERVICE_ERROR'
      ) {
        navigate('boughtPackageAnonymousPage', { history }, { bundleId });
      }
    }

    if (response) {
      runInAction(() => {
        this.successfulPurchase = true;
        this.anonymousInformations = response.data;
      });

      navigate('boughtPackageAnonymousPage', { history }, { bundleId });
    }
  };

  sendEmailWithLink = async (data: any) => {
    const { translations } = translationStore;
    const [res] = await to(
      clientBundlesAnonymousRepo.sendEmailWithLink({
        ...data,
        promotionCode: this.anonymousInformations.promotionCode
      })
    );

    if (res) {
      Toast.fire({
        type: 'success',
        title: `${translations.emailSuccessMessage} ${data.email}`
      });
      this.closeSendEmailModal();
    }
  };

  @action
  openSendEmailModal = (promoCode?: string) => {
    if (promoCode) {
      this.anonymousInformations.promotionCode = promoCode;
    }
    this.showSendEmailModal = true;
  };

  @action
  closeSendEmailModal = () => {
    this.showSendEmailModal = false;
  };

  @action
  clearAnonymousState = () => {
    this.anonymousInformations = {};
  };
}

export const buyPackageStore = new BuyPackageStore(clientBundlesRepo);
export default BuyPackageStore;
