/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field } from 'formik';

const RadioButtonComponent = ({
  name,
  label,
  // requiredClass = false,
  value
}: // errors,
// touched,
// inline
any) => {
  return (
    <div style={{ paddingTop: 10 }}>
      <label
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <Field
          name={name}
          type="radio"
          value={value}
          style={{ marginTop: 0, marginRight: 10 }}
        />

        {label}
      </label>
    </div>
  );
};

export default RadioButtonComponent;
