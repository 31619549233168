import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { AiOutlineStop } from 'react-icons/ai';
import { MdDoNotDisturbOn } from 'react-icons/md';

import getValue, { attributesJoin, getDateTime } from 'lib/getValue';
import { bundlesStore } from 'data/stores/BundlesStore';

const liStyle = {
  marginBottom: '12px'
};

const iconsStyle = {
  marginTop: '5px',
  marginLeft: '5px'
};

export const ActivatedCardModalBody = (
  promotionCode: any,
  translations: any
) => (
  <>
    <Modal.Body>
      <p>
        <b>{translations.warehouseCheckCardClientActivated}</b>{' '}
        <FaCheckCircle className="text-success" style={iconsStyle} />
      </p>
      <ul>
        <li style={liStyle}>
          <b>{translations.warehouseCheckCardDateActivation}</b>{' '}
          {getDateTime(getValue(promotionCode, 'activatedAt.date'))}
        </li>
        <li style={liStyle}>
          <b>{translations.warehouseCheckCardMapObject}</b>{' '}
          {getValue(promotionCode, 'mapObject.name')}
        </li>
        <li style={liStyle}>
          <b>{translations.warehouseCheckCardClient}</b>{' '}
          {attributesJoin(
            promotionCode,
            'client.user.firstName',
            'client.user.lastName'
          )}
        </li>
        <li style={liStyle}>
          <b>{translations.chooseUpgradablePackageCardTitle}:</b>{' '}
          {getValue(promotionCode, 'client.user.mobileNumber')}
        </li>
        <li style={liStyle}>
          <b>{translations.status}: </b>
          {bundlesStore.clientBundleStatuses[promotionCode.clientBundle.status]}
        </li>
        <li style={liStyle}>
          <b>{translations.expirationDate}: </b>
          {getDateTime(getValue(promotionCode, 'clientBundle.expiredAt.date'))}
        </li>
      </ul>
    </Modal.Body>
  </>
);

export const NotActivatedCardModalBody = (
  promotionCode: any,
  translations: any
) => (
  <>
    <Modal.Body>
      <p>
        <b>{translations.warehouseCheckCardClientNotActivated}</b>{' '}
        <AiOutlineStop className="text-danger" style={iconsStyle} />
      </p>
      <ul>
        <li style={liStyle}>
          <b>{translations.warehouseCheckCardDateExpiration}</b>{' '}
          {getDateTime(getValue(promotionCode, 'expiredAt.date'))}
        </li>
        <li style={liStyle}>
          <b>{translations.warehouseCheckCardMapObject}</b>{' '}
          {getValue(promotionCode, 'mapObject.name')}
        </li>
      </ul>
    </Modal.Body>
  </>
);

export const ExpiredCardModalBody = (promotionCode: any, translations: any) => (
  <>
    <Modal.Body>
      <p>
        <b>{translations.warehouseCheckCardExpired}</b>{' '}
        <MdDoNotDisturbOn className="text-danger" style={iconsStyle} />
      </p>
      <ul>
        <li style={liStyle}>
          <b>{translations.warehouseCheckCardDateExpiration}</b>{' '}
          {getDateTime(getValue(promotionCode, 'expiredAt.date'))}
        </li>
      </ul>
    </Modal.Body>
  </>
);
