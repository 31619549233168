import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { bundlesStore } from 'data/stores/BundlesStore';
import { upgradeClientBundleStore } from 'data/stores/UpgradeClientBundleStore';
import { translationStore } from 'data/stores/TranslationStore';
import { authStore } from 'data/stores/AuthStore';
import { navigate } from 'lib/routerHelper';

import PrintComponent from 'components/PrintComponent';
import Loader from 'components/Loader';
import BundleWithFeaturesInfoPrint from 'components/BundleWithFeaturesInfoPrint';
import RenderIf from 'components/RenderIf';
import { anchorStyle } from 'components/PrintComponent/PrintComponent';
import Card from 'components/Card';

const styleLi = {
  marginBottom: '15px',
  fontSize: '16px'
};

@observer
class BoughtUpgradePackagePage extends Component<RouterProps> {
  state = { pageNum: 0 };

  componentDidMount() {
    const { upgradableBundles } = upgradeClientBundleStore;
    const { categoryTitles } = bundlesStore;
    const { history } = this.props;

    if (upgradableBundles.length === 0) {
      navigate('homePage', { history });
    }

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }

    this.firstRsAndThenEu(categoryTitles);
  }

  navigateBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  firstRsAndThenEu = (categoryTitles: string[]) => {
    if (
      categoryTitles[0] === 'Usluge u RS' ||
      categoryTitles[0] === 'USLUGE PAKETA'
    ) {
      this.setState({ pageNum: 0 });
    } else {
      this.setState({ pageNum: 1 });
    }
  };

  render() {
    const { translations } = translationStore;
    const { country } = authStore;
    const {
      upgradableBundles,
      payslipFile,
      upgradedClientBundle
    } = upgradeClientBundleStore;
    const { promoPackageKeyNames } = bundlesStore;
    const { isTypePayslipPayment } = authStore;

    if (!translations || upgradableBundles.length === 0) {
      return <Loader />;
    }

    const { match }: any = this.props;
    const { params } = match;

    const selectedBundle: any = upgradableBundles.find((bundle: any) => {
      return bundle.id === match.params.bundleId;
    });

    if (!selectedBundle) {
      return <h2>{translations.somethingWentWrong}</h2>;
    }

    bundlesStore.splitFeaturesByCategoryTitle(selectedBundle.features);
    const { categoryTitles, territoryTitle } = bundlesStore;
    const { pageNum } = this.state;
    const onlyOnePage = categoryTitles.length === 1;

    const catIndexSecondPage = () => {
      if (country === 'rs') {
        if (pageNum === 0) {
          return 1;
        }
        return 0;
      }
      return 1;
    };

    return (
      <>
        <Row>
          <Col md={8}>
            <h2>
              {translations.package} <b>{selectedBundle.name}</b>{' '}
              {translations.packageActivatedOnNumber} <b>{params.mobileNum}</b>
            </h2>

            <ul>
              <li style={styleLi}>
                {translations.packageUpgradeActivatedDescription}
              </li>
              <li style={styleLi}>
                {translations.packageActivatedPrintDocs}

                <ul style={{ marginTop: '15px' }}>
                  <li style={styleLi}>
                    <PrintComponent
                      pageOne={
                        <BundleWithFeaturesInfoPrint
                          selectedBundle={selectedBundle}
                          territoryTitle={territoryTitle}
                          categoryTitles={categoryTitles}
                          categoryTitleIndex={country === 'rs' ? pageNum : 0}
                          upgrade
                          clientBundle={upgradedClientBundle}
                        />
                      }
                      pageTwo={
                        !onlyOnePage && (
                          <BundleWithFeaturesInfoPrint
                            pageTwo
                            selectedBundle={selectedBundle}
                            territoryTitle={territoryTitle}
                            categoryTitles={categoryTitles}
                            categoryTitleIndex={catIndexSecondPage()}
                            clientBundle={upgradedClientBundle}
                          />
                        )
                      }
                      buttonText={translations.printLinkPackageContent}
                    />
                  </li>
                </ul>
              </li>

              <RenderIf condition={isTypePayslipPayment}>
                <li style={styleLi}>
                  {translations.printPayslip}
                  <ul style={{ marginTop: '15px' }}>
                    <li>
                      <a
                        href={`https://${payslipFile.filePath}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={anchorStyle}
                      >
                        {translations.payslip}
                      </a>
                    </li>
                  </ul>
                </li>
              </RenderIf>
            </ul>
          </Col>
          <Col md={4}>
            <RenderIf condition={isTypePayslipPayment}>
              <Card>
                <p>{translations.printPayslip}</p>
                <a
                  href={`https://${payslipFile.filePath}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'white', width: '100%' }}
                  className="btn btn-fill btn-warning btn-wd"
                >
                  {translations.payslip}
                </a>
              </Card>
            </RenderIf>
          </Col>
        </Row>
        <RenderIf
          condition={
            isTypePayslipPayment &&
            !promoPackageKeyNames.includes(selectedBundle.keyName)
          }
        >
          <Row style={{ marginTop: '24px' }}>
            <Col md={9}>
              <embed
                src={`https://${payslipFile.filePath}`}
                width="100%"
                height="600px"
              />
            </Col>
          </Row>
        </RenderIf>
      </>
    );
  }
}

export default BoughtUpgradePackagePage;
