import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { GetCurrentAndUpgradableBundlesFromData } from 'data/types';
import { translationStore } from 'data/stores/TranslationStore';

import Loader from 'components/Loader';
import Card from 'components/Card';
import CheckPhoneForm from 'components/CheckPhoneForm';
import { technicalInspectionStore } from 'data/stores/TechnicalInspectionStore';

@observer
class TechnicalInspection extends Component<RouterProps> {
  onSubmit = (formData: GetCurrentAndUpgradableBundlesFromData) => {
    const { history } = this.props;

    technicalInspectionStore.getBundleInfo(formData, history);
  };

  render() {
    const { translations } = translationStore;

    if (!translations) {
      return <Loader />;
    }

    return (
      <>
        <div>
          <Row className="m-t-24">
            <Col md={6}>
              <h5 style={{ lineHeight: '25px', fontStyle: 'italic' }}>
                {translations.technicalInspectionHeadText}
              </h5>
            </Col>
          </Row>
          <Row className="m-t-24">
            <Col md={4}>
              <Card>
                <h3>{translations.chooseUpgradablePackageCardTitle}</h3>

                <CheckPhoneForm
                  onSubmit={this.onSubmit}
                  buttonText={
                    translations.warehouseCheckCardDialogConfirmButton
                  }
                />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default TechnicalInspection;
