import React from 'react';

import styles from './TableActions.module.scss';

interface TableActionsWrap {
  children: React.ReactNode;
}

function TableActionsWrap({ children }: TableActionsWrap) {
  return <div className={styles.actionsWrap}>{children}</div>;
}

export default TableActionsWrap;
