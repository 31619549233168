import React from 'react';
import { Table } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import Card from 'vendors/components/Card/Card.jsx';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { Files } from 'data/types';
import getValue, { getDateTime } from 'lib/getValue';
import { accidentDetailsStore } from 'pages/AccidentDetails/AccidentDetailsStore';
import { WarningDialog } from 'lib/dialogs';

import { TableActionBtn, TableActionsWrap } from 'components/TableActions';
import downloadFile from 'lib/downloadUtils';
import RoleGuard from 'components/RoleGuard/RoleGuard';
import { ROLE_B2B_CLUB } from 'lib/constants';

interface AccidentFilesListProps {
  files?: Files[];
  params: any;
}

const AccidentFilesBody = ({ files, params }: AccidentFilesListProps) => {
  if (!files || !files.length) {
    return <h4>There is no files for this Accident.</h4>;
  }

  const onDeleteFile = (file: Files) => {
    WarningDialog.fire({
      title: 'Delete Confirmation Required',
      text: `Are you sure you want to delete ${file.originalFilename} file?`,
      confirmButtonText: 'Yes, delete!',
      preConfirm: () => accidentDetailsStore.deleteFile(params.id, file.id)
    });
  };

  return (
    <Table responsive striped hover>
      <tbody>
        <tr>
          <td>Name</td>
          <td>Uploaded</td>
          <td />
        </tr>
        {files.map((file: any) => (
          <tr key={file.id}>
            <td>{file.originalFilename}</td>
            <td>{getDateTime(getValue(file, 'createdAt.date'))}</td>
            <td>
              <TableActionsWrap>
                <TableActionBtn
                  tooltip="Download"
                  onClick={() => downloadFile(file)}
                  icon="fa fa-download"
                  iconColor="#23ccef"
                  id="Download"
                />
                <RoleGuard visible={[ROLE_B2B_CLUB]}>
                  <TableActionBtn
                    tooltip="Delete"
                    onClick={() => onDeleteFile(file)}
                    icon="fa fa-trash"
                    iconColor="#fb404b"
                    id="Delete"
                  />
                </RoleGuard>
              </TableActionsWrap>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const AccidentFilesList = ({ files, params }: AccidentFilesListProps) => (
  <Card
    title="Accident files"
    content={
      <>
        <p style={{ fontStyle: 'italic' }}>
          Supported file format: <b>pdf,doc,docx,xls,xlsx,txt,csv</b>.
        </p>

        <AccidentFilesBody files={files} params={params} />

        <RoleGuard visible={[ROLE_B2B_CLUB]}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Formik
              onSubmit={(data: any) =>
                accidentDetailsStore.uploadFile(data, params.id)
              }
              initialValues={{ file: undefined }}
              render={({ isValid, setFieldValue }) => (
                <Form>
                  <input
                    style={{ marginBottom: 15, marginTop: 15 }}
                    type="file"
                    id="uploader-file"
                    name="file"
                    accept=".xlsx,.xls,.doc,.docx,.txt,.pdf,.csv"
                    onChange={(e: any) =>
                      setFieldValue('file', e.currentTarget.files[0])
                    }
                  />

                  <Button
                    style={{ width: '180px' }}
                    type="submit"
                    bsStyle="info"
                    fill
                    disabled={!isValid}
                  >
                    Upload file
                  </Button>
                </Form>
              )}
            />
          </div>
        </RoleGuard>
      </>
    }
  />
);

export default AccidentFilesList;
