import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';

import { layoutStore } from 'data/stores/LayoutStore';
import { pageStore } from 'data/stores/PageStore';

import AdminNavbarLinks from 'components/AdminNavbarLinks';
import { translationStore } from 'data/stores/TranslationStore';
import styles from './AdminNavbar.module.scss';

@observer
class AdminNavbar extends Component<RouteComponentProps<{}>, {}> {
  handleMiniClick = () => {
    layoutStore.toggleSidebar();
  };

  navigateBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { navTitle } = pageStore;
    const { translations } = translationStore;

    return (
      <Navbar fluid>
        {navTitle !== 'menuHome' &&
          navTitle !== 'menuStatistics' &&
          navTitle !== 'menuWarehouse' && (
            <div className="navbar-minimize">
              <button
                type="button"
                className="btn btn-warning btn-fill btn-round btn-icon"
                onClick={this.navigateBack}
              >
                <i className="fa fa-arrow-left" />
              </button>
            </div>
          )}

        <Navbar.Header>
          <div className={styles.breadCrumbs}>
            <Navbar.Brand>
              {translations[pageStore.navTitle || '']}
            </Navbar.Brand>
          </div>
        </Navbar.Header>
        <Navbar.Collapse>
          <AdminNavbarLinks />
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(AdminNavbar);
