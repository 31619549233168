import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import forEach from 'lodash/forEach';
import { object, string } from 'yup';

import { navigate } from 'lib/routerHelper';
import { homeStore } from 'pages/Home/HomeStore';
import { authStore } from 'data/stores/AuthStore';

import Card from 'components/Card';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';
import RadioButtonComponent from 'components/RadioButton/RadioButton';

import styles from './Questionnaire.module.scss';

const questionnaireSchema = object().shape({
  1: string().required(),
  2: string().required()
});

@observer
class QuestionnairePage extends Component<RouterProps> {
  componentDidMount() {
    homeStore.getQuestions();
  }

  getInitialValues = () => {
    const initialValues = {} as any;
    const { questions } = homeStore;

    forEach(questions, (field: any) => {
      initialValues[field.id] = '';
    });

    return initialValues;
  };

  onSubmitQuestionnaire = (questionsResult: any) => {
    const { history } = this.props;
    const perfectPackageId = homeStore.getBundleFromQuestionnaire(
      questionsResult
    );
    navigate(
      'recommendedPackagePage',
      { history },
      { bundleId: perfectPackageId }
    );
  };

  render() {
    const { questions } = homeStore;
    const { language } = authStore;

    if (!questions) {
      return null;
    }

    return (
      <Formik
        validationSchema={questionnaireSchema}
        initialValues={this.getInitialValues()}
        onSubmit={this.onSubmitQuestionnaire}
        render={({ isValid, values }: any) => (
          <Form>
            <Row>
              {questions.map((q: any) => {
                if (q.language.toLowerCase() === language.toLowerCase()) {
                  return (
                    <Col md={7} key={q.id}>
                      <h3>{q.question}</h3>
                      <Card
                        height="168px"
                        customStyle={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        {q.answers.map((a: any) => (
                          <RadioButtonComponent
                            key={a.id}
                            label={a.answer}
                            name={q.id}
                            inline={false}
                            value={a.points}
                          />
                        ))}

                        {values[q.id] ? (
                          <IoCheckmarkCircleOutline
                            className={styles.checkmarkIcon}
                            style={{ color: '#11bfe3' }}
                          />
                        ) : (
                          <IoCheckmarkCircleOutline
                            className={styles.checkmarkIcon}
                            style={{ color: '#a9a9a9' }}
                          />
                        )}
                      </Card>
                    </Col>
                  );
                }

                return null;
              })}
            </Row>

            <Button bsStyle="info" fill wd type="submit" disabled={!isValid}>
              Prikaži odgovarajući paket
            </Button>
          </Form>
        )}
      />
    );
  }
}

export default QuestionnairePage;
