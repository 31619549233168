import React, { useState, useEffect } from 'react';

interface RenderIfProps {
  condition: boolean;
  children: React.ReactElement;
}

const RenderIf: React.FC<RenderIfProps> = ({ condition, children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(condition);
  }, [condition]);

  if (show) {
    return <>{children}</>;
  }

  return null;
};

export default RenderIf;
