import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import forEach from 'lodash/forEach';

import to from 'lib/awaitTo';
import { QuestionnaireResult } from 'data/types';

class HomeStore {
  @observable questions = null as any;

  @observable results: QuestionnaireResult[] = [];

  @action
  getQuestions = async () => {
    const [response] = await to(
      axios.get('questionnaire/questions-and-answers')
    );

    if (response) {
      runInAction(() => {
        this.questions = response.questions;
        this.results = response.results;
      });
    }
  };

  getBundleFromQuestionnaire = (questionnaireResults: any) => {
    let sumOfResults = 0;

    forEach(questionnaireResults, (field: string) => {
      sumOfResults += parseFloat(field);
    });

    const perfectBundle: any = this.results.filter(
      (result: QuestionnaireResult) =>
        sumOfResults > result.from && sumOfResults <= result.toOrEqual
    );

    if (perfectBundle[0].bundleId) {
      return perfectBundle[0].bundleId;
    }

    return null;
  };
}

export const homeStore = new HomeStore();
export default HomeStore;
