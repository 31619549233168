import React from 'react';
import { BiPackage } from 'react-icons/bi';
import { FaArrowCircleRight } from 'react-icons/fa';

import styles from './ActionCard.module.scss';

interface ActionCardI {
  onClick: any;
  title?: string;
  description: string;
  color?: string;
  buttonText: string;
  icon?: any;
}

// #9a9a9a

const colors = {
  blue: '#1D3461',
  orange: '#EF8354',
  green: '#2D898B',
  yellow: '#83781B',
  ligthBlue: '#7CB9E8'
};

const ActionCard = ({
  onClick,
  title,
  description,
  color = 'blue',
  buttonText,
  icon = <BiPackage />
}: ActionCardI) => {
  return (
    <div
      className={styles.cardWrap}
      style={{ backgroundColor: colors[color] }}
      onClick={onClick}
    >
      <div className={styles.cardContent}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>

      <div className={styles.iconWrap}>{icon && icon}</div>

      <div className={styles.cardButton}>
        {buttonText} <FaArrowCircleRight className={styles.buttonIcon} />
      </div>
    </div>
  );
};

export default ActionCard;
