import { observable, action } from 'mobx';

class PageStore {
  @observable navTitle?: string;

  @action
  setNavTitle(pageTitle: string) {
    this.navTitle = pageTitle;
  }
}

export const pageStore = new PageStore();
export default PageStore;
