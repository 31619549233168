import axios from 'axios';
import forEach from 'lodash/forEach';
import BaseRepo from './BaseRepo';

type AssignVulcoWithDeactivation = {
  bundleId: string;
  clientId: string;
  companyId: string;
  include: string[];
  smsMessage: string;
  smsMessageForRejectedBill: string;
};

const customerBillType = 'customer-bills';
const amenitiesType = 'campaign-amenities';
const clientCampaignAmenityType = 'client-campaign-amenities';

const imgParseRequest = (formData: any) => {
  const request = new FormData();

  request.append('type', 'customer_bill');

  forEach(formData, (field: any, key: any) => {
    if (key === 'media' && field) {
      const isFile = typeof field.name === 'string';
      if (isFile) {
        request.append('media[]', field);
      }
    } else if (key === 'id' && field) {
      request.append(key, field);
    }
  });

  return request;
};

export const uploadMedia = (formData: any) => {
  return axios.post('media', imgParseRequest(formData));
};

export const assignVozziVulcoWithDeactivation = (
  data: AssignVulcoWithDeactivation
) => {
  return axios.post('digital-affiliate/assign-campaign-client-bundle', data);
};

export const exportVulcoStats = () => {
  return axios.get('company-sales-by-customer-bills?from=2022-10-15');
};

export const customerBillRepo = new BaseRepo(customerBillType);
export const amenitiesRepo = new BaseRepo(amenitiesType);
export const clientCampaignAmenityRepo = new BaseRepo(
  clientCampaignAmenityType
);
