/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { translationStore } from 'data/stores/TranslationStore';
import Loader from 'components/Loader';
import { navigate } from 'lib/routerHelper';

@observer
class TechnicalInspectionInformations extends Component<RouterProps> {
  componentDidMount() {
    const { history } = this.props;
    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }

    setTimeout(() => {
      navigate('homePage', { history });
    }, 5000);
  }

  render() {
    const { translations } = translationStore;

    if (!translations) {
      return <Loader />;
    }

    return (
      <Row>
        <Col md={8}>
          <h2 className="m-b-24">
            {translations.technicalInspectionSuccesTitle}
          </h2>
          <p>Bićete redirektovatni na početnu stranicu.</p>
        </Col>
      </Row>
    );
  }
}

export default TechnicalInspectionInformations;
