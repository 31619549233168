import { action } from 'mobx';

import to from 'lib/awaitTo';

import clientBundlesRepo from 'data/repositories/ClientBundlesRepo';
import TableStore from 'data/stores/TableStore';
import { authStore } from 'data/stores/AuthStore';
import { Toast } from 'lib/dialogs';
import { translationStore } from 'data/stores/TranslationStore';
// import { ROLE_AFFILIATE_COMPANY } from 'lib/constants';

class PayslipStatsStore extends TableStore<any> {
  @action
  fetchStats = async (params?: any) => {
    const customQParams = {
      payslipClientBundles: 1
    };

    if (params) {
      this.queryParams = {
        ...params,
        include: [
          'currentBundle',
          'mapObject',
          'digitalAffiliatePayslip',
          'client.user'
        ],
        ...customQParams
      };
    }
    const [response] = await to(
      clientBundlesRepo.getClientBundlesByMapObjectId(
        authStore.authUserData.mapObject.id,
        this.queryParams
      )
    );
    if (response) {
      this.onFetch(response.data, response.meta);
    }
  };

  @action
  deactivateClientBundle = async (bundleId: string) => {
    const { translations } = translationStore;
    const [response] = await to(
      clientBundlesRepo.deactivateClientBundle(bundleId)
    );

    if (response) {
      this.fetchStats(this.queryParams);

      Toast.fire({
        type: 'success',
        title: translations.successDeactivationPackage
      });
    }
  };
}

export const payslipStatsStore = new PayslipStatsStore(clientBundlesRepo);
export default PayslipStatsStore;
