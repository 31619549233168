import React from 'react';
import { CellInfo } from 'react-table';

const DigitalAffiliatePayslipStatus = ({ value }: CellInfo) => {
  const statuses = {
    in_process: 'U obradi',
    paid: 'Plaćeno',
    not_paid: 'Nije plaćeno'
  };

  const statusesClass = {
    in_process: 'text-default',
    paid: 'text-success',
    not_paid: 'text-danger'
  };

  return <div className={`${statusesClass[value]}`}>{statuses[value]}</div>;
};

export default DigitalAffiliatePayslipStatus;
