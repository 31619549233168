import React from 'react';

import logoImg from 'assets/logo-driver-s-assistance.png';
import backgroundImg from 'assets/print-background-v2-0.png';
import footerImg from 'assets/print-footer.png';
import styles from './PrintTemplate.module.scss';

const PrintTemplate = ({ children, onlyOnePage }: any) => (
  <div
    className={styles.mainContainer}
    style={{ pageBreakAfter: onlyOnePage ? 'unset' : 'always' }}
  >
    <div className={styles.titleContainer}>
      <img src={logoImg} alt="logo" width="150px" />
    </div>
    <div className={styles.contentContainer}>
      {children}
      <img
        className={styles.backgroundImg}
        src={backgroundImg}
        alt="background"
      />
    </div>
    <div>
      <img className={styles.footerImg} src={footerImg} alt="footer" />
    </div>
  </div>
);

export default PrintTemplate;
