import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';

import { translationStore } from 'data/stores/TranslationStore';
import { authStore } from 'data/stores/AuthStore';
import { bundlesStore } from 'data/stores/BundlesStore';

import Loader from 'components/Loader';

import { vulcoCampaignStore } from 'data/stores/VulcoCampaignStore';
import CheckClient from './components/CheckClient';

@observer
class VulcoCampaignPage extends Component<RouterProps> {
  componentDidMount() {
    vulcoCampaignStore.clearState();

    const { mapObject } = authStore;

    if (bundlesStore.bundles.length === 0) {
      bundlesStore.getBundles(mapObject.id);
    }

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  render() {
    const { translations } = translationStore;
    const { history } = this.props;

    if (!translations) {
      return <Loader />;
    }

    return <CheckClient translations={translations} history={history} />;
  }
}

export default VulcoCampaignPage;
