import { observable, action, runInAction } from 'mobx';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

import to from 'lib/awaitTo';
import {
  TYPE_AFFILIATE,
  TYPE_FULL_DIGITAL,
  TYPE_INSURANCE,
  TYPE_PAYSLIP_PAYMENT,
  ROLE_AFFILIATE_MARKETPLACE,
  TRANSLATIONS_SESSIONS_KEY
} from 'lib/constants';

import * as authRepo from 'data/repositories/AuthRepo';
import { AccidentClub, LoginParams, RefreshTokenParams } from 'data/types';
import { translationStore } from './TranslationStore';
import { vulcoCampaignStore } from './VulcoCampaignStore';

interface AuthStore {
  loading: boolean;
  invalidLogin: boolean;
  loggedIn: boolean;
  rememberMe: boolean;
}

interface AuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

class AuthStore {
  @observable loading = false;

  @observable invalidLogin = false;

  @observable loggedIn = false;

  @observable requestCounter = 0;

  @observable authUserData: any = null;

  @observable mapObject: any = null;

  @observable company: any = null;

  @observable isTypeAffiliate = false;

  @observable isTypeInsurance = false;

  @observable isTypePayslipPayment = false;

  @observable fullDigital = false;

  @observable type = '';

  @observable language = '';

  @observable country = '';

  @observable user: any = null;

  @observable roles: any = [];

  @observable isBlocked = false;

  @observable isVulco = false;

  @observable isPremio = false;

  @observable isMapObject = true;

  @observable companyTypes = [] as any;

  @observable mapObjectTypes = [];

  @observable accidentClub?: AccidentClub = undefined;

  @action
  setCompanies = (company: any) => {
    this.company = company;

    if (this.company) {
      const types = this.company.types.map((type: any) => type.keyName);
      this.companyTypes = types;
      this.isVulco = company.name.includes('Vulco');
      this.isPremio = company.name.includes('Premio');
      this.isTypeInsurance = company.types.some(
        (type: any) => type.keyName === TYPE_INSURANCE
      );
      this.isTypePayslipPayment = company.types.some(
        (type: any) => type.keyName === TYPE_PAYSLIP_PAYMENT
      );
    }

    if (this.isVulco && !vulcoCampaignStore.jumpStartItem && !this.company) {
      vulcoCampaignStore.fetchAmenitiesAndSetJumpStartItem();
    }
  };

  @action
  setMapObjectTypes = (mapObject: any) => {
    if (mapObject && mapObject.mapObjectTypes) {
      const types = mapObject.mapObjectTypes.map((type: any) => type.keyName);
      this.mapObjectTypes = types;

      const typeAffiliate = mapObject.mapObjectTypes.find(
        (type: any) => type.keyName === TYPE_AFFILIATE
      );

      const typeDigital = mapObject.mapObjectTypes.find(
        (type: any) => type.keyName === TYPE_FULL_DIGITAL
      );

      if (typeAffiliate) {
        this.isTypeAffiliate = true;
        this.type = TYPE_AFFILIATE;
      }

      if (typeDigital) {
        this.fullDigital = true;
        this.type = TYPE_FULL_DIGITAL;
      }
    }
  };

  @action
  increaseRequestCounter = () => {
    this.requestCounter += 1;
  };

  @action
  resetRequestCounter = () => {
    this.requestCounter = 0;
  };

  @action
  login(params: LoginParams) {
    this.loading = true;
    this.invalidLogin = false;
    if (params.rememberMe) {
      Cookies.set('remember_me', 'RememberMe', { sameSite: 'strict' });
    }
    authRepo
      .login(params)
      .then(this.onSuccessLogin)
      .catch(this.onErrorLogin);
  }

  @action
  getAuthUser = async () => {
    this.loading = true;
    const params = {
      include: [
        'roles',
        'mapObject.mapObjectTypes',
        'company.types',
        'mapObjectCompany.types',
        'client',
        'country',
        'accidentClub'
      ]
    };
    const [response] = await to(authRepo.getAuthUser({ params }));

    if (response) {
      this.setCompanies(
        response.company ? response.company : response.mapObjectCompany
      );

      runInAction(() => {
        this.user = response.user;
        this.roles = response.roles;
        this.authUserData = response;
        this.loading = false;
        this.mapObject = response.mapObject;
        this.language = response.language;
        this.country = response.country.shortname.toLowerCase();
        this.isBlocked = response.mapObject
          ? response.mapObject.isBlocked
          : false;
        this.accidentClub = response.accidentClub;
      });

      const isItMapObject = response.roles.find(
        (item: any) => item.name === ROLE_AFFILIATE_MARKETPLACE
      );
      runInAction(() => {
        this.isMapObject = !!isItMapObject;
      });

      this.setMapObjectTypes(response.mapObject);
      translationStore.checkForTranslations();
    }
  };

  @action
  logout = () => {
    this.loggedIn = false;
    this.roles = [];
    this.clearAuthCookieData();
    Cookies.remove('remember_me');
    this.resetRequestCounter();
    sessionStorage.removeItem(TRANSLATIONS_SESSIONS_KEY);
    translationStore.clearTranslations();
    this.clearAllState();
  };

  @action
  onSuccessLogin = (response: AxiosResponse<AuthResponse>) => {
    this.loading = false;
    this.invalidLogin = false;
    this.setAuthToken(response.data.access_token);
    this.setExpiresIn(response.data.expires_in);
    if (Cookies.get('remember_me')) {
      this.setRefreshToken(response.data.refresh_token);
    }
  };

  async getNewToken(params: RefreshTokenParams) {
    const response = await authRepo.refreshToken(params);
    return response;
  }

  @action
  onErrorLogin = () => {
    this.loading = false;
    this.invalidLogin = true;
    Cookies.remove('remember_me');
  };

  refreshToken(params: RefreshTokenParams) {
    authRepo
      .refreshToken(params)
      .then(this.onSuccessLogin)
      .catch(this.onErrorLogin);
  }

  @action
  setAuthToken = (token: string) => {
    Cookies.set('token', token, { sameSite: 'strict' });
    this.loggedIn = true;

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  setExpiresIn = (expiresIn: number) => {
    const now = new Date().getTime();
    Cookies.set('expires_in', (now + expiresIn * 1000).toString(), {
      sameSite: 'strict'
    });
  };

  hasTokenExpired = () => {
    const expiresIn = Cookies.get('expires_in');
    if (expiresIn) {
      return new Date().getTime() > parseInt(expiresIn, 10);
    }
    return false;
  };

  setRefreshToken = (refreshToken: string) => {
    Cookies.set('refresh_token', refreshToken, {
      sameSite: 'strict'
    });
  };

  clearAuthCookieData = () => {
    Cookies.remove('token');
    Cookies.remove('refresh_token');
    Cookies.remove('expires_in');
  };

  @action
  clearAllState = () => {
    this.authUserData = null;
    this.mapObject = null;
    this.company = null;
    this.isTypeAffiliate = false;
    this.fullDigital = false;
    this.language = '';
    this.accidentClub = undefined;
  };
}

export const authStore = new AuthStore();
export default AuthStore;
