import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { WarningDialog } from 'lib/dialogs';

import { bundlesStore } from 'data/stores/BundlesStore';
import { buyPackageStore } from 'data/stores/BuyPackageStore';
import { authStore } from 'data/stores/AuthStore';
import { warehouseStore } from 'pages/Warehouse/WarehousePageStore';
import { translationStore } from 'data/stores/TranslationStore';

import Card from 'components/Card';
import RenderIf from 'components/RenderIf';
import Loader from 'components/Loader';
import PrintComponent from 'components/PrintComponent';
import ActivatePackagePayslipForm from 'components/ActivatePackageForms/ActivatePackagePayslipForm';
import BundleWithFeaturesInfo from 'components/BundleWithFeaturesInfo';
import BundleWithFeaturesInfoPrint from 'components/BundleWithFeaturesInfoPrint';
import { haveSameMembers, navigate } from 'lib/routerHelper';
import {
  TYPE_AFFILIATE_ANONYMOUS,
  TYPE_VOUCHER_INTERN_USE
} from 'lib/constants';
import { Form, Formik } from 'formik';
import FormField from 'components/FormField';
import { vulcoCampaignStore } from 'data/stores/VulcoCampaignStore';

@observer
class BuyPackagePage extends Component<RouterProps> {
  state = {
    anonymousIsSubmitting: false,
    fullDigitalIsSubmitting: false
  };

  componentDidMount() {
    const { mapObject } = authStore;
    const { selectedBundleIndex } = buyPackageStore;
    const { history }: any = this.props;

    if (selectedBundleIndex === null || bundlesStore.bundles.length === 0) {
      navigate('choosePackages', { history });
    } else {
      buyPackageStore.setSelectedBundle();
    }

    if (!warehouseStore.warehouseData) {
      warehouseStore.fetchWarehouseData(mapObject.id);
    }

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  componentWillUnmount() {
    buyPackageStore.resetStoreState();
  }

  activateAnonymousPackage = (
    selectedBundleId: string,
    history: any,
    voucher?: string
  ) => {
    this.setState({ anonymousIsSubmitting: true });
    buyPackageStore.createAnonymousPackage(selectedBundleId, history, voucher);
  };

  activatePackageDialog = (formData: any, selectedBundle: any) => {
    const { history } = this.props;
    const { translations } = translationStore;
    const { isVulco, isPremio } = authStore;
    this.setState({ fullDigitalIsSubmitting: true });

    WarningDialog.fire({
      title: `${translations.buyPackageDialogTitle} ${selectedBundle.name}`,
      text: `${translations.buyPackageDialogText} ${formData.phoneNum}`,
      confirmButtonText: translations.buyPackageDialogConfirmButton,
      cancelButtonText: translations.dialogCancelButton,
      customClass: {
        confirmButton: 'btn btn-warning btn-fill m-r-8',
        cancelButton: 'btn btn-default btn-fill'
      },
      preConfirm: () => {
        if (isVulco || isPremio) {
          vulcoCampaignStore.createPackage(
            formData,
            selectedBundle.id,
            history
          );
        } else {
          buyPackageStore.activateBundle(formData, selectedBundle.id, history);
        }
      }
    });

    setTimeout(() => {
      this.setState({ fullDigitalIsSubmitting: false });
    }, 5000);
  };

  navigateBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { translations } = translationStore;
    const { anonymousIsSubmitting, fullDigitalIsSubmitting } = this.state;
    const { bundles } = bundlesStore;
    const { isItIno, selectedBundle } = buyPackageStore;
    const { warehouseData } = warehouseStore;
    const { fullDigital, isBlocked, companyTypes, mapObjectTypes } = authStore;
    const { history }: any = this.props;
    const { categoryTitles, territoryTitle } = bundlesStore;

    if (
      bundles.length === 0 ||
      !warehouseData ||
      !translations ||
      !selectedBundle
    ) {
      return <Loader />;
    }

    return (
      <>
        <Row>
          <Col md={8}>
            <BundleWithFeaturesInfo
              selectedBundle={selectedBundle}
              categoryTitles={categoryTitles}
            />
          </Col>

          <Col md={4} style={{ position: 'sticky', top: '10vh' }}>
            <RenderIf
              condition={
                !haveSameMembers(companyTypes, [TYPE_AFFILIATE_ANONYMOUS])
              }
            >
              <Card>
                <h3>{translations.activationCardTitle}</h3>
                <ActivatePackagePayslipForm
                  onSubmit={(formData: any) =>
                    this.activatePackageDialog(formData, selectedBundle)
                  }
                  fullDigital={fullDigital}
                  isBlocked={isBlocked}
                  isSubmitting={fullDigitalIsSubmitting}
                />
              </Card>
            </RenderIf>
            <RenderIf
              condition={haveSameMembers(companyTypes, [
                TYPE_AFFILIATE_ANONYMOUS
              ])}
            >
              <Card>
                <h3 className="m-b-24">
                  {translations.anonymousActivationCardTitle}
                </h3>
                <Formik
                  initialValues={{ voucher: '' }}
                  onSubmit={(data: any) =>
                    this.activateAnonymousPackage(
                      selectedBundle.id,
                      history,
                      data.voucher
                    )
                  }
                  render={({ errors, touched }) => (
                    <Form>
                      <RenderIf
                        condition={haveSameMembers(mapObjectTypes, [
                          TYPE_VOUCHER_INTERN_USE
                        ])}
                      >
                        <FormField
                          name="voucher"
                          label="Voucher"
                          inline={false}
                          errors={errors.voucher}
                          touched={touched.voucher}
                        />
                      </RenderIf>
                      <Button
                        type="submit"
                        fill
                        bsStyle="warning"
                        disabled={anonymousIsSubmitting}
                        style={{ width: '100%' }}
                      >
                        {translations.anonymousActivationCardButton}
                      </Button>
                    </Form>
                  )}
                />
              </Card>
            </RenderIf>

            <Card>
              <h3>{translations.cardPrintTitle}</h3>
              <p>{translations.cardPrintText}</p>

              <PrintComponent
                buttonText={translations.printButton}
                button
                pageOne={
                  <BundleWithFeaturesInfoPrint
                    selectedBundle={selectedBundle}
                    categoryTitles={categoryTitles}
                    territoryTitle={territoryTitle}
                    categoryTitleIndex={isItIno ? 1 : 0}
                  />
                }
                pageTwo={
                  isItIno && (
                    <BundleWithFeaturesInfoPrint
                      pageTwo
                      selectedBundle={selectedBundle}
                      categoryTitles={categoryTitles}
                      categoryTitleIndex={isItIno ? 0 : 1}
                    />
                  )
                }
              />
            </Card>

            <RenderIf condition={!fullDigital}>
              <Card>
                <h3>{translations.cardWarehouseTitle}</h3>
                <p>
                  {translations.cardWarehouseText}{' '}
                  {warehouseStore.warehouseData.meta.pagination.total}
                </p>
              </Card>
            </RenderIf>
          </Col>
        </Row>
      </>
    );
  }
}

export default BuyPackagePage;
