/* eslint-disable import/prefer-default-export */
export function checkRoles(userRoles: any, givenRoles: any) {
  let isThereRole = false;

  givenRoles.forEach((givenPermission: any) => {
    userRoles.forEach((role: any) => {
      if (role.name === givenPermission) {
        isThereRole = true;
      }
    });
  });

  return isThereRole;
}

export default checkRoles;
