import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { translationStore } from 'data/stores/TranslationStore';

import Loader from 'components/Loader';
import Card from 'components/Card';

import { vulcoCampaignStore } from 'data/stores/VulcoCampaignStore';
import BundleWithFeaturesInfo from 'components/BundleWithFeaturesInfo';
import PrintComponent from 'components/PrintComponent';
import BundleWithFeaturesInfoPrint from 'components/BundleWithFeaturesInfoPrint';
import BillForm from '../components/BillForm';

type TParams = {
  mobileNum: string;
};

@observer
class VulcoCampaignFormPage extends Component<RouteComponentProps<TParams>> {
  componentDidMount() {
    const { client, upgradableBundle, categoryTitles } = vulcoCampaignStore;
    const { match } = this.props;
    const { params } = match;

    if (!client || !upgradableBundle || !categoryTitles) {
      vulcoCampaignStore.fetchClientInfo(params.mobileNum);
    }
  }

  render() {
    const { translations } = translationStore;
    const { history } = this.props;
    const {
      client,
      upgradableBundle,
      categoryTitles,
      isItIno,
      isNew
    } = vulcoCampaignStore;

    if (!translations || !client || !upgradableBundle || !categoryTitles) {
      return <Loader />;
    }

    return (
      <Row>
        <Col md={8}>
          <div className="m-b-24">
            <h3 style={{ marginBottom: '6px' }}>
              Korisnik ima{' '}
              <b>{client ? client.clientBundle.currentBundle.name : null}</b>{' '}
              paket
              <br /> uz nove gume dobiće{' '}
              {isNew ? <b>novi</b> : <b>nadogradnju</b>}:
            </h3>
            {!isNew && (
              <span style={{ fontStyle: 'italic' }}>
                *Napomena: nadogradnja traje koliko i originalni paket
              </span>
            )}
          </div>

          <BundleWithFeaturesInfo
            selectedBundle={upgradableBundle}
            categoryTitles={categoryTitles}
            upgrade={!isNew}
          />
        </Col>
        <Col md={4} style={{ position: 'sticky', top: '10vh' }}>
          <Card>
            <h3>Popunite</h3>
            <BillForm history={history} />
          </Card>

          <Card>
            <h3>{translations.cardPrintTitle}</h3>
            <p>{translations.cardPrintText}</p>

            <PrintComponent
              buttonText={translations.printButton}
              button
              pageOne={
                <BundleWithFeaturesInfoPrint
                  selectedBundle={upgradableBundle!}
                  categoryTitles={categoryTitles!}
                  upgrade={!isNew}
                  categoryTitleIndex={isItIno ? 1 : 0}
                />
              }
              pageTwo={
                isItIno && (
                  <BundleWithFeaturesInfoPrint
                    pageTwo
                    selectedBundle={upgradableBundle!}
                    categoryTitles={categoryTitles!}
                    upgrade={!isNew}
                    categoryTitleIndex={isItIno ? 1 : 0}
                  />
                )
              }
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default VulcoCampaignFormPage;
