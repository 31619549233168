import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { WarningDialog } from 'lib/dialogs';
import checkRoles from 'lib/checkRoles';
import { ROLE_AFFILIATE_MARKETPLACE } from 'lib/constants';

import { translationStore } from 'data/stores/TranslationStore';
import { authStore } from 'data/stores/AuthStore';

import DataTable from 'components/DataTable';
import DateCell from 'components/TableCells/DateCell';
import Loader from 'components/Loader';

import { referrerStatsStore } from './ReferrerStatsStore';

@observer
class ReferrerStatsPage extends Component {
  state = {
    hasMarketplaceRole: true
  };

  componentDidMount() {
    const { roles } = authStore;

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }

    const hasMapObjectRole = checkRoles(roles, [ROLE_AFFILIATE_MARKETPLACE]);
    this.setState({ hasMarketplaceRole: hasMapObjectRole });
  }

  renderColumns = () => {
    const { translations } = translationStore;

    const columns = [
      {
        Header: translations.statisticsTableBundleName,
        id: 'currentBundle.name',
        accessor: (clientBundle: any) => {
          if (clientBundle.currentBundle) {
            return clientBundle.currentBundle.name;
          }

          return clientBundle.bundleName;
        },
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableDate,
        accessor: 'purchasedAt',
        Cell: DateCell(),
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableMobileNumber,
        accessor: 'client.user.mobileNumber',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTablePrice,
        accessor: 'price',
        sortable: false,
        filterable: false
      }
    ];

    return columns;
  };

  deactivatePackage = (bundleData: any) => {
    const { translations } = translationStore;

    WarningDialog.fire({
      title: translations.deactivatePackageDialogTitle,
      text: `${translations.deactivatePackageDialogText} ${bundleData.userMobileNumber}`,
      confirmButtonText: translations.deactivatePackageDialogConfirmButton,
      cancelButtonText: translations.dialogCancelButton,
      customClass: {
        confirmButton: 'btn btn-danger btn-fill m-r-8',
        cancelButton: 'btn btn-default btn-fill'
      },
      preConfirm: () => referrerStatsStore.deactivateClientBundle(bundleData.id)
    });
  };

  itemAction = (bundleData: any) => {
    const { translations } = translationStore;
    const { hasMarketplaceRole } = this.state;

    if (hasMarketplaceRole) {
      return (
        <Button
          fill
          pullRight
          bsStyle={bundleData.status !== 2 ? 'success' : 'danger'}
          onClick={() => this.deactivatePackage(bundleData)}
          disabled={bundleData.status !== 2}
        >
          {bundleData.status !== 2
            ? translations.activatedButton
            : translations.deactivateButton}
        </Button>
      );
    }

    return null;
  };

  render() {
    const { translations } = translationStore;
    const { company } = authStore;
    const { hasMarketplaceRole } = this.state;

    if (!translations) {
      return <Loader />;
    }

    if (!hasMarketplaceRole) {
      if (!company) {
        return <Loader />;
      }
    }
    return (
      <div>
        <h3 style={{ marginTop: 0 }}>{translations.statisticsTableTitle}</h3>
        <DataTable
          dateRangeFilter
          className={!hasMarketplaceRole ? 'withoutActionsCell' : ''}
          columns={this.renderColumns()}
          fetchData={referrerStatsStore.fetchStats}
          data={referrerStatsStore.attachCustomColumn(
            'actions',
            this.itemAction
          )}
          meta={referrerStatsStore.meta}
          previousText={translations.statsTablePrevious}
          nextText={translations.statsTableNext}
          pageText={translations.statsTablePage}
          ofText={translations.statsTableOfText}
          rowsText={translations.statsTableRowsText}
          loading={referrerStatsStore.loading}
        />
      </div>
    );
  }
}

export default ReferrerStatsPage;
