import React, { useState } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  WithGoogleMapProps,
  Polyline
} from 'react-google-maps';

import { Location, Client } from 'data/types';

import Loader from 'components/Loader';
import RenderIf from 'components/RenderIf';

import pinA from 'assets/pin-a.png';
import pinB from 'assets/pin-b.png';
import pinC from 'assets/pin-c.png';

import { getValueOrEmptyString } from 'lib/getValue';
import styles from './Map.module.scss';
import MapStyle from './MapStyle';

interface UserMap extends Location {
  name?: any;
  phoneNumber?: string;
  locationsHistory?: Location[];
  locationUpdatedAt?: any;
  children?: any;
  accidentType: string;
  companyName?: any;
  client?: Client;
  aLocation?: Location;
  bLocation?: Location;
  cLocation?: Location;
}

interface CordinatesProps extends WithGoogleMapProps, Location {
  path?: Location[];
  children?: any;
}

const Map = withScriptjs(
  withGoogleMap(({ lat, lng, path, children }: CordinatesProps) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat, lng }}
      center={{ lat, lng }}
      defaultOptions={{
        scrollwheel: true
      }}
      options={{
        styles: MapStyle as any
      }}
    >
      <Marker position={{ lat, lng }} />
      {path && (
        <Polyline path={path} defaultOptions={{ strokeColor: '#f47e1f' }} />
      )}
      {children}
    </GoogleMap>
  ))
);

const UserMap = (props: UserMap) => {
  const [infoWindowOpenA, toggleInfoWindowA] = useState<boolean>(false);
  const [infoWindowOpenB, toggleInfoWindowB] = useState<boolean>(false);
  const [infoWindowOpenC, toggleInfoWindowC] = useState<boolean>(false);

  const {
    accidentType,
    aLocation,
    bLocation,
    cLocation,
    lat,
    lng,
    locationsHistory
  } = props;

  return (
    <>
      <div className={`card ${styles.container}`}>
        <div className="content">
          <RenderIf condition={!lat}>
            <div className={styles.loader}>
              <Loader />
            </div>
          </RenderIf>
          <RenderIf condition={!!lat && !!lng}>
            <>
              <Map
                lat={lat}
                path={locationsHistory}
                lng={lng}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `408px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              >
                {aLocation && accidentType !== 'Towing' ? (
                  <Marker
                    icon={pinA}
                    onClick={() => toggleInfoWindowA(true)}
                    position={{ lat: aLocation.lat, lng: aLocation.lng }}
                  >
                    {infoWindowOpenA && (
                      <InfoWindow onCloseClick={() => toggleInfoWindowA(false)}>
                        <>
                          <div>Lat: {aLocation && aLocation.lat}</div>
                          <div>Lng: {aLocation && aLocation.lng}</div>
                          <div>
                            Address:{' '}
                            {`${getValueOrEmptyString(
                              aLocation,
                              'address.street'
                            )} ${getValueOrEmptyString(
                              aLocation,
                              'address.streetNumber'
                            )} ${getValueOrEmptyString(
                              aLocation,
                              'address.city'
                            )} ${getValueOrEmptyString(
                              aLocation,
                              'address.country'
                            )}`}
                          </div>
                        </>
                      </InfoWindow>
                    )}
                  </Marker>
                ) : null}

                {bLocation ? (
                  <Marker
                    icon={pinB}
                    onClick={() => toggleInfoWindowB(true)}
                    position={{ lat: bLocation.lat, lng: bLocation.lng }}
                  >
                    {infoWindowOpenB && (
                      <InfoWindow onCloseClick={() => toggleInfoWindowB(false)}>
                        <>
                          <div>Lat: {bLocation && bLocation.lat}</div>
                          <div>Lng: {bLocation && bLocation.lng}</div>
                          <div>
                            Address:{' '}
                            {`${getValueOrEmptyString(
                              bLocation,
                              'address.street'
                            )} ${getValueOrEmptyString(
                              bLocation,
                              'address.streetNumber'
                            )} ${getValueOrEmptyString(
                              bLocation,
                              'address.city'
                            )} ${getValueOrEmptyString(
                              bLocation,
                              'address.country'
                            )}`}
                          </div>
                        </>
                      </InfoWindow>
                    )}
                  </Marker>
                ) : null}

                {cLocation && accidentType !== 'Service' ? (
                  <Marker
                    icon={pinC}
                    onClick={() => toggleInfoWindowC(true)}
                    position={{ lat: cLocation.lat, lng: cLocation.lng }}
                  >
                    {infoWindowOpenC && (
                      <InfoWindow onCloseClick={() => toggleInfoWindowC(false)}>
                        <>
                          <div>Lat: {cLocation && cLocation.lat}</div>
                          <div>Lng: {cLocation && cLocation.lng}</div>
                          <div>
                            Address:{' '}
                            {`${getValueOrEmptyString(
                              cLocation,
                              'address.street'
                            )} ${getValueOrEmptyString(
                              cLocation,
                              'address.streetNumber'
                            )} ${getValueOrEmptyString(
                              cLocation,
                              'address.city'
                            )} ${getValueOrEmptyString(
                              cLocation,
                              'address.country'
                            )}`}
                          </div>
                        </>
                      </InfoWindow>
                    )}
                  </Marker>
                ) : null}
              </Map>
            </>
          </RenderIf>
        </div>
      </div>
    </>
  );
};

UserMap.defaultProps = {
  showLocationFilter: false,
  filterProps: {}
};

export default UserMap;
