import React from 'react';
import Swal from 'sweetalert2';

import Card from 'vendors/components/Card/Card.jsx';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { warehouseStore } from '../../WarehousePageStore';

interface NumOfCardsAndOrderProps {
  translations: any;
  warehouseData: any;
}

const NumOfCardsAndOrder = ({
  translations,
  warehouseData
}: NumOfCardsAndOrderProps) => {
  const orderMoreCards = () => {
    Swal.fire({
      title: translations.warehouseOrderDialogTitle,
      input: 'number',
      showCancelButton: false,
      confirmButtonText: translations.warehouseOrderDialogConfirmButton,
      showLoaderOnConfirm: true,
      preConfirm: (numberOfCards: any) => {
        warehouseStore.orderMoreCards(numberOfCards);
      }
    });
  };

  return (
    <>
      <Card
        title={translations.cardWarehouseTitle}
        boldTitle
        bgColor={
          warehouseData.meta.pagination.total < 10 ? '#fd9fa5' : '#fffff'
        }
        minHeight="160px"
        content={
          <p>
            {translations.cardWarehouseText}{' '}
            {warehouseData.meta.pagination.total}
          </p>
        }
      />
      <Button bsStyle="warning" fill block onClick={orderMoreCards}>
        {translations.warehouseOrderButton}
      </Button>
    </>
  );
};

export default NumOfCardsAndOrder;
