import { RouteComponentProps } from 'react-router';

import {
  ROLE_AFFILIATE_COMPANY,
  ROLE_AFFILIATE_MARKETPLACE,
  ROLE_B2B_CLUB,
  ROLE_B2B_REPAIRER,
  TYPE_EXTERNAL_AFFILIATE,
  TYPE_FULL_DIGITAL
} from 'lib/constants';

import DashboardLayout from 'layouts/DashboardLayout';
import Login from 'pages/Login';
import HomePage from 'pages/Home';
import StatsPage from 'pages/Stats';
import WarehousePage from 'pages/Warehouse';
import BuyPackagePage from 'pages/BuyPackage';
import ChooseUpgradePackage from 'pages/ChooseUpgradePackage';
import ChoosePackagesPage from 'pages/ChoosePackages';
import BoughtPackagePage from 'pages/BoughtPackage';
import UpgradePackagePage from 'pages/UpgradePackage';
import QuestionnairePage from 'pages/Questionnaire';
import RecommendedPackagePage from 'pages/RecommendedPackage';
import BoughtUpgradePackagePage from 'pages/BoughtUpgradePackage';
import UpgradablePackagesPage from 'pages/UpgradablePackages';
import InsuranceReport from 'pages/InsuranceReport';
import Insurance from 'pages/Insurance';
import InsuranceInformations from 'pages/InsuranceInformations';
import PayslipStatsPage from 'pages/PayslipStats';
import WelcomePage from 'pages/Welcome';
import CheckClientPage from 'pages/CheckClient';
// import VulcoCampaignPage from 'pages/VulcoCampaign/VulcoCampaignPage';
import VulcoCampaignFormPage from 'pages/VulcoCampaign/steps/VulcoCampaignFormPage';
import VulcoCampaignSuccessPage from 'pages/VulcoCampaign/steps/VulcoCampaignSuccessPage';
import ReferrerStatsPage from 'pages/ReferrerStats';
import PosReferrersStatsPage from 'pages/PosReferrersStats';
import BoughtPackageAnonymousPage from 'pages/BoughtPackageAnonymous';
import VulcoCampaignAmenitiesPage from 'pages/VulcoCampaign/steps/VulcoCampaignAmenitiesPage';
import StatsVulcoCampaign from 'pages/Stats/StatsVulcoCampaing';
import InvoicesPage from 'pages/InvoicesPage';
import AccidentsListPage from 'pages/AccidentsList';
// import AccidentCurrentPage from 'pages/AccidentCurrent';
import AccidentDetailsPage from 'pages/AccidentDetails';
import WorkOrderViewPage from 'pages/AccidentDetails/WorkOrderViewPage';
import WorkOrderViewPublic from 'pages/WorkOrderView/WorkOrderView.public';
import TechnicalInspection from 'pages/TechnicalInspection/TechnicalInspection';
import TechnicalInspectionReport from 'pages/TechnicalInspectionReport/TechnicalInspectionReport';
import TechnicalInspectionInformations from 'pages/TechnicalInspectionInformations/TechnicalInspectionInformations';
import VulcoCampaignPage from 'pages/VulcoCampaign/VulcoCampaignPage';

export type Layout = 'auth' | 'dashboard';

export interface Route {
  path: string;
  key: string;
  name: string;
  icon?: string;
  mini?: string;
  showOnMenu: boolean;
  placeholder?: boolean;
  roles?: string[];
  skipRoles?: boolean;
  routes?: Route[];
  dontShowOnTypes?: string[];
  showOnTypes?: string[];
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const appRoutes: Route[] = [
  {
    path: '/login',
    key: 'login',
    name: 'Login',
    skipRoles: true,
    showOnMenu: false,
    component: Login
  },
  {
    path: '/work-order-view/:id',
    key: 'publicWorkOrderView',
    name: 'WorkOrder',
    skipRoles: true,
    showOnMenu: false,
    component: WorkOrderViewPublic
  },
  {
    path: '/dashboard',
    key: 'dashboard',
    name: 'Dashboard',
    skipRoles: true,
    showOnMenu: false,
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboard/welcome',
        key: 'welcomePage',
        name: 'welcomePage',
        icon: 'pe-7s-home',
        skipRoles: true,
        showOnMenu: false,
        component: WelcomePage
      },
      {
        path: '/dashboard/home',
        key: 'homePage',
        name: 'menuHome',
        icon: 'pe-7s-home',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: HomePage
      },
      {
        path: '/dashboard/home/choose-packages',
        key: 'choosePackages',
        name: 'menuChoosePackage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: ChoosePackagesPage
      },
      {
        path: '/dashboard/home/choose-packages/buy-package/:bundleId',
        key: 'buyPackagePage',
        name: 'menuBuyPackage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: BuyPackagePage
      },
      {
        path: '/dashboard/home/choose-upgrade-package',
        key: 'chooseUpgradePackages',
        name: 'menuChooseUpgradePackage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: ChooseUpgradePackage
      },
      {
        path: '/dashboard/home/choose-upgrade-package/:mobileNum',
        key: 'upgradablePackagesPage',
        name: 'menuUpgradablePackagesPage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: UpgradablePackagesPage
      },
      {
        path:
          '/dashboard/home/choose-upgrade-package/:mobileNum/upgrade-package/:bundleId',
        key: 'upgradePackagePage',
        name: 'menuUpgradePackage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: UpgradePackagePage
      },
      {
        path:
          '/dashboard/home/choose-upgrade-package/:mobileNum/bought-upgrade-package/:bundleId',
        key: 'boughtUpgradePackagePage',
        name: 'menuBoughtUpgradePackage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: BoughtUpgradePackagePage
      },
      {
        path: '/dashboard/home/choose-packages/bought-package/:bundleId',
        key: 'boughtPackagePage',
        name: 'menuBoughtPackage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: BoughtPackagePage
      },
      {
        path:
          '/dashboard/home/choose-packages/bought-package-anonymous/:bundleId',
        key: 'boughtPackageAnonymousPage',
        name: 'menuBoughtPackage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: BoughtPackageAnonymousPage
      },
      {
        path: '/dashboard/home/questionnaire',
        key: 'questionnairePage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        name: 'menuQuestionnaire',
        showOnMenu: false,
        component: QuestionnairePage
      },
      {
        path: '/dashboard/home/questionnaire/recommended-package/:bundleId',
        key: 'recommendedPackagePage',
        name: 'menuRecommendedPage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: RecommendedPackagePage
      },
      {
        path: '/dashboard/stats',
        key: 'statsPage',
        name: 'menuStatistics',
        icon: 'pe-7s-graph',
        roles: [ROLE_AFFILIATE_MARKETPLACE, ROLE_AFFILIATE_COMPANY],
        showOnTypes: ['affiliate', 'external_affiliate'],
        showOnMenu: true,
        component: StatsPage
      },
      {
        path: '/dashboard/referrer-stats',
        key: 'referrerStatsPage',
        name: 'menuPassiveReferrerStatistics',
        icon: 'pe-7s-graph',
        roles: [ROLE_AFFILIATE_COMPANY],
        showOnMenu: true,
        showOnTypes: ['referral'],
        component: ReferrerStatsPage
      },
      {
        path: '/dashboard/pos-referrer-stats',
        key: 'posReferrerStatsPage',
        name: 'menuReferrerStatistics',
        icon: 'pe-7s-graph',
        roles: [ROLE_AFFILIATE_COMPANY],
        showOnMenu: true,
        showOnTypes: ['referral_affiliate'],
        component: PosReferrersStatsPage
      },
      {
        path: '/dashboard/stats-campaign',
        key: 'statsPageCampaign',
        name: 'menuStatisticsBills',
        icon: 'pe-7s-note2',
        roles: [ROLE_AFFILIATE_COMPANY],
        showOnTypes: ['external_affiliate'],
        showOnMenu: true,
        component: StatsVulcoCampaign
      },
      {
        path: '/dashboard/warehouse',
        key: 'warehousePage',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        dontShowOnTypes: [TYPE_FULL_DIGITAL],
        name: 'menuWarehouse',
        icon: 'pe-7s-server',
        showOnMenu: true,
        component: WarehousePage
      },
      {
        path: '/dashboard/home/insurance',
        key: 'insurance',
        name: 'menuInsurance',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: Insurance
      },
      {
        path: '/dashboard/home/insurance-report/:mobileNum',
        key: 'insuranceReport',
        name: 'menuInsuranceReport',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: InsuranceReport
      },
      {
        path: '/dashboard/home/technical-inspection',
        key: 'technicalInspection',
        name: 'menuTechnicalInspection',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: TechnicalInspection
      },
      {
        path: '/dashboard/home/technical-inspection-report/:mobileNum',
        key: 'technicalInspectionReport',
        name: 'menuTechnicalInspectionReport',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: TechnicalInspectionReport
      },
      {
        path: '/dashboard/home/technical-inspection-informations',
        key: 'technicalInspectionInformations',
        name: 'menuTechnicalInspectionInformations',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: TechnicalInspectionInformations
      },
      {
        path: '/dashboard/home/insurance-report-informations',
        key: 'insuranceInformations',
        name: 'menuInsuranceInformations',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: InsuranceInformations
      },
      {
        path: '/dashboard/payslip-stats',
        key: 'payslipStats',
        name: 'payslipStatsPage',
        icon: 'pe-7s-graph',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: PayslipStatsPage
      },
      {
        path: '/dashboard/check-client',
        key: 'checkClient',
        name: 'menuCheckClient',
        icon: 'pe-7s-info',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: CheckClientPage
      },
      {
        path: '/dashboard/invoices/:tab',
        key: 'invoices',
        name: 'menuInvoices',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        icon: 'pe-7s-note2',
        showOnMenu: true,
        dontShowOnTypes: [TYPE_EXTERNAL_AFFILIATE],
        component: InvoicesPage
      },
      {
        path: '/dashboard/home/vulco-campaign',
        key: 'vulcoCampaign',
        name: 'menuVulcoCampaign',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: VulcoCampaignPage
      },
      {
        path: '/dashboard/home/vulco-campaign/:mobileNum',
        key: 'vulcoCampaignForm',
        name: 'menuVulcoCampaignForm',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: VulcoCampaignFormPage
      },
      {
        path: '/dashboard/home/vulco-campaign/:mobileNum/success',
        key: 'vulcoCampaignSuccess',
        name: 'menuVulcoCampaignSuccess',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: VulcoCampaignSuccessPage
      },
      {
        path: '/dashboard/home/vulco-campaign-amenities/:mobileNum',
        key: 'vulcoCampaignAmenities',
        name: 'menuVulcoCampaignForm',
        roles: [ROLE_AFFILIATE_MARKETPLACE],
        showOnMenu: false,
        component: VulcoCampaignAmenitiesPage
      },
      // {
      //   path: '/dashboard/force/active-accident',
      //   key: 'forceAccidentCurrent',
      //   name: 'menuForceAccidentCurrent',
      //   icon: 'pe-7s-attention',
      //   roles: [ROLE_B2B_REPAIRER],
      //   showOnMenu: true,
      //   component: AccidentCurrentPage
      // },
      {
        path: '/dashboard/force/accidents-by-company',
        key: 'forceAccidents',
        name: 'menuForceAccidents',
        icon: 'pe-7s-note2',
        roles: [ROLE_AFFILIATE_COMPANY],
        showOnTypes: ['company-repairer-parent'],
        showOnMenu: true,
        component: AccidentsListPage
      },
      {
        path: '/dashboard/force/accidents-by-club',
        key: 'forceAccidents',
        name: 'menuForceAccidents',
        icon: 'pe-7s-note2',
        roles: [ROLE_B2B_CLUB],
        showOnMenu: true,
        component: AccidentsListPage
      },
      {
        path: '/dashboard/force/accidents/:id',
        key: 'forceAccidentDetails',
        name: 'menuForceAccidents',
        roles: [ROLE_B2B_REPAIRER, ROLE_B2B_CLUB, ROLE_AFFILIATE_COMPANY],
        showOnMenu: false,
        component: AccidentDetailsPage
      },
      {
        path: '/dashboard/force/accidents/:id/work-order/:workOrderId',
        key: 'forceWorkOrderView',
        name: 'menuForceWorkOrderView',
        roles: [ROLE_B2B_REPAIRER, ROLE_B2B_CLUB, ROLE_AFFILIATE_COMPANY],
        showOnMenu: false,
        component: WorkOrderViewPage
      }
    ]
  }
];

export const flatListOfRoutes = (
  data: Route[] = appRoutes,
  res: Route[] = []
): Route[] => {
  // eslint-disable-next-line consistent-return
  data.forEach(current => {
    if (current.routes) {
      const { routes, ...route } = current;
      res.push(route);
      return flatListOfRoutes(routes, res);
    }
    res.push(current);
  });
  return res;
};

export const flatRoutes = flatListOfRoutes();
export default appRoutes;
