import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';

import { ROLE_AFFILIATE_COMPANY, ROLE_B2B_CLUB } from 'lib/constants';
import Loader from 'components/Loader';
import RoleGuard from 'components/RoleGuard/RoleGuard';

import { accidentDetailsStore } from './AccidentDetailsStore';
import AccidentDetails from './components/AccidentDetails';
import AccidentFilesList from './components/AccidentFilesList';
import AccidentInfo from './components/AccidentInfo';
import AccidentOfficesList from './components/AccidentOfficesList';
import GeneralDetails from './components/GeneralDetails';
import WorkOrdersList from './components/WorkOrdersList/WorkOrdersList';
import Map from './components/Map/Map';
import Comments from './components/Comments';
import IncidentReportsLog from './components/IncidentReportLog/IncidentReportsLog';

type Params = {
  id: string;
};

const AccidentDetailsPage = ({
  match,
  history
}: RouteComponentProps<Params>) => {
  useEffect(() => {
    const { params } = match;
    accidentDetailsStore.getAccident(params.id);

    return () => {
      accidentDetailsStore.clearState();
    };
  }, [match]);

  const { accident, locationHistory } = accidentDetailsStore;

  if (!accident) {
    return <Loader />;
  }
  const {
    accidentWorkOrders,
    accidentOffices,
    files,
    aLocation,
    bLocation,
    cLocation,
    accidentType
  } = accident;
  const { params } = match;

  return (
    <>
      <Row>
        <Col md={6}>
          <Map
            locationsHistory={locationHistory}
            lat={bLocation.lat}
            lng={bLocation.lng}
            accidentType={accidentType!.name}
            aLocation={aLocation}
            bLocation={bLocation}
            cLocation={cLocation}
          />
        </Col>
        <Col md={6}>
          <GeneralDetails accident={accident} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Comments accident={accident} />
        </Col>
        <Col md={6}>
          <AccidentInfo accident={accident} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AccidentDetails accident={accident} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <WorkOrdersList
            accident={accident}
            accidentWorkOrders={accidentWorkOrders}
            history={history}
          />
        </Col>
      </Row>
      <RoleGuard visible={[ROLE_B2B_CLUB, ROLE_AFFILIATE_COMPANY]}>
        <Row>
          <Col md={12}>
            <AccidentOfficesList accidentOffices={accidentOffices} />
          </Col>
        </Row>
      </RoleGuard>
      <Row>
        <Col md={12}>
          <AccidentFilesList files={files} params={params} />
        </Col>
      </Row>
      <Row>
        <IncidentReportsLog accident={accident} />
      </Row>
    </>
  );
};

export default observer(AccidentDetailsPage);
