import React from 'react';
import { observer } from 'mobx-react';
import Card from 'vendors/components/Card/Card.jsx';

import { getDateTime } from 'lib/getValue';

import { Comments } from 'data/types';

// import { TableActionBtn } from 'components/TableActions';
// import RenderIf from 'components/RenderIf';

import styles from './CommentBox.module.scss';

interface CommentBoxProps {
  comment: Comments;
  onDelete: (comment: Comments) => void;
  showOnlyComments?: boolean;
}

const CommentBox = ({ comment }: CommentBoxProps) => {
  const { text, createdBy, commentType } = comment;

  return (
    <Card
      content={
        <>
          <div>
            <div className={styles.dateAndActionButtons}>
              <div>
                {getDateTime(comment.createdAt.date)}
                {comment.createdBy ? (
                  <b>
                    {' '}
                    by {createdBy.firstName} {createdBy.lastName}
                  </b>
                ) : null}
              </div>
              {/* <RenderIf condition>
                <div>
                  <TableActionBtn
                    tooltip="Delete"
                    onClick={() => onDelete(comment)}
                    icon="fa fa-trash"
                    iconColor="#fb404b"
                    id="delete-role"
                  />
                </div>
              </RenderIf> */}
            </div>
            <div
              className={styles.textDiv}
              style={{ color: commentType === 'alert' ? '#d9534f' : 'black' }}
            >
              {text}
            </div>
          </div>
        </>
      }
    />
  );
};

export default observer(CommentBox);
