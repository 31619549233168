import React from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';

import { checkRoles } from 'lib/checkRoles';
import { authStore } from 'data/stores/AuthStore';

import PageTitle from 'components/PageTitle';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorPage from 'pages/ErrorPage';
import ForbiddenPage from 'pages/ForbiddenPage';
import { haveSameMembers } from 'lib/routerHelper';

function RouteWithSubRoutes(route: any) {
  const { isMapObject } = authStore;

  if (route.dontShowOnTypes) {
    let dontShow = false;
    if (isMapObject) {
      dontShow = haveSameMembers(
        route.dontShowOnTypes,
        authStore.mapObjectTypes
      );
    } else {
      dontShow = haveSameMembers(route.dontShowOnTypes, authStore.companyTypes);
    }

    if (dontShow) {
      return <Route exact {...route} component={ForbiddenPage} />;
    }
  }

  if (route.showOnTypes) {
    let showRoute = false;

    if (isMapObject) {
      showRoute = haveSameMembers(route.showOnTypes, authStore.mapObjectTypes);
    } else {
      showRoute = haveSameMembers(route.showOnTypes, authStore.companyTypes);
    }

    if (!showRoute) {
      return <Route exact {...route} component={ForbiddenPage} />;
    }
  }

  if (!route.roles && !route.skipRoles) {
    return <Route exact {...route} component={ForbiddenPage} />;
  }

  if (route.roles) {
    const showRoute = checkRoles(authStore.roles, route.roles);

    if (!showRoute) {
      return <Route exact {...route} component={ForbiddenPage} />;
    }
  }

  return (
    <Route
      exact={route.path.includes('dashboard/')}
      path={route.path}
      render={props => {
        if (route.component) {
          return (
            <>
              <PageTitle />
              <ErrorBoundary ErrorComponent={ErrorPage}>
                <route.component {...props} routes={route.routes} />
              </ErrorBoundary>
            </>
          );
        }
        return null;
      }}
    />
  );
}

export default observer(RouteWithSubRoutes);
