import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

// import { navigate } from 'lib/routerHelper';
import { GetCurrentAndUpgradableBundlesFromData } from 'data/types';
import { translationStore } from 'data/stores/TranslationStore';
import { insuranceStore } from 'data/stores/InsuranceStore';
// import { upgradeClientBundleStore } from 'data/stores/UpgradeClientBundleStore';

import Loader from 'components/Loader';
import Card from 'components/Card';
import CheckPhoneForm from 'components/CheckPhoneForm';

@observer
class Insurance extends Component<RouterProps> {
  componentDidMount() {
    // upgradeClientBundleStore.clearStore();
  }

  onSubmit = (formData: GetCurrentAndUpgradableBundlesFromData) => {
    const { history } = this.props;

    insuranceStore.getCurrentAndUpgradableBundles(formData, history);
    // navigate('insuranceReport', { history });
  };

  render() {
    const { translations } = translationStore;

    if (!translations) {
      return <Loader />;
    }

    return (
      <>
        <div>
          <Row className="m-t-24">
            <Col md={6}>
              <h5 style={{ lineHeight: '25px', fontStyle: 'italic' }}>
                {translations.insuranceHeadText}{' '}
                <b>{translations.insuranceReplacement}</b> {translations.or}{' '}
                <b>{translations.insuranceRepair}</b>
              </h5>
            </Col>
          </Row>
          <Row className="m-t-24">
            <Col md={4}>
              <Card>
                <h3>{translations.chooseUpgradablePackageCardTitle}</h3>

                <CheckPhoneForm
                  onSubmit={this.onSubmit}
                  buttonText={
                    translations.warehouseCheckCardDialogConfirmButton
                  }
                />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Insurance;
