import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { RouteProps, NavLink } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';

import { translationStore } from 'data/stores/TranslationStore';
import { authStore } from 'data/stores/AuthStore';
import checkRoles from 'lib/checkRoles';
import { haveSameMembers } from 'lib/routerHelper';

import bgImage from 'assets/img/road.jpg';
import routes, { Route } from 'routes';
import RenderIf from 'components/RenderIf';
import SidebarLogo from './SidebarLogo';

import styles from './Sidebar.module.scss';

interface SidebarState {
  image: string;
  menuState: any;
}

@observer
class Sidebar extends PureComponent<RouteProps, SidebarState> {
  readonly state = {
    image: bgImage,
    menuState: {}
  };

  componentDidMount() {
    const { location } = this.props;
    if (location && location.pathname) {
      const routeNames = location.pathname
        .split('/')
        .filter(routeName => routeName !== '')
        .reduce((acc, curr: string) => {
          acc[curr.replace(/^\w/, c => c.toUpperCase())] = true; // eslint-disable-line no-param-reassign
          return acc;
        }, {});
      this.setState({
        menuState: routeNames
      });
    }
  }

  activeRoute = (routePath: string) => {
    const { location } = this.props;
    if (!location) return '';
    return location.pathname === routePath ? 'active' : '';
  };

  activeNestedRoute = (routePath: string) => {
    const { location } = this.props;
    if (!location) return '';
    return location.pathname.indexOf(routePath) > -1 ? 'active' : '';
  };

  toggleMenu = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    name: string
  ) => {
    event.preventDefault();
    const { menuState } = this.state;
    this.setState({
      menuState: {
        ...menuState,
        [name]: !menuState[name]
      }
    });
  };

  createLinks = (r: Route[]): any => {
    const { roles, isMapObject } = authStore;
    const { menuState } = this.state;
    const { translations } = translationStore;

    if (!translations) {
      return null;
    }

    return r.map(route => {
      if (!route.roles && !route.skipRoles) {
        return null;
      }

      if (route.dontShowOnTypes) {
        let dontShow = false;

        if (isMapObject) {
          dontShow = haveSameMembers(
            route.dontShowOnTypes,
            authStore.mapObjectTypes
          );
        } else {
          dontShow = haveSameMembers(
            route.dontShowOnTypes,
            authStore.companyTypes
          );
        }

        if (dontShow) {
          return null;
        }
      }

      if (route.showOnTypes) {
        let showRoute = false;

        if (isMapObject) {
          showRoute = haveSameMembers(
            route.showOnTypes,
            authStore.mapObjectTypes
          );
        } else {
          showRoute = haveSameMembers(
            route.showOnTypes,
            authStore.companyTypes
          );
        }

        if (!showRoute) {
          return null;
        }
      }

      if (route.roles) {
        const showRoute = checkRoles(roles, route.roles);

        if (!showRoute) {
          return null;
        }
      }

      if (!route.showOnMenu && !route.routes) return null;

      if (route.routes && !route.placeholder) {
        return this.createLinks(route.routes);
      }

      if (route.routes && route.placeholder) {
        return (
          <li key={route.path} className={this.activeNestedRoute(route.path)}>
            <a href="/" onClick={e => this.toggleMenu(e, route.name)}>
              <i className={route.icon} />
              <p>
                {route.name}
                <b
                  className={classNames('caret', {
                    'rotate-180': menuState[route.name]
                  })}
                />
              </p>
            </a>
            <Collapse in={menuState[route.name]}>
              <ul className="nav collapse-nav">
                {this.createLinks(route.routes)}
              </ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li key={route.path} className={this.activeRoute(route.path)}>
          <NavLink
            to={route.path}
            className="nav-link"
            activeClassName="active"
          >
            <RenderIf condition={!!route.icon}>
              <>
                <i className={route.icon} />
                <p>{translations[route.name]}</p>
              </>
            </RenderIf>
            <RenderIf condition={!route.icon}>
              <>
                <span className="sidebar-mini">{route.mini}</span>
                <span className="sidebar-normal">
                  <p>{translations[route.name]}</p>
                </span>
              </>
            </RenderIf>
          </NavLink>
        </li>
      );
    });
  };

  render() {
    const { image } = this.state;
    return (
      <>
        <div
          className="sidebar overflow-hidden"
          data-image={image}
          data-color="blue"
        >
          <div
            className="sidebar-background"
            style={{ backgroundImage: `url(${image})` }}
          />

          <div
            className="logo"
            style={{ height: '66px', fontWeight: 500, userSelect: 'none' }}
          >
            <div
              className={`${styles.sidebarTitle} visible-on-sidebar-regular`}
            >
              VOZZi FORCE
            </div>
          </div>

          <SidebarLogo />

          <SimpleBar className="sidebar-wrapper" id={styles.scrollBarMenu}>
            <ul className="nav">{this.createLinks(routes)}</ul>
          </SimpleBar>
        </div>
      </>
    );
  }
}

export default Sidebar;
