import axios, { AxiosRequestConfig } from 'axios';

const type = 'clients';

const checkClientByMobileNum = (
  mobileNum: string,
  config?: AxiosRequestConfig
) => {
  return axios.get(`${type}/users/${mobileNum}`, config);
};

export default { checkClientByMobileNum };
