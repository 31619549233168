import React, { useEffect } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import Card from 'vendors/components/Card/Card.jsx';

import { DATETIME_FORMAT, DATE_FORMAT } from 'lib/constants';
import getValue, { getValueOrEmptyString, getValueOrNull } from 'lib/getValue';
import { Files } from 'data/types';
import { accidentDetailsStore } from 'pages/AccidentDetails/AccidentDetailsStore';
import { navigate } from 'lib/routerHelper';
import { Toast } from 'lib/dialogs';

interface MatchParams {
  id: string;
  workOrderId: string;
}

const AccidentWorkOrderViewPage = ({
  history
}: RouteComponentProps<MatchParams>) => {
  const { choosenWorkOrder, woDataFromAccident } = accidentDetailsStore;

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      accidentDetailsStore.setChoosenWorkOrder(undefined);
      accidentDetailsStore.clearWoData();
    };
  }, []);

  const findImage = (type: string) => {
    if (choosenWorkOrder && choosenWorkOrder.media) {
      const mediaObj = choosenWorkOrder.media.find(
        (obj: Files) => obj.mediaTag === type
      );

      if (mediaObj) {
        return mediaObj.path;
      }
    }

    return '';
  };

  // Redirect to the accident list if there is none of them
  if (!choosenWorkOrder && !woDataFromAccident) {
    navigate('forceAccidents', { history });
    Toast.fire({
      type: 'info',
      title: 'There is not selected accident'
    });
    return null;
  }

  const clientLocation = `${getValueOrEmptyString(
    woDataFromAccident,
    'bLocation.address.street'
  )} ${getValueOrEmptyString(
    woDataFromAccident,
    'bLocation.address.streetNumber'
  )} ${getValueOrEmptyString(
    woDataFromAccident,
    'bLocation.address.city'
  )} ${getValueOrEmptyString(woDataFromAccident, 'bLocation.address.country')}`;

  const loadingImages = action(() =>
    choosenWorkOrder!.media.filter((img: Files) => img.mediaTag === 'loading')
  )();

  const unloadingImages = action(() =>
    choosenWorkOrder!.media.filter((img: Files) => img.mediaTag === 'unloading')
  )();

  const damageImages = action(() =>
    choosenWorkOrder!.media.filter((imgObj: Files) =>
      imgObj.mediaTag ? imgObj.mediaTag.includes('car_part') : []
    )
  )();

  const renderVehicleParts = () => {
    return getValueOrNull(choosenWorkOrder, 'damagedVehicleParts') &&
      getValueOrNull(choosenWorkOrder, 'damagedVehicleParts').length
      ? getValueOrNull(choosenWorkOrder, 'damagedVehicleParts').map(
          (part: string, index: number) => (index ? `, ${part}` : part)
        )
      : 'Nema oštećenja na vozilu';
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <p style={{ marginTop: '0px', marginBottom: '30px' }}>
            Work order number {getValue(choosenWorkOrder, 'realId')}, Accident
            ID {getValue(woDataFromAccident, 'realId')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card
            tableFullWidth
            title="Client Details"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>First name:</td>
                      <td>
                        {getValue(woDataFromAccident, 'clientB2b.firstName')}
                      </td>
                    </tr>
                    <tr>
                      <td>Last name:</td>
                      <td>
                        {getValue(woDataFromAccident, 'clientB2b.lastName')}
                      </td>
                    </tr>
                    <tr>
                      <td>Phone number:</td>
                      <td>
                        {getValue(woDataFromAccident, 'clientB2b.phoneNumber')}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />
          <Card
            tableFullWidth
            title="Intervention information"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Created at:</td>
                      <td>
                        {moment(
                          getValue(choosenWorkOrder, 'createdAt.date')
                        ).format(DATETIME_FORMAT)}
                      </td>
                    </tr>
                    <tr>
                      <td>Type of information:</td>
                      <td>
                        {getValue(woDataFromAccident, 'accidentType.name')}
                      </td>
                    </tr>
                    <tr>
                      <td>Client location:</td>
                      <td>{clientLocation}</td>
                    </tr>
                    <tr>
                      <td>Fault code:</td>
                      <td>
                        {getValue(
                          choosenWorkOrder,
                          'interventionFaultCode.name'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Client comment:</td>
                      <td>
                        {getValue(choosenWorkOrder, 'interventionComment')}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />
        </Col>
        <Col md={6}>
          <Card
            tableFullWidth
            title="Vehicle information"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Brand:</td>
                      <td>
                        {getValue(choosenWorkOrder, 'companyVehicle.carBrand')}
                      </td>
                    </tr>
                    <tr>
                      <td>Type:</td>
                      <td>
                        {getValue(choosenWorkOrder, 'companyVehicle.carModel')}
                      </td>
                    </tr>
                    <tr>
                      <td>Registration number:</td>
                      <td>
                        {getValue(
                          choosenWorkOrder,
                          'companyVehicle.registrationPlate'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>VIN number:</td>
                      <td>
                        {getValue(choosenWorkOrder, 'companyVehicle.vinNumber')}
                      </td>
                    </tr>
                    <tr>
                      <td>Mileage:</td>
                      <td>
                        {getValue(woDataFromAccident, 'accidentInfo.mileage')}
                      </td>
                    </tr>
                    <tr>
                      <td>Date of first registration:</td>
                      <td>
                        {getValueOrNull(
                          choosenWorkOrder,
                          'companyVehicle.firstRegistrationAt.date'
                        )
                          ? moment(
                              getValueOrNull(
                                choosenWorkOrder,
                                'companyVehicle.firstRegistrationAt.date'
                              )
                            ).format(DATE_FORMAT)
                          : 'Nema podataka'}
                      </td>
                    </tr>
                    <tr>
                      <td>Last service:</td>
                      <td>
                        {getValue(
                          woDataFromAccident,
                          'accidentInfo.lastService.name'
                        )}
                        {getValueOrNull(
                          woDataFromAccident,
                          'accidentInfo.lastServiceDate'
                        )
                          ? `, ${moment(
                              getValue(
                                woDataFromAccident,
                                'accidentInfo.lastServiceDate.date'
                              )
                            ).format(DATE_FORMAT)}`
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Service Mileage</td>
                      <td>
                        {getValue(
                          woDataFromAccident,
                          'accidentInfo.lastServiceMileage'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Destination repairer:</td>
                      <td>
                        {getValue(
                          woDataFromAccident,
                          'destinationRepairer.name'
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />

          {!damageImages.length && (
            <Card
              tableFullWidth
              title="Damage on vehicle"
              content={
                <>
                  <Table striped hover responsive>
                    <tbody>
                      <tr>
                        <td>Car parts:</td>
                        <td>{renderVehicleParts()}</td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              }
            />
          )}
        </Col>
      </Row>

      {!!damageImages.length && (
        <Row>
          <Col md={12}>
            <Card
              tableFullWidth
              title="Damage on vehicle"
              content={
                <>
                  <Table striped hover responsive>
                    <tbody>
                      <tr>
                        <td>Car parts:</td>
                        <td>{renderVehicleParts()}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <div style={{ padding: '10px', marginTop: '10px' }}>
                    {damageImages &&
                      damageImages.map((img: Files) => (
                        <a
                          href={img.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={img.path}
                            height="250px"
                            style={{ borderRadius: '5px', margin: '10px' }}
                            alt={img.mediaTag}
                          />
                        </a>
                      ))}
                  </div>
                </>
              }
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col md={6}>
          <Card
            tableFullWidth
            title="Loading"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Comment:</td>
                      <td>{getValue(choosenWorkOrder, 'loadingComment')}</td>
                    </tr>
                  </tbody>
                </Table>
                <div style={{ padding: '10px', marginTop: '10px' }}>
                  {loadingImages &&
                    loadingImages.map((img: Files) => (
                      <a
                        href={img.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={img.path}
                          height="250px"
                          style={{ borderRadius: '5px', margin: '10px' }}
                          alt={img.mediaTag}
                        />
                      </a>
                    ))}
                </div>
              </>
            }
          />
        </Col>
        <Col md={6}>
          <Card
            tableFullWidth
            title="Unloading"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Comment:</td>
                      <td>{getValue(choosenWorkOrder, 'unloadingComment')}</td>
                    </tr>
                  </tbody>
                </Table>
                <div style={{ padding: '10px', marginTop: '10px' }}>
                  {unloadingImages &&
                    unloadingImages.map((img: Files) => (
                      <a
                        href={img.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={img.path}
                          height="250px"
                          style={{ borderRadius: '5px', margin: '10px' }}
                          alt={img.mediaTag}
                        />
                      </a>
                    ))}
                </div>
              </>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card
            title="Napomena za klijenta i saglasnost za anketiranje"
            content={
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  columnGap: '30px'
                }}
              >
                <div>
                  <p style={{ fontSize: '14px' }}>
                    <b>
                      <u>Napomena:</u>
                    </b>
                  </p>

                  <p style={{ fontSize: '14px' }}>
                    Potvrđujem da ću snositi sve troškove usluge asistencije,
                    ukoliko servis utvrdi da je otkaz na vozilu posledica
                    nepoštovanja upustva za upotrebu, neredovnog održavanja
                    vozila ili je prouzrokovan ličnom nepažnjom. Potvrđujem da
                    sam upoznat/a sa obavezom potpisivanja radnog naloga
                    servisu.
                  </p>
                </div>
                <div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type="checkbox"
                      checked={choosenWorkOrder!.consent}
                      disabled
                      style={{
                        alignSelf: 'flex-start',
                        width: '22px',
                        height: '22px',
                        margin: '0 10px 0 0'
                      }}
                    />
                    <div>
                      <p style={{ fontSize: '14px' }}>
                        <b>
                          <u>Saglasnost korisnika za anketiranje:</u>
                        </b>
                      </p>
                      <p style={{ fontSize: '14px' }}>
                        Potvrđujem da me u okviru ispitivanja zadovoljstva
                        korisnika kontaktira ovlašćena agencija u vezi sa
                        pruženim uslugama asistencije.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card
            tableFullWidth
            title="Signatures"
            content={
              <div
                style={{
                  display: 'flex',
                  padding: '15px',
                  color: '#333',
                  gap: '30px'
                }}
              >
                <div>
                  <p>Client Signature:</p>
                  <img
                    src={choosenWorkOrder ? findImage('signature_client') : ''}
                    alt=""
                    width="250px"
                  />
                </div>
                <div>
                  <p>Driver Signature:</p>
                  <img
                    src={choosenWorkOrder ? findImage('signature_driver') : ''}
                    alt=""
                    width="250px"
                  />
                </div>
                <div>
                  <p>Repairer Signature:</p>
                  <img
                    src={
                      choosenWorkOrder ? findImage('signature_repairer') : ''
                    }
                    alt=""
                    width="250px"
                  />
                </div>
              </div>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default observer(AccidentWorkOrderViewPage);
