import React from 'react';

import imageSrc from 'assets/img/something-went-wrong/image.png';
import style from './ErrorPage.module.scss';

const ErrorPage = () => (
  <div className={style.errorPage}>
    <img src={imageSrc} alt="Something went wrong!" />
    <h1>Ooops! Something went wrong.</h1>
    <p>Please try to refresh this page.</p>
  </div>
);

export default ErrorPage;
