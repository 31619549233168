import { action, observable, runInAction } from 'mobx';

import * as warehouseRepo from 'data/repositories/WarehouseRepo';

import to from 'lib/awaitTo';
import { Toast } from 'lib/dialogs';
import queryBuilder from 'lib/queryBuilder';
import { PromotionCode } from 'data/types';
import { authStore } from 'data/stores/AuthStore';
import { translationStore } from 'data/stores/TranslationStore';

class WarehouseStore {
  @observable warehouseData: any = null;

  @observable promotionCode?: PromotionCode;

  @observable showModal = false;

  fetchWarehouseData = async (mapObjectId: string) => {
    const [response] = await to(warehouseRepo.fetchWarehouseData(mapObjectId));

    if (response) {
      runInAction(() => {
        this.warehouseData = response;
      });
    }
  };

  orderMoreCards = async (numberOfCards: string) => {
    const { mapObject } = authStore;
    const { translations } = translationStore;

    const requestData = {
      mapObjectId: mapObject.id,
      numberOfCards: parseInt(numberOfCards, 10)
    };

    const [response] = await to(warehouseRepo.orderMoreCards(requestData));

    if (response) {
      Toast.fire({
        type: 'success',
        title: `${translations.warehouseSuccessOrderTitle} ${numberOfCards}`
      });
    }
  };

  checkCardStatus = async (serialNum: string) => {
    const queryParams = queryBuilder({
      include: ['client.user', 'bundle', 'mapObject', 'clientBundle']
    });

    const [response] = await to(
      warehouseRepo.getPromoCardBySerialNum(serialNum, { params: queryParams })
    );

    if (response) {
      runInAction(() => {
        this.promotionCode = response;
        this.showModal = true;
      });
    }
  };

  @action
  closeModal = () => {
    this.showModal = false;
  };
}

export const warehouseStore = new WarehouseStore();
export default WarehouseStore;
