import { action, observable, runInAction } from 'mobx';

import to from 'lib/awaitTo';

import clientBundlesRepo from 'data/repositories/ClientBundlesRepo';
import TableStore from 'data/stores/TableStore';
import { authStore } from 'data/stores/AuthStore';
import { Toast } from 'lib/dialogs';
import { ROLE_AFFILIATE_COMPANY } from 'lib/constants';
import { translationStore } from 'data/stores/TranslationStore';
import { exportVulcoStats } from 'data/repositories/CampaignRepos';

class StatsStore extends TableStore<any> {
  @observable csvData = '';

  csvDataHeader = [
    { label: 'Partner ID', key: 'partnerId' },
    { label: 'Partner', key: 'partner' },
    { label: 'Address', key: 'address' },
    { label: 'City', key: 'city' },
    { label: 'Country', key: 'country' },
    { label: 'Activated', key: 'activated' },
    { label: 'Total sum', key: 'totalSum' }
  ];

  @action
  fetchStats = async (params?: any) => {
    if (params) {
      this.queryParams = {
        ...params,
        include: [
          'currentBundle',
          'mapObject',
          'digitalAffiliatePayslip',
          'promotionCode.client'
        ]
      };
    }

    this.getData();
  };

  getData = async () => {
    const { roles, company } = authStore;

    if (roles) {
      if (roles.find((role: any) => role.name === ROLE_AFFILIATE_COMPANY)) {
        const [response] = await to(
          clientBundlesRepo.getClientBundlesByCompanyId(
            company.id,
            this.queryParams
          )
        );

        if (response) {
          this.onFetch(response.data, response.meta);
        }
      } else {
        const [response] = await to(
          clientBundlesRepo.getClientBundlesByMapObjectId(
            authStore.authUserData.mapObject.id,
            this.queryParams
          )
        );
        if (response) {
          this.onFetch(response.data, response.meta);
        }
      }
    }
  };

  @action
  deactivateClientBundle = async (bundleId: string) => {
    const { translations } = translationStore;
    const [response] = await to(
      clientBundlesRepo.deactivateClientBundle(bundleId)
    );

    if (response) {
      this.fetchStats(this.queryParams);

      Toast.fire({
        type: 'success',
        title: translations.successDeactivationPackage
      });
    }
  };

  getCsvData = async () => {
    const [res] = await to(exportVulcoStats());

    if (res) {
      const { results, sum } = res.data;
      runInAction(() => {
        this.csvData = results.map((item: any, key: number) => {
          return {
            partnerId: item.PartnerID,
            partner: item.PartnerEntity,
            address: item.Address,
            city: item.City,
            country: item.Country,
            activated: item.Sold,
            totalSum: key === 0 ? sum.sum : ''
          };
        });
      });
    }
  };

  @action
  clearState = () => {
    this.csvData = '';
  };
}

export const statsStore = new StatsStore(clientBundlesRepo);
export default StatsStore;
