/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import Resizer from 'react-image-file-resizer';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { translationStore } from 'data/stores/TranslationStore';

import { Client } from 'data/types';
import { vulcoCampaignStore } from 'data/stores/VulcoCampaignStore';

import FormField from 'components/FormField';
import Loader from 'components/Loader';
import Uploader from 'components/Uploader';
import RenderIf from 'components/RenderIf';
import { getValueOrEmptyString } from 'lib/getValue';

interface BillFormI {
  history: any;
  client?: Client;
  addressForm?: boolean;
}

const BillForm = ({ history, client, addressForm }: BillFormI) => {
  const { translations } = translationStore;

  if (!translations) {
    return <Loader />;
  }

  const resizeImg = (img: any) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        img,
        1000,
        1000,
        'JPEG',
        30,
        0,
        uri => {
          resolve(uri);
        },
        'file'
      );
    });

  const setImage = async (
    img: any,
    setFieldValue: (key: string, value: any) => void
  ) => {
    const resizedImg = await resizeImg(img);
    return setFieldValue('media', resizedImg);
  };

  const initialValues = {
    billNumber: '',
    media: undefined,
    address: '',
    phoneNumber: client
      ? getValueOrEmptyString(client, 'user.mobileNumber')
      : ''
  };

  const billFormSchema = object().shape({
    billNumber: string().required('Broj racuna je obavezno polje!'),
    media: string().required('Fotografija racuna je obavezno polje!'),
    address: string().required('Adresa je obavezno polje'),
    phoneNumber: string().required('Broj za pošiljku je obavezno polje')
  });

  const onSubmit = (formData: any) => {
    vulcoCampaignStore.createClientCampaign(formData, history);
  };

  return (
    <div>
      <Formik
        validationSchema={billFormSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({
          errors,
          touched,
          isValid,
          loading,
          setFieldValue,
          values
        }: any) => (
          <Form noValidate>
            <FormField
              autoFocus
              errors={errors.billNumber}
              touched={touched.billNumber}
              label="Broj racuna"
              name="billNumber"
              inline={false}
            />

            <Uploader
              file={values.media}
              uploaderChoosePhotoLabel={translations.uploaderChoosePhotoLabel}
              uploaderRemovePhotoLabel={translations.uploaderRemovePhotoLabel}
              uploaderChoosePhotoWindow={translations.uploaderChoosePhotoWindow}
              name="media"
              handleUpload={(file: any) => setImage(file, setFieldValue)}
              handleRemove={() => setFieldValue('media', undefined)}
            />

            <RenderIf condition={!!addressForm}>
              <>
                <FormField
                  errors={errors.address}
                  touched={touched.address}
                  label="Lokacija slanja (grad, poštanski broj i adresa)"
                  name="address"
                  inline={false}
                />

                <FormField
                  errors={errors.phoneNumber}
                  touched={touched.phoneNumber}
                  label="Kontakt telefon za pošiljku"
                  name="phoneNumber"
                  inline={false}
                />
              </>
            </RenderIf>

            <Button
              bsStyle="warning"
              fill
              wd
              type="submit"
              style={{ width: '100%' }}
              disabled={loading || !isValid}
            >
              Sačuvajte i aktiviraj
            </Button>
          </Form>
        )}
      />
    </div>
  );
};

export default BillForm;
