export default class Errors {

  errors: object

  constructor() {
    this.errors = {}
  }

  has(field: string) {
    return Object.prototype.hasOwnProperty.call(this.errors, field)
  }

  any() {
    return Object.keys(this.errors).length > 0
  }

  get(field: string) {
    if (this.errors[field]) {
      return this.errors[field][0]
    }
  }

  record(errors: object) {
    this.errors = errors
  }

  clear(field: string) {
    if (field) {
      delete this.errors[field]
      return
    }
    this.errors = {}
  }

  invalidFields() {
    return Object.keys(this.errors)
  }
}

