import React from 'react';
import { CellInfo } from 'react-table';

const FixedTwoDecimalsCell = ({ value }: CellInfo) => {
  const fixedNumber = parseFloat(value).toFixed(2);

  return <div>{fixedNumber}</div>;
};

export default FixedTwoDecimalsCell;
