import React from 'react';

export default function ForbiddenPage() {
  return (
    <div
      style={{
        width: '100%',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <h1>403</h1>
      <h3>Access denied...</h3>
      <p>You dont have permissions to access this page</p>
    </div>
  );
}
