import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import getValue, { getDateTime } from 'lib/getValue';
import { bundlesStore } from 'data/stores/BundlesStore';
import { Client } from 'data/types';

interface ClientInfoModalProps {
  show: boolean;
  onHide: () => void;
  client: Client;
  translations?: any;
}

const liStyle = {
  marginBottom: '12px'
};

const iconsStyle = {
  marginTop: '5px',
  marginLeft: '5px'
};

const ClientInfoModal = ({
  show,
  onHide,
  client,
  translations
}: ClientInfoModalProps) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{translations.checkClientInformationTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          <b>{translations.checkClientHasPackage}</b>{' '}
          <FaCheckCircle className="text-success" style={iconsStyle} />
        </p>
        <ul>
          <li style={liStyle}>
            <b>{translations.checkClientPackageLabel} </b>
            {getValue(client, 'clientBundle.currentBundle.name')}
          </li>
          <li style={liStyle}>
            <b>{translations.checkClientActivationLabel} </b>
            {getDateTime(getValue(client, 'clientBundle.purchasedAt'))}
          </li>
          <li style={liStyle}>
            <b>{translations.checkClientExpirationLabel} </b>
            {getDateTime(getValue(client, 'clientBundle.expiredAt.date'))}
          </li>
          <li style={liStyle}>
            <b>{translations.checkClientPackageStatusLabel} </b>
            {
              bundlesStore.clientBundleStatuses[
                parseFloat(getValue(client, 'clientBundle.status'))
              ]
            }
          </li>
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Button fill bsStyle="default" onClick={onHide}>
          {translations.warehouseCheckCardModalCloseButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClientInfoModal;
