import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import { RouterProps } from 'react-router';

import ActionCard from 'components/ActionCard';
import Loader from 'components/Loader';

import { navigate } from 'lib/routerHelper';

import { bundlesStore } from 'data/stores/BundlesStore';
import { authStore } from 'data/stores/AuthStore';
import { translationStore } from 'data/stores/TranslationStore';

@observer
class RecommendedPackagePage extends Component<RouterProps> {
  state = {
    perfectPackageId: ''
  };

  componentDidMount() {
    const { match }: any = this.props;
    const { params } = match;
    const { mapObject } = authStore;

    this.setState({
      perfectPackageId: params.bundleId
    });

    bundlesStore.getBundles(mapObject.id);

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  navigateToPage = (key: string, bundleId: string) => {
    const { history } = this.props;
    navigate(key, { history }, { bundleId });
  };

  render() {
    const { translations } = translationStore;
    const { bundles } = bundlesStore;

    if (!bundles || !translations) {
      return <Loader />;
    }
    const { perfectPackageId } = this.state;

    const perfectBundle: any = bundles.find(
      (bundle: any) => bundle.id === perfectPackageId
    );

    const notPerfectBundle: any = bundles.filter(
      (bundle: any) => bundle.id !== perfectPackageId
    );

    return (
      <>
        {perfectBundle && (
          <>
            <h2>
              <b>{perfectBundle.name}</b>{' '}
              {translations.recommendedPackagePerfectBundleText}
            </h2>

            <Row className="m-t-24">
              <Col md={4} key={perfectBundle.id}>
                <ActionCard
                  color={
                    perfectBundle.bundleType === 'domestic' ? 'orange' : 'blue'
                  }
                  title={perfectBundle.name}
                  description={`${perfectBundle.price} ${perfectBundle.currency.displayName}`}
                  buttonText="Izaberi"
                  onClick={() =>
                    this.navigateToPage('buyPackagePage', perfectBundle.id)
                  }
                />
              </Col>
            </Row>

            <h2 className="m-t-24">
              {translations.recommendedPackageRestBundlesText}
            </h2>
            <Row>
              {notPerfectBundle.map((bundle: any) => {
                return (
                  <Col md={4} key={bundle.id}>
                    <ActionCard
                      color={
                        bundle.bundleType === 'domestic' ? 'orange' : 'blue'
                      }
                      title={bundle.name}
                      description={`${bundle.price} ${bundle.currency.displayName}`}
                      buttonText="Izaberi"
                      onClick={() =>
                        this.navigateToPage('buyPackagePage', bundle.id)
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </>
    );
  }
}

export default RecommendedPackagePage;
