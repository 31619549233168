import React from 'react';
import DatePicker, { DatetimepickerProps } from 'react-datetime';

import FormFieldContainer from 'components/FormField/FormFieldContainer';
import { FieldProps } from 'data/types';
import { DATE_FORMAT } from 'lib/constants';

interface DateTimeFieldProps extends FieldProps, DatetimepickerProps {
  inline?: boolean;
}

const defaultProps = {
  dateFormat: DATE_FORMAT,
  timeFormat: false,
  closeOnSelect: true,
  inputProps: {
    placeholder: DATE_FORMAT
  }
};

const DateTimePicker = ({
  label,
  errors,
  touched,
  inline,
  ...restProps
}: DateTimeFieldProps) => (
  <FormFieldContainer
    inline={inline}
    label={label}
    errors={errors}
    touched={touched}
  >
    <DatePicker {...defaultProps} {...restProps} />
  </FormFieldContainer>
);

export default DateTimePicker;
