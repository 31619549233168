/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';
import PrintTemplate from './PrintTemplate/PrintTemplate';

export const anchorStyle = {
  color: '#ff7f11',
  textDecoration: 'underline',
  cursor: 'pointer'
};

const ComponentToPrint = React.forwardRef((props: any, ref: any) => (
  <div style={{ display: 'none' }}>
    <div ref={ref}>
      {props.pageTwo ? (
        <>
          <PrintTemplate title={props.title}>{props.pageOne}</PrintTemplate>
          <PrintTemplate>{props.pageTwo}</PrintTemplate>
        </>
      ) : (
        <PrintTemplate onlyOnePage>{props.pageOne}</PrintTemplate>
      )}
    </div>
  </div>
));

interface PrintComponentProps {
  buttonText: string;
  button?: boolean;
  pageOne: any;
  pageTwo?: any;
  title?: any;
}

const PrintComponent = ({
  buttonText,
  button,
  pageOne,
  pageTwo
}: PrintComponentProps) => {
  const componentRef = useRef() as any;

  const triggerComponent = () => {
    if (button) {
      return (
        <Button bsStyle="warning" fill style={{ width: '100%' }}>
          {buttonText}
        </Button>
      );
    }

    return <a style={anchorStyle}>{buttonText}</a>;
  };

  return (
    <div>
      <ReactToPrint
        trigger={() => triggerComponent()}
        content={() => componentRef.current}
      />
      <ComponentToPrint
        pageOne={pageOne}
        pageTwo={pageTwo}
        ref={componentRef}
      />
    </div>
  );
};

export default PrintComponent;
