import axios, { AxiosRequestConfig } from 'axios';

import { authStore } from 'data/stores/AuthStore';
import { trimWhitespaces } from 'lib/formHelpers';
import { Balance } from 'data/types';

import BaseRepo from './BaseRepo';

const type = 'client-bundles';

const getClientBundlesByMapObjectId = (mapObjectId: string, params: any) => {
  return axios.get(`${type}/map-objects/${mapObjectId}`, { params });
};

const getClientBundlesByCompanyId = (companyId: string, params: any) => {
  return axios.get(`${type}/companies/${companyId}`, { params });
};

const getReferreredClientBundlesByCompanyId = (
  companyId: string,
  params: AxiosRequestConfig
) => {
  return axios.get(`${type}-list-referrers-packages/${companyId}`, params);
};

const getCompanyReferreredClientBundlesByCompanyId = (
  companyId: string,
  params: AxiosRequestConfig
) => {
  return axios.get(`${type}-sold-by-referrer/${companyId}`, params);
};

const activateClientBundle = (
  formData: any,
  bundleId: string,
  companyId: string,
  isTypePayslipPayment: boolean
) => {
  const { language } = authStore;

  const params: any = {
    bundleId,
    userMobileNumber: trimWhitespaces(formData.phoneNum), // If someone writes 381 66 123 456, we want to send 38166123456
    userFirstName: formData.userFirstName || null,
    userLastName: formData.userLastName || null,
    userEmail: trimWhitespaces(formData.userEmail),
    langShortName: language,
    smsMessage: formData.smsMessage,
    smsMessageForRejectedBill: formData.smsMessageForRejectedBill,
    companyId,
    isPayslipPayment: isTypePayslipPayment,
    buyerId: formData.buyerId,
    isCompanyBuyer: formData.isCompanyBuyer,
    include: ['digitalAffiliatePayslip', 'client.user', 'currentBundle']
  };

  if (formData.serialNum) {
    params.code = formData.serialNum;
  }

  return axios.post(`${type}/with-new-client-digital-affiliate`, params);
};

const deactivateClientBundle = (bundleId: string) => {
  return axios.post(`${type}/${bundleId}/deactivate`, {
    digitalAffiliate: true
  });
};

const updateTechnicalInspectionStatus = (id: string, data: Balance): Promise<any> => {
  return axios.post(`${type}/technical-inspection`, { id, data });
};

const clientBundlesRepo = new BaseRepo(type);

export default {
  ...clientBundlesRepo,
  getClientBundlesByMapObjectId,
  getClientBundlesByCompanyId,
  activateClientBundle,
  deactivateClientBundle,
  getReferreredClientBundlesByCompanyId,
  getCompanyReferreredClientBundlesByCompanyId,
  updateTechnicalInspectionStatus
};
