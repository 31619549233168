import { observable, action, runInAction } from 'mobx';

import clientsRepo from 'data/repositories/ClientsRepo';
import vulcoCampaignRepo from 'data/repositories/VulcoCampaignRepo';
import {
  amenitiesRepo,
  assignVozziVulcoWithDeactivation,
  clientCampaignAmenityRepo,
  customerBillRepo,
  uploadMedia
} from 'data/repositories/CampaignRepos';
import ClientBundlesRepo from 'data/repositories/ClientBundlesRepo';
import {
  Bundle,
  BundleFeature,
  CampaignAmenity,
  Client,
  ClientBundle
} from 'data/types';

import to from 'lib/awaitTo';
import { Toast } from 'lib/dialogs';
import { navigate } from 'lib/routerHelper';
import { getValueOrEmptyString } from 'lib/getValue';

import { bundlesStore } from './BundlesStore';
import { authStore } from './AuthStore';
import { translationStore } from './TranslationStore';

class VulcoCampaignStore {
  @observable client?: Client;

  @observable upgradableBundle?: Bundle;

  @observable originalClientBundleId?: string;

  @observable isItIno = false;

  @observable isNew = false;

  @observable categoryTitles?: string[];

  @observable packageIsActivated = false;

  @observable jumpStartItem?: CampaignAmenity;

  @observable jumpStartCreated = false;

  @observable hasBoughtThePackage = false;

  @observable createdClientBundle?: ClientBundle;

  @observable enteredPhoneNumber = '';

  @observable campaignAmenityId = '';

  includes = ['clientBundle.currentBundle', 'user'];

  vozziBundlesKeyNames = [
    'vozzi-rs-2022',
    'vozzi-evropa',
    'vozzi-evropa-max',
    'vozzi-bih-2022',
    'vozzi-evropa-bih',
    'vozzi-evropa-max-bih',
    'nikana-evropa',
    'nikana-greece',
    'vozzi-rs',
    'eu1',
    'eu2',
    'eu3'
  ];

  fetchAmenitiesAndSetJumpStartItem = async () => {
    const [res] = await to(amenitiesRepo.getAll({ params: { limit: 0 } }));

    if (res) {
      const [jumpStart] = res.data;
      runInAction(() => {
        this.jumpStartItem = jumpStart;
      });
    }
  };

  createClientCampaign = async (data: any, history: any) => {
    const { billNumber } = data;
    const { company, mapObject } = authStore;

    if (process.env.REACT_APP_API_URL === 'https://api.prod.vozzi.io/v1/') {
      runInAction(() => {
        this.campaignAmenityId = 'kq4nmy5jrda0pe8j';
      });
    }

    if (process.env.REACT_APP_API_URL === 'https://api.stage.vozzi.io/v1/') {
      runInAction(() => {
        this.campaignAmenityId = '9brpm7q4yq04kzjx';
      });
    }

    // if (!this.jumpStartItem) {
    //   return Toast.fire({
    //     type: 'error',
    //     title: 'Molimo krenite iz pocetka'
    //   });
    // }

    if (!this.campaignAmenityId) {
      return Toast.fire({
        type: 'error',
        title: 'Molimo krenite iz pocetka'
      });
    }
    const [res] = await to(
      customerBillRepo.create({
        billNumber,
        clientBundleId: this.client ? this.client.clientBundle.id : null,
        companyId: company.id
      })
    );

    if (res) {
      const clientCampaignData = {
        clientId: this.client!.id,
        customerBillId: res.id,
        address: data.address,
        phoneNumber: data.phoneNumber,
        // campaignAmenityId: this.jumpStartItem!.id,
        campaignAmenityId: this.campaignAmenityId,
        mapObjectId: mapObject.id
      };
      const [res2] = await to(
        clientCampaignAmenityRepo.create(clientCampaignData)
      );
      const [res3] = await to(uploadMedia({ id: res.id, media: data.media }));

      if (res2 && res3) {
        runInAction(() => {
          this.jumpStartCreated = true;
        });
        Toast.fire({
          type: 'success',
          title: 'Uspesno je kreiran upis'
        });
        navigate(
          'vulcoCampaignSuccess',
          { history },
          { mobileNum: this.client!.user.mobileNumber }
        );
      }
    }

    return null;
  };

  getDataFromClient = (field: string) => {
    if (!this.client) {
      return '';
    }
    return getValueOrEmptyString(this.client, field);
  };

  navigateToBundlesPage = (history: any) => {
    const { bundles } = bundlesStore;
    navigate('buyPackagePage', { history }, { bundleId: bundles[0].id });
  };

  navigateToAmenitiesPage = (history: any) => {
    if (this.client && history) {
      const { user } = this.client;
      navigate(
        'vulcoCampaignAmenities',
        { history },
        { mobileNum: user.mobileNumber }
      );
    } else {
      Toast.fire({
        type: 'error',
        title: 'Ne postoji korisnik, molimo vas pokušajte ponovo'
      });
    }
  };

  fetchClientInfo = async (mobileNum: string, history?: any) => {
    const queryParams = {
      include: this.includes
    };

    const [res, err] = await to(
      clientsRepo.checkClientByMobileNum(mobileNum, { params: queryParams })
    );

    if (res) {
      runInAction(() => {
        this.client = {
          ...res,
          user: {
            ...res.user,
            mobileNumber:
              res.user.mobileNumber[0] === '+'
                ? res.user.mobileNumber.substring(1)
                : res.user.mobileNumber
          }
        };
      });
      if (res.clientBundle) {
        runInAction(() => {
          this.hasBoughtThePackage = this.vozziBundlesKeyNames.includes(
            res.clientBundle.currentBundle.keyName
          );
        });
        if (this.hasBoughtThePackage) {
          this.navigateToAmenitiesPage(history);
        } else {
          this.navigateToBundlesPage(history);
        }
      } else {
        this.navigateToBundlesPage(history);
      }
    } else if (err) {
      runInAction(() => {
        this.enteredPhoneNumber = mobileNum;
      });
      this.navigateToBundlesPage(history);
    }
  };

  @action
  fetchUpgradableBundle = async (clientBundleId: string, history?: any) => {
    this.originalClientBundleId = clientBundleId;

    const [res] = await to(
      vulcoCampaignRepo.fetchUpgradableBundle(clientBundleId)
    );

    if (res) {
      runInAction(() => {
        this.upgradableBundle = res;
        this.isItIno = res.bundleType === 'ino';
        this.isNew = res.isNew;
      });
      this.setCategoryTitles(res.features);

      // Navigate only if history param is passed
      if (history) {
        navigate(
          'vulcoCampaignForm',
          { history },
          { mobileNum: this.client!.user.mobileNumber }
        );
      }
    }
  };

  @action
  setCategoryTitles = (features: BundleFeature[]) => {
    this.categoryTitles = bundlesStore.splitFeaturesByCategoryTitle(features);
  };

  createClientBundle = async (formData: any, bundleId: string) => {
    const { translations } = translationStore;
    const { company } = authStore;

    const [response] = await to(
      ClientBundlesRepo.activateClientBundle(
        {
          ...formData,
          smsMessage: translations.smsMessage,
          smsMessageForRejectedBill: translations.smsMessageVulcoBillRejected
        },
        bundleId,
        company.id,
        false
      )
    );

    if (response) {
      return response;
    }

    return undefined;
  };

  createPackage = (formData: any, bundleId: string, history: any) => {
    if (this.client) {
      const { clientBundle } = this.client;

      if (clientBundle && !this.hasBoughtThePackage) {
        this.deactivateAndCreateClientBundle(formData, bundleId, history);
      } else {
        this.activateClientBundle(formData, history, bundleId);
      }
    } else {
      this.activateClientBundle(formData, history, bundleId);
    }
  };

  activateClientBundle = async (
    formData: any,
    history: any,
    bundleId: string
  ) => {
    // Create Client Bundle
    const createdClientBundle = await this.createClientBundle(
      formData,
      bundleId
    );

    if (!createdClientBundle) {
      return null;
    }

    runInAction(() => {
      this.createdClientBundle = createdClientBundle;
    });

    const [res] = await to(
      customerBillRepo.create({
        billNumber: formData.billNumber,
        clientBundleId: createdClientBundle.id
      })
    );

    if (res) {
      const [resPhoto] = await to(
        uploadMedia({ media: formData.media, id: res.id })
      );

      if (resPhoto) {
        Toast.fire({
          type: 'success',
          title: 'Uspesno ste kreirali paket'
        });

        runInAction(() => {
          this.packageIsActivated = true;
        });

        navigate(
          'vulcoCampaignSuccess',
          { history },
          {
            mobileNum: this.createdClientBundle
              ? this.createdClientBundle.client.user.mobileNumber
              : this.enteredPhoneNumber
          }
        );
      }
    }

    return null;
  };

  deactivateAndCreateClientBundle = async (
    formData: any,
    bundleId: string,
    history: any
  ) => {
    const { company } = authStore;
    const { translations } = translationStore;

    const data = {
      clientId: this.client!.id,
      bundleId,
      companyId: company.id,
      include: ['currentBundle'],
      smsMessage: translations.smsMessage,
      smsMessageForRejectedBill: translations.smsMessageVulcoBillRejected
    };

    const [res] = await to(assignVozziVulcoWithDeactivation(data));

    if (res) {
      runInAction(() => {
        this.createdClientBundle = res;
      });

      const [res2] = await to(
        customerBillRepo.create({
          billNumber: formData.billNumber,
          clientBundleId: res.id
        })
      );

      if (res2) {
        const [resPhoto] = await to(
          uploadMedia({ media: formData.media, id: res2.id })
        );

        if (resPhoto) {
          Toast.fire({
            type: 'success',
            title: 'Uspesno ste kreirali paket'
          });

          runInAction(() => {
            this.packageIsActivated = true;
          });

          navigate(
            'vulcoCampaignSuccess',
            { history },
            { mobileNum: this.client!.user.mobileNumber }
          );
        }
      }
    }
  };

  @action
  clearState = () => {
    this.client = undefined;
    this.createdClientBundle = undefined;
    this.jumpStartCreated = false;
  };
}

export const vulcoCampaignStore = new VulcoCampaignStore();
export default VulcoCampaignStore;
