import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { translationStore } from 'data/stores/TranslationStore';
import { bundlesStore } from 'data/stores/BundlesStore';

import Loader from 'components/Loader';

import { vulcoCampaignStore } from 'data/stores/VulcoCampaignStore';
import ActivatedPackage from '../components/ActivatedPackage';

@observer
class VulcoCampaignSuccessPage extends Component<RouterProps> {
  componentWillUnmount() {
    vulcoCampaignStore.clearState();
  }

  render() {
    const { translations } = translationStore;
    const {
      client,
      createdClientBundle,
      jumpStartCreated,
      enteredPhoneNumber
    } = vulcoCampaignStore;
    const { categoryTitles } = bundlesStore;

    if (!translations) {
      return <Loader />;
    }

    if (jumpStartCreated) {
      return (
        <Row>
          <Col md={8}>
            <h2>Uspešno je kreiran upis!</h2>
            <h4>Vaučer za gorivo se dobija odmah nakon verifikacije računa.</h4>
          </Col>
        </Row>
      );
    }

    if (createdClientBundle) {
      return (
        <ActivatedPackage
          translations={translations}
          client={client}
          enteredPhoneNumber={enteredPhoneNumber}
          activatedBundle={createdClientBundle.currentBundle}
          clientBundle={createdClientBundle}
          categoryTitles={categoryTitles!}
        />
      );
    }

    return null;
  }
}

export default VulcoCampaignSuccessPage;
