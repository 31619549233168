import { observable, action } from 'mobx';
import { ReactNode } from 'react';

import { Meta } from 'data/types';
import { Query } from 'lib/queryBuilder';
import to from 'lib/awaitTo';
import BaseRepo from 'data/repositories/BaseRepo';

interface AbstractStore<T> {
  data: T[];
  meta?: Meta;
  error: boolean;
  loading: boolean;
  queryParams?: Query;
  openItem?: T;
  repo: BaseRepo;
  showModal: boolean;
}

abstract class TableStore<P> implements AbstractStore<P> {
  @observable data: P[] = [];

  @observable loading = false;

  @observable error = false;

  @observable openItem?: P;

  @observable meta?: Meta;

  @observable queryParams?: Query;

  @observable showModal = false;

  repo: BaseRepo;

  includes: string[] = [];

  constructor(repo: BaseRepo) {
    this.repo = repo;
  }

  @action
  onFetch(data: P[], meta: Meta) {
    this.data = data;
    this.meta = meta;
    this.loading = false;
  }

  @action
  onError() {
    this.error = true;
    this.loading = false;
  }

  @action
  fetch = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes
    };
    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );
    if (response && response.data) {
      this.onFetch(response.data, response.meta);
    }
    if (error) {
      this.onError();
    }
  };

  attachCustomColumn = (accessor: string, template: (i: P) => ReactNode) => {
    return this.data.map(item => ({
      ...item,
      [accessor]: template(item)
    }));
  };

  @action
  openModalForm = (item?: P) => {
    this.showModal = true;
    if (item) {
      this.openItem = item;
    }
  };

  @action
  closeModalForm = () => {
    this.showModal = false;
    this.openItem = undefined;
  };
}

export default TableStore;
