import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { bundlesStore } from 'data/stores/BundlesStore';
import { WarningDialog } from 'lib/dialogs';
import { translationStore } from 'data/stores/TranslationStore';
import { upgradeClientBundleStore } from 'data/stores/UpgradeClientBundleStore';

import Card from 'components/Card';
import Loader from 'components/Loader';
import PrintComponent from 'components/PrintComponent';
import BundleWithFeaturesInfo from 'components/BundleWithFeaturesInfo';
import BundleWithFeaturesInfoPrint from 'components/BundleWithFeaturesInfoPrint';
import ActivateUpgradePackage from 'components/ActivatePackageForms/ActivateUpgradePackage';
import { authStore } from 'data/stores/AuthStore';

@observer
class UpgradePackagePage extends Component<RouterProps> {
  componentDidMount() {
    const { upgradableBundles } = upgradeClientBundleStore;
    const { match }: any = this.props;
    const { params } = match;

    if (upgradableBundles.length === 0) {
      upgradeClientBundleStore.getCurrentAndUpgradableBundles({
        userMobileNumber: params.mobileNum
      });
    }

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  upgradePackageDialog = (selectedBundle: any, legalFormData: any) => {
    const { history } = this.props;
    const { translations } = translationStore;
    const { user } = upgradeClientBundleStore;
    const { currentClientBundle } = upgradeClientBundleStore;

    const formData = {
      bundleId: selectedBundle.id,
      clientBundleId: currentClientBundle.id,
      buyerId: legalFormData.buyerId,
      isCompanyBuyer: legalFormData.isCompanyBuyer
    };

    WarningDialog.fire({
      title: `${translations.upgradePackagePageDialogTitle} ${selectedBundle.name}?`,
      text: `${translations.upgradePackagePageDialogText} ${user.mobileNumber}`,
      confirmButtonText: translations.upgradePackagePageDialogConfirmButton,
      cancelButtonText: translations.dialogCancelButton,
      customClass: {
        confirmButton: 'btn btn-warning btn-fill m-r-8',
        cancelButton: 'btn btn-danger btn-fill'
      },
      preConfirm: () =>
        upgradeClientBundleStore.upgradeClientBundle(
          formData,
          history,
          user.mobileNumber
        )
    });
  };

  navigateBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { translations } = translationStore;
    const { isBlocked } = authStore;
    const { user, upgradableBundles } = upgradeClientBundleStore;

    if (upgradableBundles.length === 0 || !translations) {
      return <Loader />;
    }

    const { match }: any = this.props;

    const selectedBundle: any = upgradeClientBundleStore.upgradableBundles.find(
      (bundle: any) => {
        return bundle.id === match.params.bundleId;
      }
    );

    if (!selectedBundle || !user) {
      return <div>{translations.somethingWentWrong}</div>;
    }

    bundlesStore.splitFeaturesByCategoryTitle(selectedBundle.features);
    const { categoryTitles } = bundlesStore;

    return (
      <Row>
        <Col md={8}>
          <BundleWithFeaturesInfo
            selectedBundle={selectedBundle}
            categoryTitles={categoryTitles}
            upgrade
          />
        </Col>

        <Col md={4} style={{ position: 'sticky', top: '10vh' }}>
          <ActivateUpgradePackage
            mobileNumber={user.mobileNumber}
            selectedBundle={selectedBundle}
            onSubmit={this.upgradePackageDialog}
            isBlocked={isBlocked}
          />

          <Card>
            <h3>{translations.cardPrintTitle}</h3>
            <p>{translations.cardPrintText}</p>

            <PrintComponent
              buttonText={translations.printButton}
              button
              pageOne={
                <BundleWithFeaturesInfoPrint
                  selectedBundle={selectedBundle}
                  categoryTitles={categoryTitles}
                  categoryTitleIndex={0}
                  upgrade
                />
              }
              pageTwo={
                <BundleWithFeaturesInfoPrint
                  pageTwo
                  selectedBundle={selectedBundle}
                  categoryTitles={categoryTitles}
                  categoryTitleIndex={1}
                />
              }
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default UpgradePackagePage;
