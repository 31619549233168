import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';

import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { warehouseStore } from 'pages/Warehouse/WarehousePageStore';
import {
  ActivatedCardModalBody,
  NotActivatedCardModalBody,
  ExpiredCardModalBody
} from './components/ModalBodies';

interface CardInfoModalProps {
  show: boolean;
  onHide: () => void;
  translations: any;
}

const CardInfoModal = observer(
  ({ show, onHide, translations }: CardInfoModalProps) => {
    const { promotionCode } = warehouseStore;

    if (!promotionCode) {
      return null;
    }

    const isBeforeToday = (date: string) => {
      return new Date(date).valueOf() < new Date().valueOf();
    };

    const renderCorrectBody = () => {
      switch (true) {
        case promotionCode.activated:
          return ActivatedCardModalBody(promotionCode, translations);

        case isBeforeToday(promotionCode.expiredAt.date):
          return ExpiredCardModalBody(promotionCode, translations);

        case !isBeforeToday(promotionCode.expiredAt.date) &&
          !promotionCode.activated:
          return NotActivatedCardModalBody(promotionCode, translations);

        default:
          return null;
      }
    };

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {translations.warehouseCheckCardModalTitle}{' '}
            <b>{promotionCode.bundle.name}</b>
          </Modal.Title>
        </Modal.Header>

        {renderCorrectBody()}

        <Modal.Footer>
          <Button fill bsStyle="default" onClick={onHide}>
            {translations.warehouseCheckCardModalCloseButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default CardInfoModal;
