import React from 'react';
import { CellInfo } from 'react-table';

const CalculateOurProfitCell = ({ value }: CellInfo) => {
  const ourProfit = value.price - value.ourProfit;
  const fixedNumber = ourProfit.toFixed(2);

  return <div>{fixedNumber}</div>;
};

export default CalculateOurProfitCell;
