import React from 'react';

import { ReactComponent as Logo } from 'assets/logo-driver-s-assistance.svg';
import { ReactComponent as LogoMini } from 'assets/logo-mini-2-0.svg';

const SidebarLogo = () => (
  <a href="/dashboard/welcome">
    <div className="logo" style={{ textAlign: 'center', padding: '30px 15px' }}>
      <LogoMini
        className="visible-on-sidebar-mini"
        style={{ width: '44px', height: 'auto' }}
      />
      <Logo className="visible-on-sidebar-regular" />
    </div>
  </a>
);

export default SidebarLogo;
