import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { RouteProps, Redirect } from 'react-router';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Card from 'vendors/components/Card/Card.jsx';
import Checkbox from 'vendors/components/CustomCheckbox/CustomCheckbox.jsx';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';
import classNames from 'classnames';

// import { ROLE_AFFILIATE_COMPANY } from 'lib/constants';

import { authStore } from 'data/stores/AuthStore';
import AuthLayout from 'layouts/AuthLayout';
import ErrorMessage from 'components/ErrorMessage';
import { LoginParams } from 'data/types';

interface LoginPageState {
  cardHidden: boolean;
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required'),
  password: Yup.string().required('Password is required')
});

@observer
class LoginPage extends Component<RouteProps, LoginPageState> {
  readonly state = {
    cardHidden: true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ cardHidden: false }), 700);
  }

  onSubmit = (data: LoginParams) => {
    authStore.login(data);
  };

  render() {
    const { cardHidden } = this.state;
    const { invalidLogin, loggedIn } = authStore;

    if (loggedIn) {
      return <Redirect to="/dashboard/welcome" />;
      // if (
      //   roles &&
      //   roles.find((role: any) => role.name === ROLE_AFFILIATE_COMPANY)
      // ) {
      //   return <Redirect to="/dashboard/stats" />;
      // }

      // return <Redirect to="/dashboard/home" />;
    }

    return (
      <AuthLayout>
        <Grid>
          <Row>
            <Col md={4} sm={6} mdOffset={4} smOffset={3}>
              <Formik
                validationSchema={loginSchema}
                initialValues={{
                  email: '',
                  password: '',
                  rememberMe: true
                }}
                onSubmit={this.onSubmit}
                render={({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  isValid
                }) => (
                  <Form noValidate>
                    <Card
                      hidden={cardHidden}
                      textCenter
                      title="Login"
                      content={
                        <div>
                          <FormGroup
                            className={classNames({
                              'has-error': errors.email && touched.email
                            })}
                          >
                            <ControlLabel className="field-required">
                              Email address
                            </ControlLabel>
                            <Field
                              autoFocus
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              name="email"
                            />
                            <ErrorMessage
                              message={errors.email}
                              show={!!touched.email}
                            />
                          </FormGroup>
                          <FormGroup
                            className={classNames({
                              'has-error': errors.password && touched.password
                            })}
                          >
                            <ControlLabel className="field-required">
                              Password
                            </ControlLabel>
                            <Field
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              name="password"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              message={errors.password}
                              show={!!touched.password}
                            />
                          </FormGroup>
                          <ErrorMessage
                            message="Invalid email or password"
                            show={invalidLogin}
                          />
                          <Field
                            component={Checkbox}
                            number="rememberMe"
                            label="Remember me"
                            name="rememberMe"
                            checked={values.rememberMe}
                            onChange={() => {
                              setFieldValue('rememberMe', !values.rememberMe);
                            }}
                          />
                        </div>
                      }
                      legend={
                        <Button
                          bsStyle="warning"
                          fill
                          wd
                          type="submit"
                          disabled={!isValid}
                        >
                          Login
                        </Button>
                      }
                      ftTextCenter
                    />
                  </Form>
                )}
              />
            </Col>
          </Row>
        </Grid>
      </AuthLayout>
    );
  }
}

export default LoginPage;
