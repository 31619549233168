import { Accident } from 'data/types';
import getValue, { attributesJoin, getDate } from 'lib/getValue';
import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Card from 'vendors/components/Card/Card.jsx';

interface AccidentDetails {
  accident: Accident;
}

const AccidentDetails = ({ accident }: AccidentDetails) => (
  <Card
    title="Accident details"
    content={
      <Row>
        <Col md={6}>
          <Table responsive striped hover>
            <tbody>
              <tr>
                <td>Accident created by</td>
                <td>
                  {attributesJoin(
                    accident,
                    'accidentInfo.createdBy.firstName',
                    'accidentInfo.createdBy.lastName'
                  )}
                </td>
              </tr>
              <tr>
                <td>Client vehicle</td>
                <td>
                  {attributesJoin(
                    accident,
                    'companyVehicle.carBrand',
                    'companyVehicle.carModel',
                    'companyVehicle.registrationPlate'
                  )}
                </td>
              </tr>
              <tr>
                <td>Vehicle country of registration</td>
                <td>{getValue(accident, 'companyVehicle.country.name')}</td>
              </tr>
              <tr>
                <td>VIN number</td>
                <td>{getValue(accident, 'companyVehicle.vinNumber')}</td>
              </tr>
              <tr>
                <td>Mileage</td>
                <td>{getValue(accident, 'accidentInfo.mileage')}</td>
              </tr>
              <tr>
                <td>Last service</td>
                <td>{getValue(accident, 'accidentInfo.lastService.name')}</td>
              </tr>
              <tr>
                <td>Last service date</td>
                <td>
                  {getDate(
                    getValue(accident, 'accidentInfo.lastServiceDate.date')
                  )}
                </td>
              </tr>
              <tr>
                <td>Last service mileage</td>
                <td>{getValue(accident, 'accidentInfo.lastServiceMileage')}</td>
              </tr>
              <tr>
                <td>Distance B to C</td>
                <td>{getValue(accident, 'distanceBToC')} km</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col md={6}>
          <Table responsive striped hover>
            <tbody>
              <tr>
                <td>Accident Club</td>
                <td>{getValue(accident, 'accidentClub.name')}</td>
              </tr>
              <tr>
                <td>Num of passengers</td>
                <td>{getValue(accident, 'accidentInfo.numOfPassengers')}</td>
              </tr>
              <tr>
                <td>Accident country</td>
                <td>{getValue(accident, 'country.shortname')}</td>
              </tr>
              <tr>
                <td>Accident description</td>
                <td>{getValue(accident, 'accidentInfo.description')}</td>
              </tr>
              <tr>
                <td>Accident fault classification</td>
                <td>
                  {getValue(accident, 'accidentFaultClassification.name')}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    }
  />
);

export default AccidentDetails;
