import React from 'react';
import { Table } from 'react-bootstrap';
import Card from 'vendors/components/Card/Card.jsx';

import { Accident } from 'data/types';
import getValue, { getDateTime, attributesJoin } from 'lib/getValue';

interface GeneralDetailsProps {
  accident: Accident;
}

const GeneralDetails = ({ accident }: GeneralDetailsProps) => (
  <Card
    title="General details"
    content={
      <Table responsive striped hover>
        <tbody>
          <tr>
            <td>Accident Type</td>
            <td>{getValue(accident, 'accidentType.name')}</td>
          </tr>
          <tr>
            <td>Accident created</td>
            <td>{getDateTime(getValue(accident, 'timeAccidentCreatedAt'))}</td>
          </tr>
          <tr>
            <td>Driver arrived at</td>
            <td>{getDateTime(getValue(accident, 'timeDriverArrivedAt'))}</td>
          </tr>
          <tr>
            <td>Client</td>
            <td>
              {attributesJoin(
                accident,
                'clientB2b.firstName',
                'clientB2b.lastName'
              )}{' '}
              {getValue(accident, 'clientB2b.phoneNumber')}
            </td>
          </tr>
        </tbody>
      </Table>
    }
  />
);

export default GeneralDetails;
