import axios from 'axios';
import BaseRepo from './BaseRepo';

const type = 'bundles';

const getBundlesByMapObject = (mapObjectId: string) => {
  return axios.get(
    `${type}/map-objects/${mapObjectId}?include[]=features.thumbnail&include[]=backgroundThumbnail`
  );
};

const bundlesRepo = new BaseRepo(type);

export default { ...bundlesRepo, getBundlesByMapObject };
