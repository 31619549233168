import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { navigate } from 'lib/routerHelper';

import { bundlesStore } from 'data/stores/BundlesStore';
import { warehouseStore } from 'pages/Warehouse/WarehousePageStore';
import { buyPackageStore } from 'data/stores/BuyPackageStore';
import { authStore } from 'data/stores/AuthStore';
import { translationStore } from 'data/stores/TranslationStore';

import Card from 'components/Card';
import BundleWithFeaturesInfoPrint from 'components/BundleWithFeaturesInfoPrint';
import RenderIf from 'components/RenderIf';
import PrintComponent from 'components/PrintComponent';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';
import Loader from 'components/Loader';
import { anchorStyle } from 'components/PrintComponent/PrintComponent';

const styleLi = {
  marginBottom: '15px',
  fontSize: '16px'
};
@observer
class BoughtPackagePage extends Component<RouterProps> {
  state = { pageNum: 0 };

  componentDidMount() {
    const { mapObject } = authStore;
    const { boughtBundle } = buyPackageStore;
    const { categoryTitles } = bundlesStore;
    const { history } = this.props;

    if (!boughtBundle) {
      navigate('choosePackages', { history });
    }

    if (!warehouseStore.warehouseData) {
      warehouseStore.fetchWarehouseData(mapObject.id);
    }

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }

    this.firstRsAndThenEu(categoryTitles);
  }

  navigateBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  firstRsAndThenEu = (categoryTitles: string[]) => {
    if (
      categoryTitles[0] === 'Usluge u RS' ||
      categoryTitles[0] === 'USLUGE PAKETA'
    ) {
      this.setState({ pageNum: 0 });
    } else {
      this.setState({ pageNum: 1 });
    }
  };

  render() {
    const { translations } = translationStore;
    const { country } = authStore;
    const {
      bundles,
      promoPackageKeyNames,
      categoryTitles,
      territoryTitle
    } = bundlesStore;
    const { warehouseData } = warehouseStore;
    const { isTypePayslipPayment, fullDigital } = authStore;
    const {
      clientMobileNumber,
      payslipFile,
      createdClientBundle,
      boughtBundle
    } = buyPackageStore;

    if (bundles.length === 0 || !warehouseData || !translations) {
      return <Loader />;
    }

    if (!boughtBundle) {
      return <h2>{translations.somethingWentWrong}</h2>;
    }
    const { pageNum } = this.state;
    const onlyOnePage = categoryTitles.length === 1;
    const catIndexSecondPage = () => {
      if (country === 'rs') {
        if (pageNum === 0) {
          return 1;
        }
        return 0;
      }
      return 1;
    };

    return (
      <>
        <Row>
          <Col md={8}>
            <h2>
              {translations.package} <b>{boughtBundle.name}</b>{' '}
              {translations.packageActivatedOnNumber}{' '}
              <b>{clientMobileNumber}</b>
            </h2>
            <ul>
              <li style={styleLi}>
                {translations.packageActivatedDescription}
              </li>
              <li style={styleLi}>
                {translations.packageActivatedPrintDocs}
                <ul style={{ marginTop: '15px' }}>
                  <li style={styleLi}>
                    <PrintComponent
                      pageOne={
                        <BundleWithFeaturesInfoPrint
                          selectedBundle={boughtBundle}
                          categoryTitles={categoryTitles}
                          categoryTitleIndex={country === 'rs' ? pageNum : 0}
                          clientBundle={createdClientBundle}
                        />
                      }
                      pageTwo={
                        !onlyOnePage && (
                          <BundleWithFeaturesInfoPrint
                            pageTwo
                            selectedBundle={boughtBundle}
                            territoryTitle={territoryTitle}
                            categoryTitles={categoryTitles}
                            categoryTitleIndex={catIndexSecondPage()}
                            clientBundle={createdClientBundle}
                          />
                        )
                      }
                      buttonText={translations.printLinkPackageContent}
                    />
                  </li>
                </ul>
              </li>

              <RenderIf condition={!fullDigital}>
                <li style={styleLi}>{translations.packageActivatedGiveCode}</li>
              </RenderIf>
              <RenderIf
                condition={
                  isTypePayslipPayment &&
                  !promoPackageKeyNames.includes(boughtBundle.keyName)
                }
              >
                <li style={styleLi}>
                  {translations.printPayslip}
                  <ul style={{ marginTop: '15px' }}>
                    <li>
                      <a
                        href={`https://${payslipFile.filePath}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={anchorStyle}
                      >
                        {translations.payslip}
                      </a>
                    </li>
                  </ul>
                </li>
              </RenderIf>
            </ul>
          </Col>
          <Col md={4}>
            <Card>
              <p>{translations.packageActivatedDeactivateText}</p>
              <Link to="/dashboard/stats">
                <Button bsStyle="danger" fill wd style={{ width: '100%' }}>
                  {translations.deactivateButton}
                </Button>
              </Link>
            </Card>
            <RenderIf
              condition={
                isTypePayslipPayment &&
                !promoPackageKeyNames.includes(boughtBundle.keyName)
              }
            >
              <Card>
                <p>{translations.printPayslip}</p>
                <a
                  href={`https://${payslipFile.filePath}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'white', width: '100%' }}
                  className="btn btn-fill btn-warning btn-wd"
                >
                  {translations.payslip}
                </a>
              </Card>
            </RenderIf>
          </Col>
        </Row>
        <RenderIf
          condition={
            isTypePayslipPayment &&
            !promoPackageKeyNames.includes(boughtBundle.keyName)
          }
        >
          <Row style={{ marginTop: '24px' }}>
            <Col md={8}>
              <embed
                src={`https://${payslipFile.filePath}`}
                width="100%"
                height="600px"
              />
            </Col>
          </Row>
        </RenderIf>
      </>
    );
  }
}

export default BoughtPackagePage;
