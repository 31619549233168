import { observable, action } from 'mobx';

class LayoutStore {
  @observable sidebar = {
    mini: false
  };

  @action
  toggleSidebar = () => {
    this.sidebar.mini = !this.sidebar.mini;
    document.body.classList.toggle('sidebar-mini');
  };
}

export const layoutStore = new LayoutStore();
export default LayoutStore;
