import React from 'react';
import { RouterProps } from 'react-router';
import { Table } from 'react-bootstrap';
import { MdFolderOpen } from 'react-icons/md';
import Card from 'vendors/components/Card/Card.jsx';

import getValue, { getDateTime, attributesJoin } from 'lib/getValue';
import { navigate } from 'lib/routerHelper';
import { Accident, WorkOrder, VehiclePart } from 'data/types';

import { TableActionBtn } from 'components/TableActions';
// import { accidentWorkOrderStore } from 'pages/AccidentWorkOrder/AccidenWorkOrderStore';
import { accidentDetailsStore } from 'pages/AccidentDetails/AccidentDetailsStore';

import PrintComponent from './components/PrintComponent';
import styles from './WorkOrders.module.scss';

interface DigitalWorkOrdersProps extends RouterProps {
  accidentWorkOrders?: WorkOrder[];
  accident: Accident;
}

const WorkOrdersList = ({
  accidentWorkOrders,
  history,
  accident
}: DigitalWorkOrdersProps) => {
  // const { setWorkOrderFormAccident } = accidentWorkOrderStore;

  return (
    <>
      <Card
        title="Work Orders"
        content={
          <>
            {accidentWorkOrders && accidentWorkOrders.length > 0 ? (
              <>
                <Table responsive striped hover>
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <th>Created At</th>
                      <th>Vehicle</th>
                      <th>Registration Plate</th>
                      <th>Company</th>
                      <th>Damage on vehicle</th>
                      <th>Damaged vehicle parts</th>
                      <th>Status</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                    {accidentWorkOrders.map((workOrder: WorkOrder) => (
                      <tr key={workOrder.id}>
                        <td
                          className={
                            workOrder.status === 'closed'
                              ? styles.greyRowColor
                              : ''
                          }
                        >
                          {getValue(workOrder, 'realId')}
                        </td>
                        <td>
                          {getDateTime(getValue(workOrder, 'createdAt.date'))}
                        </td>
                        <td>
                          {attributesJoin(
                            workOrder,
                            'companyVehicle.carBrand',
                            'companyVehicle.carModel'
                          )}
                        </td>
                        <td>
                          {getValue(
                            workOrder,
                            'companyVehicle.registrationPlate'
                          )}
                        </td>
                        <td>
                          {getValue(workOrder, 'companyVehicle.company.name')}
                        </td>
                        <td>{workOrder.damageOnVehicle ? 'Yes' : 'No'}</td>
                        <td>
                          {workOrder.damagedVehicleParts
                            ? workOrder.damagedVehicleParts.map(
                                (partObj: VehiclePart, index: number) => {
                                  return index ? `, ${partObj}` : `${partObj}`;
                                }
                              )
                            : 'N/A'}
                        </td>
                        <td>{getValue(workOrder, 'status')}</td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <TableActionBtn
                              tooltip="Open document"
                              onClick={() => {
                                accidentDetailsStore.setChoosenWorkOrder(
                                  workOrder
                                );

                                navigate(
                                  'forceWorkOrderView',
                                  { history },
                                  {
                                    id: accident.id,
                                    workOrderId: workOrder.id
                                  }
                                );
                              }}
                              reactIcon={<MdFolderOpen />}
                              iconColor={
                                workOrder.status === 'open'
                                  ? '#78B414'
                                  : '#d0d0d0'
                              }
                              id="edit-role"
                            />

                            <PrintComponent
                              onlyIconWithToolTip
                              workOrderData={{
                                ...accident,
                                workOrder,
                                clientLocation: getValue(
                                  accident,
                                  'interventionLocation'
                                )
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <p>There are no Work Orders for this accident.</p>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default WorkOrdersList;
