import { observable, action, runInAction } from 'mobx';

import { trimWhitespaces } from 'lib/formHelpers';
import { navigate } from 'lib/routerHelper';
import { Toast, WarningDialog } from 'lib/dialogs';
import to from 'lib/awaitTo';
import { GetCurrentAndUpgradableBundlesFromData } from 'data/types';

import upgradeClientBundleRepo from 'data/repositories/UpgradeClientBundleRepo';
import externalInsuranceRepo from 'data/repositories/ExternalInsuranceRepo';

import { authStore } from './AuthStore';
import { translationStore } from './TranslationStore';

class InsuranceStore {
  @observable currentBundle: any = {};

  @observable currentClientBundle: any = {};

  @observable balances: any = [];

  @observable client: any = {};

  @observable isInsuraceFeatureValid = false;

  @observable externalInsuranceBalance: any = {};

  @action
  createInsuranceReport = async (type: string, history: any) => {
    const { mapObject } = authStore;
    const { translations } = translationStore;

    const data = {
      type,
      clientBundleId: this.currentClientBundle.id,
      clientId: this.client.id,
      mapObjectId: mapObject.id,
      externalInsuranceBalanceId: this.externalInsuranceBalance.id
    };

    const [res] = await to(externalInsuranceRepo.create(data));

    if (res) {
      Toast.fire({
        type: 'success',
        title: translations.insuranceSuccessTitle
      });
      navigate('insuranceInformations', { history });
    }
  };

  @action
  getCurrentAndUpgradableBundles = async (
    formData: GetCurrentAndUpgradableBundlesFromData,
    history?: any
  ) => {
    const { translations } = translationStore;

    const noWhiteSpacesFormData = {
      ...formData,
      userMobileNumber: trimWhitespaces(formData.userMobileNumber)
    };

    // We can use same endpoint for this
    const [response] = await to(
      upgradeClientBundleRepo.getCurrentClientBundleAndUpgradableBundles({
        params: noWhiteSpacesFormData
      })
    );

    if (response) {
      runInAction(() => {
        this.client = response.client;
        this.currentClientBundle = response;
        this.currentBundle = response.currentBundle;
      });
      this.setBalancesValues(response.balances);

      if (history) {
        navigate(
          'insuranceReport',
          { history },
          { mobileNum: formData.userMobileNumber }
        );
      }
    } else {
      WarningDialog.fire({
        type: 'info',
        title: translations.insuranceClientNotActivatePackage,
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn btn-fill btn-info'
        }
      });
    }
  };

  @action
  setBalancesValues = (balances: any) => {
    this.balances = balances;

    this.externalInsuranceBalance = this.balances.find(
      (balance: any) =>
        balance.accidentServiceType.name === 'external_insurance'
    );

    this.isInsuraceFeatureValid = this.balances.some(
      (balance: any) =>
        balance.accidentServiceType.name === 'external_insurance' &&
        balance.status === 'active'
    );
  };
}

export const insuranceStore = new InsuranceStore();
export default InsuranceStore;
