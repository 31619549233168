import { action, observable } from 'mobx';

import invoicesRepo from 'data/repositories/InvoicesRepo';
import specificationsRepo from 'data/repositories/SpecificationsRepo';
import TableStore from 'data/stores/TableStore';

import to from 'lib/awaitTo';
import { Meta } from 'data/types';
import { Query } from 'lib/queryBuilder';
import { authStore } from 'data/stores/AuthStore';
import { ReactNode } from 'react';

class InvoicesStore extends TableStore<any> {
  INVOICES_TABS = {
    invoices: 'invoices',
    specifications: 'specifications'
  };

  POS_PAYMENT_TYPES = {
    standard: 'standard',
    payslip: 'payslip',
    promo: 'promo',
    referred: 'affiliate_referred',
    anonymous: 'affiliate_anonymous'
  };

  @observable dataSpecifications = [] as any;

  @observable page = this.INVOICES_TABS.invoices as string;

  includes = ['company.country'];

  @action
  fetchInvoices = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.data = [];
    const { mapObject } = authStore;

    this.queryParams = {
      ...params,
      include: this.includes,
      from: '2022-11-01'
    };

    const [response, error] = await to(
      invoicesRepo.listAllInvoicesByMapObject(mapObject.id, {
        params: this.queryParams
      })
    );
    if (response && response.data) {
      this.onFetch(response.data, response.meta);
    }
    if (error) {
      this.onError();
    }
  };

  attachCustomColumnSpecifications = (
    accessor: string,
    template: (i: any) => ReactNode
  ) => {
    return this.dataSpecifications.map((item: any) => ({
      ...item,
      [accessor]: template(item)
    }));
  };

  @action
  onFetchSpecifications(data: any[], meta: Meta) {
    this.dataSpecifications = data;
    this.meta = meta;
    this.loading = false;
  }

  @action
  fetchSpecifications = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.data = [];
    const { mapObject } = authStore;

    this.queryParams = {
      ...params,
      include: [...this.includes, 'affiliateInvoice'],
      from: '2022-11-01'
    };

    const [response, error] = await to(
      specificationsRepo.listAllSpecificationsByMapObject(mapObject.id, {
        params: this.queryParams
      })
    );
    if (response && response.data) {
      this.onFetchSpecifications(response.data, response.meta);
    }
    if (error) {
      this.onError();
    }
  };

  downloadPDF = (file: any) => {
    // Create an invisible A element
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    // Set the HREF to a Blob representation of the data to be downloaded
    // a.href = window.URL.createObjectURL(blob || new File([data], fileName));
    a.setAttribute('target', '_blank');

    a.href = `http://${file.filePath}`;

    // Use download attribute to set set desired file name
    a.setAttribute('download', file.fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    // window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  @action
  changeToPage = (page: string) => {
    this.page = page;
  };

  @action
  clearStore = () => {
    this.data = [];
    this.dataSpecifications = [];
  };
}

export const invoicesStore = new InvoicesStore(invoicesRepo);
export default InvoicesStore;
