/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './vendors/css/light-bootstrap-dashboard-pro-react.css';
import './vendors/css/pe-icon-7-stroke.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'config/mobx';
import 'config/axios';
import 'styles/index.scss';
import NotFound from 'pages/NotFound';
import routes from 'routes';
import RouteWithSubRoutes from 'components/RoutesWithSubRoutes';
import Log from 'components/Log';
import LoadingBar from 'components/LoadingBar';
import * as serviceWorker from './serviceWorker';

// We only want in production to capture errors
Sentry.init({
  dsn:
    'https://7a54748c02d74dfbaf17b60615d903bd@o563549.ingest.sentry.io/5703646',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_API_URL === 'https://api.prod.vozzi.io/v1/'
});

ReactDOM.render(
  <BrowserRouter>
    <LoadingBar />
    <Log />
    <Switch>
      <Redirect exact from="/" to="/login" />
      {routes.map(route => (
        <RouteWithSubRoutes {...route} />
      ))}
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.clearCache();
serviceWorker.unregister();
