import axios from 'axios';
import BaseRepo from './BaseRepo';

const type = 'accidents';

const commentsRepo = new BaseRepo(type);

const addAccidentCommentRepo = (data: any, accidentId: string) => {
  return axios.post(`${type}/${accidentId}/comments`, data);
};

export default { ...commentsRepo, addAccidentCommentRepo };
