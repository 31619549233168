import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink } from 'react-csv';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { WarningDialog } from 'lib/dialogs';
import {
  ROLE_AFFILIATE_MARKETPLACE,
  TYPE_AFFILIATE_ANONYMOUS
} from 'lib/constants';
import checkRoles from 'lib/checkRoles';
import { haveSameMembers } from 'lib/routerHelper';

import { translationStore } from 'data/stores/TranslationStore';
import { authStore } from 'data/stores/AuthStore';
import { buyPackageStore } from 'data/stores/BuyPackageStore';

import DataTable from 'components/DataTable';
import RenderIf from 'components/RenderIf';
import Loader from 'components/Loader';
import FixedTwoDecimalsCell from 'components/TableCells/FixedTwoDecimalsCell';
import AnonymousSendEmailCell from 'components/TableCells/AnonymousSendEmailCell';
import SendEmailModal from 'pages/BoughtPackageAnonymous/components/SendEmailModal';

import { statsStore } from './StatsStore';

@observer
class StatsPage extends Component {
  state = {
    hasMarketplaceRole: true
  };

  componentDidMount() {
    const { roles } = authStore;

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }

    const hasMapObjectRole = checkRoles(roles, [ROLE_AFFILIATE_MARKETPLACE]);
    this.setState({ hasMarketplaceRole: hasMapObjectRole });
  }

  componentWillUnmount(): void {
    statsStore.clearState();
  }

  renderColumns = () => {
    const { translations } = translationStore;
    const { companyTypes } = authStore;
    const { hasMarketplaceRole } = this.state;

    const columns = [
      {
        Header: translations.statisticsTableBundleName,
        id: 'currentBundle.name',
        accessor: (clientBundle: any) => {
          if (clientBundle.currentBundle) {
            return clientBundle.currentBundle.name;
          }

          return clientBundle.bundleName;
        },
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableDate,
        accessor: 'purchasedAt',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableMobileNumber,
        accessor: 'userMobileNumber',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTablePrice,
        accessor: 'price',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableProfit,
        accessor: 'profit',
        sortable: false,
        filterable: false,
        Cell: FixedTwoDecimalsCell
      },
      {
        Header: 'QR',
        accessor: 'promotionCode',
        sortable: false,
        filterable: false,
        Cell: AnonymousSendEmailCell,
        show: haveSameMembers(companyTypes, [TYPE_AFFILIATE_ANONYMOUS])
      },
      {
        Header: translations.statisticsTableObject,
        accessor: 'mapObject.name',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.dataTableActions,
        accessor: 'actions',
        show: hasMarketplaceRole,
        filterable: false,
        sortable: false
      }
    ];
    return columns;
  };

  deactivatePackage = (bundleData: any) => {
    const { translations } = translationStore;

    WarningDialog.fire({
      title: translations.deactivatePackageDialogTitle,
      text: `${translations.deactivatePackageDialogText} ${bundleData.userMobileNumber}`,
      confirmButtonText: translations.deactivatePackageDialogConfirmButton,
      cancelButtonText: translations.dialogCancelButton,
      customClass: {
        confirmButton: 'btn btn-danger btn-fill m-r-8',
        cancelButton: 'btn btn-default btn-fill'
      },
      preConfirm: () => statsStore.deactivateClientBundle(bundleData.id)
    });
  };

  itemAction = (bundleData: any) => {
    const { translations } = translationStore;
    const { hasMarketplaceRole } = this.state;

    if (hasMarketplaceRole) {
      return (
        <Button
          fill
          pullRight
          bsStyle={bundleData.status !== 2 ? 'success' : 'danger'}
          onClick={() => this.deactivatePackage(bundleData)}
          disabled={bundleData.status !== 2}
        >
          {bundleData.status !== 2
            ? translations.activatedButton
            : translations.deactivateButton}
        </Button>
      );
    }

    return null;
  };

  render() {
    const { translations } = translationStore;
    const { company, isVulco } = authStore;
    const { hasMarketplaceRole } = this.state;
    const { showSendEmailModal, closeSendEmailModal } = buyPackageStore;
    const { csvData, csvDataHeader } = statsStore;

    if (!translations) {
      return <Loader />;
    }

    if (!hasMarketplaceRole) {
      if (!company) {
        return <Loader />;
      }
    }
    return (
      <div>
        <h3 style={{ marginTop: 0 }}>{translations.statisticsTableTitle}</h3>
        <RenderIf condition={isVulco && !hasMarketplaceRole}>
          <>
            <Button
              className="m-b-24"
              fill
              bsStyle="default"
              onClick={statsStore.getCsvData}
            >
              Generate data for the export
            </Button>
            <CSVLink
              filename="export-digital-affiliate-invoices.csv"
              data={csvData}
              headers={csvDataHeader}
            >
              <Button
                className="m-b-24 m-l-16 btn-success btn-fill"
                disabled={csvData.length === 0}
              >
                Download CSV
              </Button>
            </CSVLink>
          </>
        </RenderIf>
        <DataTable
          dateRangeFilter
          className={!hasMarketplaceRole ? 'withoutActionsCell' : ''}
          columns={this.renderColumns()}
          fetchData={statsStore.fetchStats}
          data={statsStore.attachCustomColumn('actions', this.itemAction)}
          meta={statsStore.meta}
          previousText={translations.statsTablePrevious}
          nextText={translations.statsTableNext}
          pageText={translations.statsTablePage}
          ofText={translations.statsTableOfText}
          rowsText={translations.statsTableRowsText}
          loading={statsStore.loading}
          defaultSort={[{ id: 'createdAt', desc: true }]}
        />
        <SendEmailModal
          showModal={showSendEmailModal}
          closeModalForm={closeSendEmailModal}
        />
      </div>
    );
  }
}

export default StatsPage;
