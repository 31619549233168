import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import { TYPE_AFFILIATE_REFERRED, TYPE_PAYSLIP_PAYMENT } from 'lib/constants';
import { authStore } from 'data/stores/AuthStore';
import { notificationStore } from 'data/stores/NotificationStore';
import { translationStore } from 'data/stores/TranslationStore';

import RenderIf from 'components/RenderIf';
import Loader from 'components/Loader';
import InvoicesTabs from './components/InvoicesTabs';
import InvoicesListPage from './components/InvoicesListPage';
import SpecificationsListPage from './components/SpecificationsListPage';

type TParams = { tab: 'invoices' | 'specifications' | ':tab' };
type ComponentType = 'invoices' | 'specifications';

const InvoicesPage = (props: RouteComponentProps<TParams>) => {
  const [component, setComponent] = useState<ComponentType>('invoices');
  const [onlySpecifications, setOnlySpecifications] = useState(false);
  const { match, history } = props;

  useEffect(() => {
    if (onlySpecifications) {
      setComponent('specifications');
      history.push('/dashboard/invoices/specifications');
    } else if (match.params.tab === 'invoices') {
      setComponent('invoices');
    } else if (match.params.tab === 'specifications') {
      setComponent('specifications');
    } else if (match.params.tab === ':tab') {
      setComponent('invoices');
      history.push('/dashboard/invoices/invoices');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.tab, onlySpecifications]);

  const { companyTypes } = authStore;
  useEffect(() => {
    const showOnlySpecifications =
      companyTypes.includes(TYPE_PAYSLIP_PAYMENT) ||
      companyTypes.includes(TYPE_AFFILIATE_REFERRED);

    setOnlySpecifications(showOnlySpecifications);
  }, [companyTypes]);

  const { showInvoiceNotification } = notificationStore;
  useEffect(() => {
    if (showInvoiceNotification) {
      notificationStore.updateLocalStorage();
    }
  }, [showInvoiceNotification]);

  const { translations } = translationStore;

  if (!translations) {
    return <Loader />;
  }

  return (
    <>
      <RenderIf condition={!!component && !onlySpecifications}>
        <InvoicesTabs history={history} component={component} />
      </RenderIf>
      <RenderIf condition={component === 'invoices' && !onlySpecifications}>
        <InvoicesListPage />
      </RenderIf>
      <RenderIf condition={component === 'specifications'}>
        <SpecificationsListPage />
      </RenderIf>
    </>
  );
};

export default observer(InvoicesPage);
