import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import styles from './TableActions.module.scss';

interface TableActionBtn {
  onClick: () => void;
  tooltip: string;
  buttonEnabled?: boolean;
  icon?: string;
  reactIcon?: any;
  iconColor: string;
  id: string;
}

function TableActionBtn({
  onClick,
  tooltip,
  buttonEnabled,
  icon,
  reactIcon,
  iconColor,
  id
}: TableActionBtn) {
  const skipButtonEnabled = typeof buttonEnabled === 'undefined';

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
    >
      <Button
        className={styles.action}
        disabled={skipButtonEnabled ? false : !buttonEnabled}
        style={{ color: iconColor }}
        onClick={onClick}
        simple
      >
        {reactIcon || <i className={icon} />}
      </Button>
    </OverlayTrigger>
  );
}

export default TableActionBtn;
