import React from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import FormField from 'components/FormField';
import { translationStore } from 'data/stores/TranslationStore';
import Loader from 'components/Loader';

interface CheckClientFormI {
  onSubmit: any;
}

const CheckClientForm = ({ onSubmit }: CheckClientFormI) => {
  const { translations } = translationStore;

  if (!translations) {
    return <Loader />;
  }

  const regexCountryCallingCode = translations.countryCallingCodeRegex;

  const regexCountryCallingCodePhoneNumberValidation =
    translations.phoneNumberValidationRegex;

  const minPhoneNum = parseFloat(translations.phoneNumberValidationMin);

  // Regex for Country Calling Code and Phone Num Validation
  const regexCountryCode = new RegExp(regexCountryCallingCode);

  const regexPhoneNumValidation = new RegExp(
    regexCountryCallingCodePhoneNumberValidation
  );

  const validationSchema = object().shape({
    mobileNumber: string()
      .matches(regexCountryCode, {
        message: translations.countryCallingCodeMessage
      })
      .matches(regexPhoneNumValidation, {
        message: translations.phoneNumberValidationMessage
      })
      .min(minPhoneNum, translations.phoneNumberValidationMinMessage)
      .required(translations.phoneNumberValidationRequiredMessage)
  });

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          mobileNumber: translations.phoneNumberCodeInitial
        }}
        onSubmit={onSubmit}
        render={({ errors, touched, isValid, loading }: any) => (
          <Form noValidate>
            <FormField
              autoFocus
              errors={errors.phoneNum}
              touched={touched.phoneNum}
              label={translations.chooseUpgradablePackageCardMobileNumLabel}
              placeholder={
                translations.chooseUpgradablePackageCardMobileNumPlaceholder
              }
              name="mobileNumber"
              inline={false}
            />

            <Button
              bsStyle="warning"
              fill
              wd
              type="submit"
              style={{ width: '100%' }}
              disabled={loading || !isValid}
            >
              {translations.buttonLookOption}
            </Button>
          </Form>
        )}
      />
    </div>
  );
};

export default CheckClientForm;
