import React from 'react';
import Swal from 'sweetalert2';

import Card from 'vendors/components/Card/Card.jsx';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { warehouseStore } from 'pages/Warehouse/WarehousePageStore';

interface CheckCardValidationProps {
  translations: any;
}

const CheckCardValidation = ({ translations }: CheckCardValidationProps) => {
  const checkCard = () => {
    Swal.fire({
      title: translations.warehouseCheckCardDialogTitle,
      input: 'text',
      showCancelButton: false,
      confirmButtonText: translations.warehouseCheckCardDialogConfirmButton,
      showLoaderOnConfirm: true,
      preConfirm: (serialNum: any) => {
        warehouseStore.checkCardStatus(serialNum);
      }
    });
  };

  return (
    <>
      <Card
        title={translations.cardCheckCardTitle}
        boldTitle
        minHeight="160px"
        content={<p>{translations.cardCheckCardText}</p>}
      />
      <Button bsStyle="info" fill block onClick={checkCard}>
        {translations.cardCheckCardButtonText}
      </Button>
    </>
  );
};

export default CheckCardValidation;
