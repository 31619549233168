import accidentsRepo from 'data/repositories/AccidentsRepo';
import { action, observable, runInAction } from 'mobx';
import moment from 'moment';
import { Query } from 'lib/queryBuilder';
import omit from 'lodash/omit';
import { DATETIME_FORMAT } from 'lib/constants';
import to from 'lib/awaitTo';
import getValue, { getValueOrNull } from 'lib/getValue';
import { dataTableStore } from 'data/stores/DataTableStore';
import TableStore from 'data/stores/TableStore';

class AccidentsListStore extends TableStore<any> {
  @observable exportResults = [];

  @observable activeTab = '';

  tabs = {
    isClub: 'isClub',
    vwAudiSeat: 'vwAudiSeat',
    mobilityFleet: 'mobilityFleet',
    additionalCompanyServices: 'additionalCompanyServices'
  };

  includes = [
    'clientB2b',
    'companyVehicle.company',
    'driver.user',
    'driver.company',
    'accidentType',
    'bLocation',
    'accidentOffices',
    'country',
    'destinationRepairer',
    'accidentInfo'
  ];

  @action
  changeTab = (param: string) => {
    this.activeTab = this.tabs[param];

    this.fetch();
  };

  fetchByClub = async (companyName?: string) => {
    const [response, error] = await to(
      accidentsRepo.fetchByClub({
        params: {
          ...this.queryParams,
          typeListing: 'paginate',
          limit:
            dataTableStore.query && dataTableStore.query.limit
              ? dataTableStore.query.limit
              : 15,
          companyName
        }
      })
    );
    if (response && response.data) {
      this.onFetch(response.data, response.meta);
    }
    if (error) {
      this.onError();
    }
  };

  fetchByCompany = async (companyName?: string) => {
    const [response, error] = await to(
      accidentsRepo.fetchByCompany({
        params: {
          ...this.queryParams,
          typeListing: 'paginate',
          limit:
            dataTableStore.query && dataTableStore.query.limit
              ? dataTableStore.query.limit
              : 15,
          companyName
        }
      })
    );
    if (response && response.data) {
      this.onFetch(response.data, response.meta);
    }
    if (error) {
      this.onError();
    }
  };

  fetchAdditional = async () => {
    const [response, error] = await to(
      accidentsRepo.fetchAdditionalCompanyRepairers({
        params: {
          ...this.queryParams,
          typeListing: 'paginate',
          limit:
            dataTableStore.query && dataTableStore.query.limit
              ? dataTableStore.query.limit
              : 15
        }
      })
    );
    if (response && response.data) {
      this.onFetch(response.data, response.meta);
    }
    if (error) {
      this.onError();

      runInAction(() => {
        this.data = [];
      });
    }
  };

  @action
  fetch = async (params?: Query) => {
    const { activeTab } = this;
    this.loading = true;
    this.error = false;

    this.queryParams = {
      ...params,
      include: this.includes
    };

    switch (activeTab) {
      case 'isClub':
        await this.fetchByClub();
        break;

      case 'vwAudiSeat':
        await this.fetchByCompany('audi');
        break;

      case 'mobilityFleet':
        await this.fetchByCompany('fleet');
        break;

      case 'additionalCompanyServices':
        await this.fetchAdditional();
        break;

      default:
    }
  };

  @action
  exportCsv = async () => {
    const params = omit(this.queryParams, ['page', 'orderBy', 'sortedBy']);

    const [response] = await to(
      accidentsRepo.fetchByClub({ params: { ...params, limit: 0 } })
    );

    if (response && response.data) {
      const exportResults = response.data.map((item: any) => {
        return {
          acccidentId: getValue(item, 'realId'),
          date: moment(item.timeAccidentCreatedAt).format(DATETIME_FORMAT),
          firstName: getValue(item, 'clientB2b.firstName'),
          lastName: getValue(item, 'clientB2b.lastName'),
          countryOfAccident: getValue(item, 'country.shortname'),
          accidentType: getValue(item, 'accidentType.name'),
          status: getValue(item, 'status'),
          features: item.accidentOffices.length > 1 ? '*' : '',
          billUploaded: getValueOrNull(item, 'accidentInfo.billUploaded')
            ? '*'
            : '',
          carBrand: getValue(item, 'companyVehicle.carBrand'),
          carModel: getValue(item, 'companyVehicle.carModel'),
          vehiclePlates: getValue(item, 'companyVehicle.registrationPlate'),
          vin: getValue(item, 'companyVehicle.vinNumber'),
          distanceBToC: getValueOrNull(item, 'distanceBToC')
        };
      });
      this.setExportResults(exportResults);
    }
  };

  @action
  setExportResults = (data: any) => {
    this.exportResults = data;
  };

  @action
  clearExportData = () => {
    this.exportResults = [];
  };
}

export const accidentsListStore = new AccidentsListStore(accidentsRepo);
export default AccidentsListStore;
