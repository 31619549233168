import axios, { AxiosRequestConfig } from 'axios';

import { LoginParams, RefreshTokenParams } from 'data/types';

export const login = (data: LoginParams) => {
  return axios.post('clients/admin/login', data);
};

export const logout = () => {};

export const refreshToken = (data: RefreshTokenParams) => {
  return axios.post('clients/admin/refresh', data);
};

export const getAuthUser = (config: AxiosRequestConfig) => {
  return axios.get('authenticated-user', config);
};
