import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from 'components/Card';
import CheckPhoneForm from 'components/CheckPhoneForm';

import { vulcoCampaignStore } from 'data/stores/VulcoCampaignStore';

interface CheckClientProps {
  translations: any;
  history: any;
}

const CheckClient = ({ translations, history }: CheckClientProps) => {
  const onSubmit = (formData: any) => {
    vulcoCampaignStore.fetchClientInfo(formData.userMobileNumber, history);
  };

  return (
    <Row className="m-t-24">
      <Col md={5}>
        <Card>
          <h3>{translations.chooseUpgradablePackageCardTitle}</h3>

          <CheckPhoneForm
            onSubmit={onSubmit}
            label="Ukucajte broj telefona radi provere informacija"
            buttonText="Proveri"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default CheckClient;
