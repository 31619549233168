import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { translationStore } from 'data/stores/TranslationStore';
import { upgradeClientBundleStore } from 'data/stores/UpgradeClientBundleStore';
import { navigate } from 'lib/routerHelper';

import Loader from 'components/Loader';
import ActionCard from 'components/ActionCard';

@observer
class UpgradablePackagesPage extends Component<RouterProps> {
  componentDidMount() {
    const { upgradableBundles } = upgradeClientBundleStore;
    const { match }: any = this.props;
    const { params } = match;

    if (upgradableBundles.length === 0) {
      upgradeClientBundleStore.getCurrentAndUpgradableBundles({
        userMobileNumber: params.mobileNum
      });
    }

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  renderCurrentAndPreviousBundleTitle = (
    currentBundle: any,
    activeAdditionalBundles: any
  ) => {
    let bundleName = currentBundle.name;

    if (activeAdditionalBundles.length > 0 && currentBundle) {
      bundleName = '';

      activeAdditionalBundles.forEach((previousBundle: any) => {
        bundleName += `${previousBundle.name} + `;
      });

      bundleName += `${currentBundle.name}`;
    }

    return bundleName;
  };

  render() {
    const { history, match }: any = this.props;
    const { params } = match;
    const { translations } = translationStore;

    const {
      user,
      upgradableInoBundles,
      upgradableDomesticBundles,
      upgradableBundles,
      currentBundle,
      activeAdditionalBundles
    } = upgradeClientBundleStore;

    if (!translations || upgradableBundles.length === 0) {
      return <Loader />;
    }

    if (!user || !currentBundle) {
      return <h3>{translations.somethingWentWrong}</h3>;
    }

    return (
      <>
        <div>
          <h2>
            {translations.upgradablePackagesTitlePartOne}{' '}
            <b>{user.mobileNumber}</b>{' '}
            {translations.upgradablePackagesTitlePartTwo}{' '}
            <b>
              {this.renderCurrentAndPreviousBundleTitle(
                currentBundle,
                activeAdditionalBundles
              )}
            </b>{' '}
            {translations.upgradablePackagesTitlePartThree}
          </h2>

          <h3 className="m-t-24">{translations.upgradablePackagesOptions}</h3>

          {upgradableDomesticBundles.length > 0 ? (
            <h4 className="m-t-24">{translations.bundleDomestic}</h4>
          ) : null}
          <Row>
            {upgradableDomesticBundles.map((bundle: any) => {
              return (
                <Col md={4} key={bundle.id}>
                  <ActionCard
                    color="orange"
                    title={bundle.name}
                    description={`${bundle.price} ${bundle.currency.displayName}`}
                    buttonText={translations.choosePackageCardButton}
                    onClick={() =>
                      navigate(
                        'upgradePackagePage',
                        { history },
                        { bundleId: bundle.id, mobileNum: params.mobileNum }
                      )
                    }
                  />
                </Col>
              );
            })}
          </Row>

          {upgradableInoBundles.length > 0 ? (
            <h4 className="m-t-24">{translations.bundleIno}</h4>
          ) : null}
          <Row>
            {upgradableInoBundles.map((bundle: any) => {
              return (
                <Col md={4} key={bundle.id}>
                  <ActionCard
                    color="blue"
                    title={bundle.name}
                    description={`${bundle.price} ${bundle.currency.displayName}`}
                    buttonText={translations.choosePackageCardButton}
                    onClick={() =>
                      navigate(
                        'upgradePackagePage',
                        { history },
                        { bundleId: bundle.id, mobileNum: params.mobileNum }
                      )
                    }
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  }
}

export default UpgradablePackagesPage;
