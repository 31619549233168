/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';

import { Route } from 'routes';

import { navigate } from 'lib/routerHelper';
import { authStore } from 'data/stores/AuthStore';
import { translationStore } from 'data/stores/TranslationStore';
import { notificationStore } from 'data/stores/NotificationStore';

import Sidebar from 'components/Sidebar';
import RenderIf from 'components/RenderIf';
import CountryGuard from 'components/CountryGuard';
import AdminNavbar from 'components/AdminNavbar/AdminNavbar';
import NavBarRight from 'components/NavBarRight';
import RouteWithSubRoutes from 'components/RoutesWithSubRoutes';
import Loader from 'components/Loader';

import styles from './DashboardLayout.module.scss';

interface DashboardLayoutProps extends RouteComponentProps {
  children: React.ReactChildren;
  routes: Route[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedRoutes = (routes: Route[]): any => {
  return routes.map(route => {
    if (route.placeholder && route.routes) {
      return getNestedRoutes(route.routes);
    }
    return <RouteWithSubRoutes {...route} />;
  });
};
const DashboardLayout = (props: DashboardLayoutProps) => {
  const { mapObject, country } = authStore;

  useEffect(() => {
    authStore.getAuthUser();

    return () => {
      notificationStore.clearState();
    };
  }, []);

  useEffect(() => {
    if (mapObject && mapObject.id && country === 'rs') {
      notificationStore.fetchInvoicesCount();
      notificationStore.fetchSpecificationsCount();
      notificationStore.fetchPaidInvoicesSpecificationsCount();
    }
  }, [mapObject, country]);

  const navigateToInvoicePage = () => {
    const { history } = props;
    navigate('invoices', { history }, { tab: ':tab' });
  };

  const { loggedIn, isBlocked, isVulco, isPremio } = authStore;
  const { routes, location } = props;
  const { translations } = translationStore;
  const { showInvoiceNotification } = notificationStore;

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  if (authStore.loading || !authStore.authUserData || !translations) {
    return <Loader />;
  }

  return (
    <div className="wrapper">
      <Sidebar location={location} />
      <div className="main-panel">
        <div className="content">
          {translations && (
            <div className="navbar">
              <AdminNavbar />
              <NavBarRight />
            </div>
          )}
          <CountryGuard visible={['rs']}>
            <RenderIf
              condition={showInvoiceNotification && !isVulco && !isPremio}
            >
              <div
                className={styles.invoiceNotification}
                onClick={navigateToInvoicePage}
              >
                <p>{translations.invoiceNotificationText}</p>
              </div>
            </RenderIf>
          </CountryGuard>
          <CountryGuard visible={['rs']}>
            <RenderIf condition={isBlocked}>
              <div className={styles.blocked}>
                <p>
                  Vaše prodajno mesto je blokirano zbog neplaćenih uplata.{' '}
                  <a href="/dashboard/payslip-stats">
                    <b>Ovde</b>
                  </a>{' '}
                  pogledajte sve uplate.
                </p>
              </div>
            </RenderIf>
          </CountryGuard>

          <div className="container-fluid">
            <div className="main-content">{getNestedRoutes(routes)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DashboardLayout);
