import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { FaCheckCircle } from 'react-icons/fa';
import { AiOutlineStop } from 'react-icons/ai';
import { observer } from 'mobx-react';
import { Col, Row, Table } from 'react-bootstrap';
import Card from 'vendors/components/Card/Card.jsx';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import getValue, { getDateTime } from 'lib/getValue';
import { Balance } from 'data/types';
import { bundlesStore } from 'data/stores/BundlesStore';
import { translationStore } from 'data/stores/TranslationStore';
import { technicalInspectionStore } from 'data/stores/TechnicalInspectionStore';

type TParams = {
  mobileNum: string;
};

@observer
class TechnicalInspectionReport extends Component<
  RouteComponentProps<TParams>
> {
  componentDidMount() {
    const { balances } = technicalInspectionStore;
    const { translations } = translationStore;
    const { match } = this.props;
    const { params } = match;

    if (!translations) {
      translationStore.checkForTranslations();
    }

    if (balances.length === 0) {
      technicalInspectionStore.getBundleInfo({
        userMobileNumber: params.mobileNum
      });
    }
  }

  onSubmit = (balance?: Balance) => {
    const { history } = this.props;

    if (balance) {
      technicalInspectionStore.updateBalanceStatus(
        balance.id,
        balance,
        history
      );
    }

    return null;
  };

  notUsedFeature = (translations: any) => (
    <>
      <span>{translations.notUsedFeature}</span>
      <FaCheckCircle className="text-success m-l-12" />
    </>
  );

  usedFeature = (translations: any) => (
    <>
      <span>{translations.usedFeature}</span>
      <AiOutlineStop className="text-danger m-l-12" />
    </>
  );

  render() {
    const {
      isTechnicalInspectionFeatureValid,
      technicalInspectionBalance,
      currentClientBundle
    } = technicalInspectionStore;
    const { clientBundleStatuses } = bundlesStore;
    const { translations } = translationStore;

    return (
      <>
        <Row>
          <Col md={8}>
            <h3 className="m-b-24 m-t-24">
              {translations.insuranceReportTitle}
            </h3>

            <Card
              title={translations.package}
              content={
                <>
                  <Table responsive hover striped>
                    <tbody>
                      <tr>
                        <th>{translations.package}</th>
                        <td>
                          {getValue(currentClientBundle, 'currentBundle.name')}
                        </td>
                      </tr>
                      <tr>
                        <th>{translations.status}</th>
                        <td>
                          {
                            clientBundleStatuses[
                              getValue(currentClientBundle, 'status')
                            ]
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>{translations.expirationDate}</th>
                        <td>
                          {getDateTime(
                            getValue(currentClientBundle, 'expiredAt.date')
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>{translations.technicalInspectionFeature}</th>
                        <td>
                          {isTechnicalInspectionFeatureValid
                            ? this.notUsedFeature(translations)
                            : this.usedFeature(translations)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              }
            />
          </Col>
        </Row>
        {isTechnicalInspectionFeatureValid && (
          <Row>
            <Col md={8}>
              <Card
                title={translations.technicalInspectionReportDescription}
                content={
                  <>
                    <Row>
                      <Col md={6}>
                        <p>{translations.technicalInspectionActivationText}</p>
                        <Button
                          bsStyle="info"
                          fill
                          block
                          onClick={() =>
                            this.onSubmit(technicalInspectionBalance)
                          }
                        >
                          {translations.technicalInspectionButtonText}
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default TechnicalInspectionReport;
