import React, { Component } from 'react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { bundlesStore } from 'data/stores/BundlesStore';
import { authStore } from 'data/stores/AuthStore';
import { navigate } from 'lib/routerHelper';

import Loader from 'components/Loader';
import ActionCard from 'components/ActionCard';
import { observer } from 'mobx-react';
import { translationStore } from 'data/stores/TranslationStore';
import { Bundle } from 'data/types';
import { buyPackageStore } from 'data/stores/BuyPackageStore';

@observer
class ChoosePackagesPage extends Component<RouterProps> {
  componentDidMount() {
    const { mapObject } = authStore;

    if (bundlesStore.bundles.length === 0) {
      bundlesStore.getBundles(mapObject.id).then(() => {});
    }

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  render() {
    const { history } = this.props;
    const { domesticBundles, inoBundles, bundles } = bundlesStore;
    const { translations } = translationStore;
    const { company, isVulco } = authStore;
    const sindikatMup = company.name === 'Nezavisni Sindikat MUP';
    const sindikatGvozdeniPuk = company.name === 'Sindikat Gvozdeni Puk';

    if (bundles.length === 0 || !translations) {
      return <Loader />;
    }

    return (
      <>
        <h2>{translations.choosePackage}</h2>
        {domesticBundles.length > 0 ? (
          <h3 className="m-t-24">{translations.bundleDomestic}</h3>
        ) : null}
        <Row>
          {bundles.map((bundle: Bundle) => {
            if (bundle.bundleType === 'domestic') {
              return (
                <Col md={4} key={bundle.id}>
                  <ActionCard
                    color="orange"
                    title={bundle.name}
                    description={`${
                      sindikatMup || sindikatGvozdeniPuk
                        ? (bundle.price * 0.8).toFixed(2)
                        : bundle.price
                    } ${bundle.currency.displayName}`}
                    buttonText={translations.choosePackageCardButton}
                    onClick={() => {
                      buyPackageStore.setSelectedBundleIndex(bundle);
                      navigate(
                        'buyPackagePage',
                        { history },
                        { bundleId: bundle.id }
                      );
                    }}
                  />
                </Col>
              );
            }
            return null;
          })}
        </Row>

        {inoBundles.length > 0 ? (
          <h3 className="m-t-24">{translations.bundleIno}</h3>
        ) : null}
        <Row>
          {bundles.map((bundle: Bundle) => {
            if (bundle.bundleType === 'ino') {
              return (
                <Col md={4} key={bundle.id}>
                  <ActionCard
                    color="blue"
                    title={bundle.name}
                    description={`${
                      // eslint-disable-next-line no-nested-ternary
                      sindikatMup || sindikatGvozdeniPuk
                        ? (bundle.price * 0.8).toFixed(2)
                        : isVulco
                        ? 0
                        : bundle.price
                    } ${bundle.currency.displayName}`}
                    buttonText={translations.choosePackageCardButton}
                    onClick={() => {
                      buyPackageStore.setSelectedBundleIndex(bundle);
                      navigate(
                        'buyPackagePage',
                        { history },
                        { bundleId: bundle.id }
                      );
                    }}
                  />
                </Col>
              );
            }
            return null;
          })}
        </Row>
      </>
    );
  }
}

export default ChoosePackagesPage;
