import React, { useEffect, useState } from 'react';

import { authStore } from 'data/stores/AuthStore';
import { observer } from 'mobx-react';

interface CountryGuard {
  hidden?: string[];
  visible?: string[];
  children: React.ReactNode | React.ReactNode[];
}

const CountryGuard = ({ hidden, visible, children }: any) => {
  const [show, setShow] = useState(false);
  const { country } = authStore;

  useEffect(() => {
    if (hidden) {
      hidden.forEach((shortName: string) => {
        if (shortName === country) {
          setShow(false);
        }
      });
    } else if (visible) {
      visible.forEach((shortName: string) => {
        if (shortName === country) {
          setShow(true);
        }
      });
    }
  }, [country, hidden, visible]);

  if (show) {
    return <>{children}</>;
  }
  return null;
};

export default observer(CountryGuard);
