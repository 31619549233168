import React from 'react';
import { uniqueId } from 'lodash';
import { Field, Formik } from 'formik';
import { Form } from 'react-bootstrap/lib/Navbar';
import Card from 'vendors/components/Card/Card.jsx';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { Comments } from 'data/types';
import CommentBox from 'components/CommentBox/CommentBox';
import Accordion from 'components/Accordion';
import { WarningDialog } from 'lib/dialogs';
import { accidentDetailsStore } from 'pages/AccidentDetails/AccidentDetailsStore';

import styles from '../../AccidentsDetails.module.scss';

const CommentsCard = ({ accident, match }: any) => {
  const onDeleteComment = (comment: Comments) => {
    const { params } = match;

    WarningDialog.fire({
      title: 'Delete Confirmation Required!',
      text: `Are you sure you want to delete comment?`,
      confirmButtonText: 'Yes, delete!',
      preConfirm: () => accidentDetailsStore.deleteComment(comment, params.id)
    });
  };

  const handleCommentSubmit = (data: any) => {
    const { params } = match;
    accidentDetailsStore.addComment(data, params.id);
  };

  return (
    <Card
      content={
        <>
          {accident.comments
            ? accident.comments.map((comment: Comments) => (
                <CommentBox
                  key={uniqueId()}
                  comment={comment}
                  onDelete={() => onDeleteComment(comment)}
                />
              ))
            : null}
          <Accordion title="Add comment">
            <div style={{ paddingBottom: '40px' }}>
              <Formik
                initialValues={{ text: '' }}
                onSubmit={(data, formikProps) => {
                  handleCommentSubmit(data);
                  formikProps.resetForm({ text: '' });
                }}
                enableReinitialize
                render={({ values, errors, touched }) => (
                  <Form>
                    <Field
                      className={styles.commentField}
                      name="text"
                      label="Comment"
                      value={values.text}
                      placeholder="Enter comment..."
                      errors={errors.text}
                      touched={touched.text}
                      component="textarea"
                    />
                    <Button type="submit" bsStyle="info" fill pullRight>
                      Submit comment
                    </Button>
                  </Form>
                )}
              />
            </div>
          </Accordion>
        </>
      }
    />
  );
};

export default CommentsCard;
