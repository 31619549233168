import { action } from 'mobx';

import to from 'lib/awaitTo';

import clientBundlesRepo from 'data/repositories/ClientBundlesRepo';
import TableStore from 'data/stores/TableStore';
import { authStore } from 'data/stores/AuthStore';

class PosReferrersStore extends TableStore<any> {
  @action
  fetchStats = async (params?: any) => {
    if (params) {
      this.queryParams = {
        ...params,
        include: ['currentBundle', 'client.user']
      };
    }

    this.getData();
  };

  getData = async () => {
    const { company } = authStore;

    const [response] = await to(
      clientBundlesRepo.getCompanyReferreredClientBundlesByCompanyId(
        company.id,
        {
          params: this.queryParams
        }
      )
    );

    if (response) {
      this.onFetch(response.data, response.meta);
    }
  };
}

export const posReferrersStore = new PosReferrersStore(clientBundlesRepo);
export default PosReferrersStore;
