import { action, observable, runInAction } from 'mobx';

import to from 'lib/awaitTo';
import invoicesRepo from 'data/repositories/InvoicesRepo';
import specificationsRepo from 'data/repositories/SpecificationsRepo';

import { authStore } from './AuthStore';

class NotificationStore {
  @observable invoicesCount = 0;

  @observable specificationsCount = 0;

  @observable showInvoiceNotification = false;

  @observable paidInvoicesSpecificationCount = 0;

  FROM_DATE = '2022-11-01';

  fetchInvoicesCount = async () => {
    const { mapObject } = authStore;
    const [res] = await to(
      invoicesRepo.listAllInvoicesByMapObject(mapObject.id, {
        params: { from: this.FROM_DATE }
      })
    );

    if (res && res.meta) {
      runInAction(() => {
        this.invoicesCount = res.meta.pagination.total;
      });
      this.setNotificationInLocalStorage(this.invoicesCount, 'invoiceCount');
    }
  };

  fetchSpecificationsCount = async () => {
    const { mapObject } = authStore;
    const [res] = await to(
      specificationsRepo.listAllSpecificationsByMapObject(mapObject.id, {
        params: { from: this.FROM_DATE }
      })
    );

    if (res && res.meta) {
      runInAction(() => {
        this.specificationsCount = res.meta.pagination.total;
      });
      this.setNotificationInLocalStorage(
        this.specificationsCount,
        'specificationCount'
      );
    }
  };

  fetchPaidInvoicesSpecificationsCount = async () => {
    const { mapObject } = authStore;
    const [res] = await to(
      specificationsRepo.listAllSpecificationsByMapObject(mapObject.id, {
        params: { from: this.FROM_DATE, isInvoicePaid: 1 }
      })
    );

    if (res && res.meta) {
      runInAction(() => {
        this.paidInvoicesSpecificationCount = res.meta.pagination.total;
      });
      this.setNotificationInLocalStorage(
        this.paidInvoicesSpecificationCount,
        'paidInvoices'
      );
    }
  };

  @action
  setNotificationInLocalStorage = (
    count: number,
    type: 'invoiceCount' | 'specificationCount' | 'paidInvoices'
  ) => {
    const item = localStorage.getItem(type)
      ? parseInt(localStorage.getItem(type)!, 10)
      : null;

    if (!item && item !== 0) {
      localStorage.setItem(type, JSON.stringify(count));
    } else if (item !== count && count !== 0) {
      this.showInvoiceNotification = true;
    }
  };

  @action
  updateLocalStorage = () => {
    this.showInvoiceNotification = false;

    localStorage.setItem('invoiceCount', JSON.stringify(this.invoicesCount));
    localStorage.setItem(
      'specificationCount',
      JSON.stringify(this.specificationsCount)
    );
    localStorage.setItem(
      'paidInvoices',
      JSON.stringify(this.paidInvoicesSpecificationCount)
    );
  };

  @action
  clearState = () => {
    this.invoicesCount = 0;
    this.specificationsCount = 0;
  };
}

export const notificationStore = new NotificationStore();
export default NotificationStore;
