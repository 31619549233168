import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { translationStore } from 'data/stores/TranslationStore';
import { authStore } from 'data/stores/AuthStore';

import DataTable from 'components/DataTable';
import DateCell from 'components/TableCells/DateCell';
import Loader from 'components/Loader';
import CalculateOurProfitCell from 'components/TableCells/CalculateOurProfitCell';

import { posReferrersStore } from './PosReferrersStore';

@observer
class PosReferrersStatsPage extends Component {
  componentDidMount() {
    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  renderColumns = () => {
    const { translations } = translationStore;
    const { fullDigital } = authStore;

    const columns = [
      {
        Header: translations.statisticsTableBundleName,
        id: 'currentBundle.name',
        accessor: (clientBundle: any) => {
          if (clientBundle.currentBundle) {
            return clientBundle.currentBundle.name;
          }

          return clientBundle.bundleName;
        },
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableDate,
        accessor: 'purchasedAt',
        Cell: DateCell(),
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableMobileNumber,
        accessor: 'client.user.mobileNumber',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTablePrice,
        accessor: 'price',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableProfit,
        accessor: '',
        sortable: false,
        filterable: false,
        Cell: CalculateOurProfitCell,
        show: !fullDigital
      }
    ];

    return columns;
  };

  render() {
    const { translations } = translationStore;

    if (!translations) {
      return <Loader />;
    }

    return (
      <div>
        <h3 style={{ marginTop: 0 }}>{translations.statisticsTableTitle}</h3>
        <DataTable
          dateRangeFilter
          className="withoutActionsCell"
          columns={this.renderColumns()}
          fetchData={posReferrersStore.fetchStats}
          data={posReferrersStore.data}
          meta={posReferrersStore.meta}
          previousText={translations.statsTablePrevious}
          nextText={translations.statsTableNext}
          pageText={translations.statsTablePage}
          ofText={translations.statsTableOfText}
          rowsText={translations.statsTableRowsText}
          loading={posReferrersStore.loading}
        />
      </div>
    );
  }
}

export default PosReferrersStatsPage;
