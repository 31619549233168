import Swal from 'sweetalert2';

export const WarningDialog = Swal.mixin({
  type: 'warning',
  showCancelButton: true,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-danger m-r-8',
    cancelButton: 'btn btn-default'
  }
});

export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
});

export const genericError = () => {
  Toast.fire({
    type: 'error',
    title: `Oops! something went wrong.`
  });
};
