import { observable, action } from 'mobx';

import { TRANSLATIONS_SESSIONS_KEY } from 'lib/constants';
import { Toast } from 'lib/dialogs';

import translationsRepo from 'data/repositories/TranslationsRepo';
import { authStore } from 'data/stores/AuthStore';
import { bundlesStore } from 'data/stores/BundlesStore';

class TranslationStore {
  @observable translations = null as any;

  getTranslations = async (lang: string) => {
    const trans = await translationsRepo.getTranslationsByLocale(lang);

    if (trans) {
      this.setTranslations(trans);
    }
  };

  @action
  setTranslations = (trans: any) => {
    this.translations = trans;

    const { sessionStorage } = window;
    sessionStorage.setItem(TRANSLATIONS_SESSIONS_KEY, JSON.stringify(trans));
    bundlesStore.setClientBundleStatuses();
  };

  checkForTranslations = () => {
    const { language } = authStore;
    const { sessionStorage } = window;

    if (!language) {
      authStore.logout();

      Toast.fire({
        type: 'error',
        title: 'User has no language, please check that'
      });
    }

    if (!sessionStorage.getItem(TRANSLATIONS_SESSIONS_KEY)) {
      this.getTranslations(language);
    } else {
      const transJson = sessionStorage.getItem(TRANSLATIONS_SESSIONS_KEY);

      if (transJson) {
        const trans = JSON.parse(transJson);
        this.setTranslations(trans);
      } else {
        this.getTranslations(language);
      }
    }
  };

  @action
  clearTranslations = () => {
    this.translations = null;
  };
}

export const translationStore = new TranslationStore();
export default TranslationStore;
