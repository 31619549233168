import to from 'lib/awaitTo';

import TableStore from 'data/stores/TableStore';
import { Toast } from 'lib/dialogs';
import vulcoCampaignRepo from 'data/repositories/VulcoCampaignRepo';

class StatsVulcoCampaignStore extends TableStore<any> {
  includes = ['media', 'clientBundle.currentBundle', 'clientBundle.mapObject'];

  downloadPDF = (file: any) => {
    // Create an invisible A element
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    // Set the HREF to a Blob representation of the data to be downloaded
    // a.href = window.URL.createObjectURL(blob || new File([data], fileName));
    a.setAttribute('target', '_blank');

    a.href = `${file.filePath}`;

    // Use download attribute to set set desired file name
    a.setAttribute('download', file.fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    // window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  updateBill = async (id: string, status: 'approved' | 'rejected') => {
    const [res] = await to(vulcoCampaignRepo.update(id, { status }));

    if (res) {
      Toast.fire({
        type: status === 'approved' ? 'success' : 'warning',
        title: `You have ${
          status === 'approved' ? 'approved' : 'rejected'
        } successfully bill!`
      });

      this.fetch(this.queryParams);
    }
  };
}

export const statsVulcoCampaignStore = new StatsVulcoCampaignStore(
  vulcoCampaignRepo
);
export default StatsVulcoCampaignStore;
