import { Route, flatRoutes } from 'routes';
import { RouterProps, generatePath } from 'react-router';

import { pageStore } from 'data/stores/PageStore';

export const setPageTitle = (pathname: string) => {
  let title = process.env.REACT_APP_APP_NAME || '';
  const routeParams = flatRoutes.find(route => route.path === pathname);
  if (routeParams) {
    const { name } = routeParams;
    title = `${title}`;
    pageStore.setNavTitle(name);
  }
  window.document.title = title;
};

export const getRouteDefinition = (pathname: string): Route | undefined => {
  return flatRoutes.find(route => route.path === pathname);
};

export type Param = {
  [paramName: string]: string | number | boolean | undefined;
};
interface Navigate {
  (routeKey: string, routeProps: RouterProps, params?: Param): void;
}

export const invalidRouteMessage = () => {
  // eslint-disable-next-line no-console
  console.error(
    'Invalid route key, please use one of these',
    flatRoutes.map(k => k.key)
  );
};

export const navigate: Navigate = (routeKey, routeProps, params) => {
  const route = flatRoutes.find(r => r.key === routeKey);
  if (route) {
    const { history } = routeProps;
    const path = params ? generatePath(route.path, params) : route.path;
    history.push(path);
  } else {
    invalidRouteMessage();
  }
};

/**
 * @param array1 string[]
 * @param array2 string[]
 * @returns boolean
 */
export const haveSameMembers = (array1: string[], array2: string[]) => {
  const duplicateMembersArray = array1.filter(element =>
    array2.includes(element)
  );
  if (duplicateMembersArray.length > 0) return true;

  return false;
};
