import axios, { AxiosRequestConfig } from 'axios';
import { forEach } from 'lodash';
import BaseRepo from './BaseRepo';

const type = 'customer-bills';

const fetchUpgradableBundle = (
  clientBundleId: string,
  config?: AxiosRequestConfig
) => {
  return axios.get(`vulco-upgradeable-bundles/${clientBundleId}`, config);
};

const imgParseRequest = (formData: any) => {
  const request = new FormData();

  request.append('type', 'vulco_bill');

  forEach(formData, (field: any, key: any) => {
    if (key === 'media' && field) {
      const isFile = typeof field.name === 'string';
      if (isFile) {
        request.append('media[]', field);
      }
    } else if (key === 'id' && field) {
      request.append(key, field);
    }
  });

  return request;
};

const uploadMedia = (formData: any) => {
  return axios.post('media', imgParseRequest(formData));
};

const createClientBundle = (formData: any) => {
  return axios.post('digital-affiliate/vulco-client-bundle', formData);
};

const vulcoCampaignRepo = new BaseRepo(type);

export default {
  ...vulcoCampaignRepo,
  fetchUpgradableBundle,
  uploadMedia,
  createClientBundle
};
