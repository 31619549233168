import React from 'react';

import './NotFound.scss';
import notFound from 'assets/img/not-found/image.png';

const NotFound = () => (
  <div className="not-found">
    <img src={notFound} alt="Not Found" />
    <h1>404 not found</h1>
  </div>
);

export default NotFound;
