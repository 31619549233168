import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'react-bootstrap';
import { RouterProps } from 'react-router';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { navigate } from 'lib/routerHelper';
import { translationStore } from 'data/stores/TranslationStore';

import styles from '../Invoices.module.scss';
import { invoicesStore } from '../InvoicesStore';

const InvoicesTabs = (props: RouterProps & { component: string }) => {
  const navigateTo = (tab: string) => {
    const { history } = props;
    navigate(`invoices`, { history }, { tab });
  };

  const { INVOICES_TABS } = invoicesStore;
  const { component } = props;
  const { translations } = translationStore;

  return (
    <>
      <Row className="m-b-16">
        <Col md={3}>
          <Button
            className={
              component === 'invoices'
                ? styles.activeButtonTab
                : styles.buttonTab
            }
            onClick={() => {
              invoicesStore.changeToPage(INVOICES_TABS.invoices);
              navigateTo('invoices');
            }}
          >
            {translations.invoiceTab}
          </Button>
        </Col>
        <Col md={3}>
          <Button
            className={
              component === 'specifications'
                ? styles.activeButtonTab
                : styles.buttonTab
            }
            onClick={() => {
              invoicesStore.changeToPage(INVOICES_TABS.specifications);
              navigateTo('specifications');
            }}
          >
            {translations.specificationTab}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default observer(InvoicesTabs);
