import React from 'react';

interface CardI {
  children: any;
  height?: string;
  customStyle?: any;
  className?: any;
  width?: string;
}

const Card = ({ children, height, width, customStyle, className }: CardI) => {
  return (
    <div
      style={{
        padding: 15,
        backgroundColor: '#FFF',
        border: 'solid 1px #e7e7e7',
        borderRadius: 4,
        marginBottom: 15,
        width: width || '100%',
        flexGrow: 1,
        height,
        ...customStyle
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export default Card;
