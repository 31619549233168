import React from 'react';
import { Formik, Form } from 'formik';
import { Col, Row } from 'react-bootstrap';

import { translationStore } from 'data/stores/TranslationStore';
import { Bundle } from 'data/types';

import Button from 'vendors/components/CustomButton/CustomButton.jsx';
import Card from 'components/Card';
import Checkbox from 'components/Checkbox';
import FormField from 'components/FormField';
import CountryGuard from 'components/CountryGuard';

interface ActivateUpgradePackageProps {
  onSubmit: (b: Bundle, data: any) => void;
  mobileNumber: string;
  isBlocked: boolean;
  selectedBundle: Bundle;
}

const ActivateUpgradePackage = ({
  onSubmit,
  mobileNumber,
  isBlocked,
  selectedBundle
}: ActivateUpgradePackageProps) => {
  const { translations } = translationStore;
  return (
    <Card>
      <Formik
        initialValues={{ isCompanyBuyer: false, buyerId: '' }}
        onSubmit={(data: any) => onSubmit(selectedBundle, data)}
        render={({ values, setFieldValue, errors, touched }) => (
          <Form>
            <h3>{translations.upgradePackagePageCardTitle}</h3>
            <p style={{ fontSize: 12, opacity: 0.7 }}>
              {translations.upgradePackagePageCardLabel}
            </p>
            <p>{mobileNumber}</p>

            <CountryGuard visible={['rs']}>
              <Row>
                <Col md={3}>
                  <Checkbox
                    inline={false}
                    label="Pravno lice"
                    name="isCompanyBuyer"
                    value={values.isCompanyBuyer}
                    onChange={() =>
                      setFieldValue('isCompanyBuyer', !values.isCompanyBuyer)
                    }
                  />
                </Col>
                {values.isCompanyBuyer && (
                  <Col md={9}>
                    <FormField
                      errors={errors.buyerId}
                      touched={touched.buyerId}
                      label="PIB"
                      name="buyerId"
                      inline={false}
                    />
                  </Col>
                )}
              </Row>
            </CountryGuard>

            <Button
              bsStyle="warning"
              fill
              wd
              type="submit"
              style={{ width: '100%' }}
              disabled={isBlocked}
            >
              {translations.upgradePackagePageUpgradeButton}
            </Button>
          </Form>
        )}
      />
    </Card>
  );
};

export default ActivateUpgradePackage;
