/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { translationStore } from 'data/stores/TranslationStore';
import Loader from 'components/Loader';

@observer
class InsuranceInformations extends Component<RouterProps> {
  componentDidMount() {
    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  navigateBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { translations } = translationStore;

    if (!translations) {
      return <Loader />;
    }

    return (
      <Row>
        <Col md={8}>
          <h2 className="m-b-24">{translations.insuranceSuccessTitle}</h2>
          <p>
            {translations.insuranceSuccessText}
            <b> tireinsurance@vozzi.app</b>{' '}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: translations.InsuranceSuccessText2
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: translations.insuranceSuccessText3
            }}
          />
          <p>
            {translations.insuranceSuccessText4}
            <b> tireinsurance@vozzi.app</b>.
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: translations.insuranceSuccessText5
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default InsuranceInformations;
