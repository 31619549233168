import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { translationStore } from 'data/stores/TranslationStore';

import Loader from 'components/Loader';
import Card from 'components/Card';

import { vulcoCampaignStore } from 'data/stores/VulcoCampaignStore';
import BillForm from '../components/BillForm';

type TParams = {
  mobileNum: string;
};

@observer
class VulcoCampaignAmenitiesPage extends Component<
  RouteComponentProps<TParams>
> {
  componentDidMount() {
    const { client } = vulcoCampaignStore;
    const { match } = this.props;
    const { params } = match;

    if (!client) {
      vulcoCampaignStore.fetchClientInfo(params.mobileNum).then(() => {});
    }
  }

  render() {
    const { translations } = translationStore;
    const { history } = this.props;
    const { client } = vulcoCampaignStore;

    if (!translations || !client) {
      return <Loader />;
    }

    return (
      <Row>
        <Col md={8}>
          <div className="m-b-24">
            <h3 className="m-b-24">
              Korisnik ima aktivan VOZZi paket{' '}
              <b>{client ? client.clientBundle.currentBundle.name : null}</b>
            </h3>

            <div style={{ maxWidth: '800px' }}>
              <ol type="1">
                <li>
                  <p>
                    Uz kupljeni set guma <b>(Goodyear/Dunlop 16’’)</b> dobija
                    &nbsp;
                    <b>
                      NIS vaučer za gorivo 2.500RSD na NIS GAZPROM benzinskim
                      stanicama
                    </b>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    Uz kupljeni set guma <b>(Goodyear/Dunlop 17’’)</b> dobija
                    &nbsp;
                    <b>
                      NIS vaučer 3.500RSD na NIS GAZPROM benzinskim stanicama
                    </b>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    Uz kupljeni set guma <b>(Goodyear/Dunlop 18’’+)</b> dobija
                    &nbsp;
                    <b>
                      NIS vaučer 4.500RSD na NIS GAZPROM benzinskim stanicama
                    </b>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    Uz kupljeni set guma <b>Fulda</b> dobija{' '}
                    <b>
                      NIS vaučer 2.000 RSD na NIS GAZPROM benzinskim stanicama
                    </b>
                    .
                  </p>
                </li>
              </ol>
            </div>

            <h6 className="m-t-24">
              <b>
                * Vaučer za gorivo se dobija odmah nakon verifikacije računa.{' '}
                <br />
                Molim vas popunite formu sa desne strane sa traženim podacima.
              </b>
            </h6>
          </div>
        </Col>
        <Col md={4} style={{ position: 'sticky', top: '10vh' }}>
          <Card>
            <h3>Popunite</h3>
            <BillForm history={history} addressForm client={client} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default VulcoCampaignAmenitiesPage;
