/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';

import Loader from 'components/Loader';

import { workOrderViewStore } from './WorkOrderViewStore';
import WorkOrderComponent from './WorkOrderComponent';
import styles from './WorkOrder.module.scss';

type TParams = {
  id: string;
};

const WorkOrderViewPagePublic = (props: RouteComponentProps<TParams>) => {
  useEffect(() => {
    const { match } = props;
    const { params } = match;

    workOrderViewStore.getWorkOrderById(params.id);

    return () => workOrderViewStore.clearState();
  }, [props]);

  const { workOrder, error } = workOrderViewStore;

  if (error) {
    return <h2>Something went wrong, probably wrong link</h2>;
  }

  if (!workOrder || !workOrder.accident) {
    return <Loader />;
  }

  return (
    <div className={styles.mainContainer}>
      <h1 className="align-center">VOZZi Force - Work Order</h1>
      <WorkOrderComponent workOrder={workOrder} accident={workOrder.accident} />
    </div>
  );
};

export default observer(WorkOrderViewPagePublic);
