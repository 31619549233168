/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import Resizer from 'react-image-file-resizer';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { buyPackageStore } from 'data/stores/BuyPackageStore';
import { authStore } from 'data/stores/AuthStore';
import { vulcoCampaignStore } from 'data/stores/VulcoCampaignStore';
import { translationStore } from 'data/stores/TranslationStore';

import FormField from 'components/FormField';
import Loader from 'components/Loader';
import Checkbox from 'components/Checkbox';
import CountryGuard from 'components/CountryGuard';
import RenderIf from 'components/RenderIf';
import Uploader from 'components/Uploader';

interface ActivatePackagePayslipFormI {
  onSubmit: (data: any) => void;
  fullDigital: boolean; // is map object type full digital
  isBlocked: boolean;
  isSubmitting: boolean;
}

const ActivatePackagePayslipForm = ({
  onSubmit,
  fullDigital,
  isBlocked,
  isSubmitting
}: ActivatePackagePayslipFormI) => {
  const { translations } = translationStore;
  const { isVulco, isPremio } = authStore;

  if (!translations) {
    return <Loader />;
  }

  const resizeImg = (img: any) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        img,
        1000,
        1000,
        'JPEG',
        30,
        0,
        uri => {
          resolve(uri);
        },
        'file'
      );
    });

  const setImage = async (
    img: any,
    setFieldValue: (key: string, value: any) => void
  ) => {
    if (img.type === "application/pdf") {
      return setFieldValue('media', img);
    }
    const resizedImg = await resizeImg(img);
    return setFieldValue('media', resizedImg);
  };

  const regVar = translations.countryCallingCodeRegex;

  const regVarPhoneNumberValidation = translations.phoneNumberValidationRegex;

  const minPhoneNum = parseFloat(translations.phoneNumberValidationMin);

  const reg = new RegExp(regVar);

  const regPhoneNumValidation = new RegExp(regVarPhoneNumberValidation);

  const fullDigitalSchema = object().shape({
    phoneNum: string()
      .matches(reg, {
        message: translations.countryCallingCodeMessage
      })
      .matches(regPhoneNumValidation, {
        message: translations.phoneNumberValidationMessage
      })
      .min(minPhoneNum, translations.phoneNumberValidationMinMessage)
      .required(translations.phoneNumberValidationRequiredMessage),
    userFirstName: string().required('Ime korisnika je obavezno polje'),
    userLastName: string().required('Prezime korisnika je obavezno polje'),
    userEmail: string().required('Email korisnika je obavezno polje')
  });

  const withCardSchema = object().shape({
    phoneNum: string()
      .matches(reg, {
        message: translations.countryCallingCodeMessage
      })
      .matches(regPhoneNumValidation, {
        message: translations.phoneNumberValidationMessage
      })
      .min(minPhoneNum, translations.phoneNumberValidationMinMessage)
      .required(translations.phoneNumberValidationRequiredMessage),
    serialNum: string().required(translations.serialNumRequired),
    userFirstName: string().required('Ime korisnika je obavezno polje'),
    userLastName: string().required('Prezime korisnika je obavezno polje'),
    userEmail: string().required('Email korisnika je obavezno polje')
  });

  const vulcoSchema = object().shape({
    phoneNum: string()
      .matches(reg, {
        message: translations.countryCallingCodeMessage
      })
      .matches(regPhoneNumValidation, {
        message: translations.phoneNumberValidationMessage
      })
      .min(minPhoneNum, translations.phoneNumberValidationMinMessage)
      .required(translations.phoneNumberValidationRequiredMessage),
    userFirstName: string().required('Ime korisnika je obavezno polje'),
    userLastName: string().required('Prezime korisnika je obavezno polje'),
    userEmail: string().required('Email korisnika je obavezno polje'),
    billNumber: string().required('Broj računa je obavezno polje'),
    media: string().required('Slika je obavezno polje')
  });

  const getRightSchema = () => {
    if (isVulco || isPremio) {
      return vulcoSchema;
    }
    if (fullDigital) {
      return fullDigitalSchema;
    }
    return withCardSchema;
  };

  const getInitalValues = () => {
    const { getDataFromClient, enteredPhoneNumber } = vulcoCampaignStore;
    if (isVulco || isPremio) {
      return {
        phoneNum:
          getDataFromClient('user.mobileNumber') ||
          enteredPhoneNumber ||
          translations.phoneNumberCodeInitial,
        userFirstName: getDataFromClient('user.firstName'),
        userLastName: getDataFromClient('user.lastName'),
        userEmail: getDataFromClient('user.email'),
        billNumber: '',
        media: undefined
      };
    }
    if (fullDigital) {
      return {
        phoneNum: translations.phoneNumberCodeInitial,
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        serialNum: '',
        isCompanyBuyer: false,
        buyerId: ''
      };
    }

    return {
      phoneNum: translations.phoneNumberCodeInitial,
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      isCompanyBuyer: false,
      buyerId: ''
    };
  };

  return (
    <div>
      <Formik
        validationSchema={getRightSchema()}
        initialValues={getInitalValues()}
        onSubmit={onSubmit}
        render={(formikProps: any) => (
          <Form noValidate>
            <Row>
              <Col md={12}>
                <FormField
                  autoFocus
                  errors={formikProps.errors.phoneNum}
                  touched={formikProps.touched.phoneNum}
                  label={translations.activationCardPhoneNumLabel}
                  name="phoneNum"
                  inline={false}
                  onBlur={() =>
                    buyPackageStore.checkDoesClientExistsByMobileNum(
                      formikProps.values.phoneNum,
                      formikProps
                    )
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormField
                  errors={formikProps.errors.userFirstName}
                  touched={formikProps.touched.userFirstName}
                  label={translations.clientNameLabel}
                  name="userFirstName"
                  disabled={formikProps.isSubmitting}
                  inline={false}
                />
              </Col>
              <Col md={6}>
                <FormField
                  errors={formikProps.errors.userLastName}
                  touched={formikProps.touched.userLastName}
                  disabled={formikProps.isSubmitting}
                  label={translations.clientLastName}
                  name="userLastName"
                  inline={false}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormField
                  errors={formikProps.errors.userEmail}
                  touched={formikProps.touched.userEmail}
                  disabled={formikProps.isSubmitting}
                  label={translations.clientEmailLabel}
                  name="userEmail"
                  inline={false}
                />
              </Col>
            </Row>

            {!fullDigital && (
              <Row>
                <Col md={12}>
                  <FormField
                    errors={formikProps.errors.serialNum}
                    touched={formikProps.touched.serialNum}
                    disabled={formikProps.isSubmitting}
                    label={translations.activationCardSerialNumLabel}
                    name="serialNum"
                    inline={false}
                  />
                </Col>
              </Row>
            )}

            <RenderIf condition={isVulco || isPremio}>
              <>
                <Row>
                  <Col md={12}>
                    <FormField
                      errors={formikProps.errors.billNumber}
                      touched={formikProps.touched.billNumber}
                      label={translations.billNumberLabel}
                      name="billNumber"
                      inline={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Uploader
                      file={formikProps.values.media}
                      name="media"
                      uploaderChoosePhotoLabel={
                        translations.uploaderChoosePhotoLabel
                      }
                      uploaderRemovePhotoLabel={
                        translations.uploaderRemovePhotoLabel
                      }
                      uploaderChoosePhotoWindow={
                        translations.uploaderChoosePhotoWindow
                      }
                      handleUpload={(file: any) =>
                        setImage(file, formikProps.setFieldValue)
                      }
                      handleRemove={() =>
                        formikProps.setFieldValue('media', undefined)
                      }
                    />
                  </Col>
                </Row>
              </>
            </RenderIf>

            <CountryGuard visible={['rs']}>
              <RenderIf condition={!isVulco}>
                <Row>
                  <Col md={3}>
                    <Checkbox
                      inline={false}
                      label="Pravno lice"
                      name="isCompanyBuyer"
                      value={formikProps.values.isCompanyBuyer}
                      onChange={() =>
                        formikProps.setFieldValue(
                          'isCompanyBuyer',
                          !formikProps.values.isCompanyBuyer
                        )
                      }
                    />
                  </Col>
                  {formikProps.values.isCompanyBuyer && (
                    <Col md={9}>
                      <FormField
                        errors={formikProps.errors.buyerId}
                        touched={formikProps.touched.buyerId}
                        label="PIB"
                        name="buyerId"
                        inline={false}
                      />
                    </Col>
                  )}
                </Row>
              </RenderIf>
            </CountryGuard>

            <Button
              bsStyle="warning"
              fill
              wd
              type="submit"
              style={{ width: '100%' }}
              disabled={
                formikProps.loading ||
                !formikProps.isValid ||
                isBlocked ||
                isSubmitting
              }
            >
              {translations.activationCardSubmitButton}
            </Button>
          </Form>
        )}
      />
    </div>
  );
};

export default ActivatePackagePayslipForm;
