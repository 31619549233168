import React, { useEffect, useState } from 'react';

import { authStore } from 'data/stores/AuthStore';
import { observer } from 'mobx-react';
import checkRoles from 'lib/checkRoles';

interface RoleGuard {
  hidden?: string[];
  visible?: string[];
  children: React.ReactNode | React.ReactNode[];
}

const RoleGuard = ({ hidden, visible, children }: any) => {
  const [show, setShow] = useState(false);
  const { roles } = authStore;

  useEffect(() => {
    if (hidden) {
      const userHasRoles = checkRoles(roles, hidden);
      if (userHasRoles) {
        setShow(false);
      }
    } else if (visible) {
      const userHasRoles = checkRoles(roles, visible);
      if (userHasRoles) {
        setShow(true);
      }
    }
  }, [hidden, visible, roles]);

  if (show) {
    return <>{children}</>;
  }
  return null;
};

export default observer(RoleGuard);
