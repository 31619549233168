import axios, { AxiosRequestConfig } from 'axios';
import BaseRepo from './BaseRepo';

const type = 'affiliate-invoices';

const listAllInvoicesByMapObject = (
  mapObjectId: string,
  params?: AxiosRequestConfig
) => {
  return axios.get(`${type}-map-object/${mapObjectId}`, params);
};

const invoicesRepo = new BaseRepo(type);

export default { ...invoicesRepo, listAllInvoicesByMapObject };
