import React from 'react';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import Card from 'vendors/components/Card/Card.jsx';

import { attributesJoin, getValueOrNull } from 'lib/getValue';
import { DATETIME_FORMAT } from 'lib/constants';
import { Accident, B2bMessage } from 'data/types';
import styles from './IncidentReports.module.scss';

interface Props {
  accident: Accident;
}

const IncidentReportsLog = ({ accident }: Props): JSX.Element => {
  const { b2bSentMessages, clientB2b } = accident;

  if (!b2bSentMessages || !b2bSentMessages.length) {
    return (
      <Col md={12}>
        <Card title="Incident Report Log" content={<p>No logs recorded.</p>} />
      </Col>
    );
  }

  const htmlPreview = (htmlString: string) => (
    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
  );

  return (
    <Col md={12}>
      <Card
        title="Incident Report Log"
        content={
          <>
            {b2bSentMessages.map((message: B2bMessage) => {
              const emailMsg = message.type === 'email';
              const viberSmsMsg = message.type === 'sms';

              return (
                <div className={styles.messageWrapper}>
                  <p className={styles.heading}>
                    <b>{emailMsg ? 'Email' : 'Viber/SMS poruka ka klijentu'}</b>
                  </p>
                  <p>
                    Poslao agent:{' '}
                    {attributesJoin(
                      message,
                      'userSender.firstName',
                      'userSender.lastName'
                    )}
                  </p>
                  <p>
                    Datum i vreme:{' '}
                    {moment(message.createdAt.date).format(DATETIME_FORMAT)}
                  </p>
                  <p>
                    Na {emailMsg ? 'email adresu' : 'broj telefona'}:{' '}
                    {viberSmsMsg
                      ? clientB2b && clientB2b.phoneNumber
                      : getValueOrNull(message, 'sentEmail.recipientEmail')}
                  </p>
                  {viberSmsMsg && (
                    <div className={styles.message}>{message.message}</div>
                  )}

                  {getValueOrNull(message, 'sentEmail.content') &&
                    htmlPreview(message.sentEmail.content)}
                </div>
              );
            })}
          </>
        }
      />
    </Col>
  );
};

export default IncidentReportsLog;
