import React from 'react';
import moment from 'moment';

import getValue, { getValueOrEmptyString, getValueOrNull } from 'lib/getValue';
import { DATETIME_FORMAT, DATE_FORMAT } from 'lib/constants';

import logo from 'assets/logo.png';
import styles from './Print.module.scss';

const PrintTemplate = ({ data }: { data: any }): JSX.Element => {
  const findImage = (type: string) => {
    const mediaObj = data.workOrder.media.find(
      (obj: any) => obj.mediaTag === type
    );

    if (mediaObj) {
      return mediaObj.path;
    }

    return '';
  };

  const clientLocation = `${getValueOrEmptyString(
    data,
    'bLocation.address.street'
  )} ${getValueOrEmptyString(
    data,
    'bLocation.address.streetNumber'
  )} ${getValueOrEmptyString(
    data,
    'bLocation.address.city'
  )} ${getValueOrEmptyString(data, 'bLocation.address.country')}`;

  return (
    <div className={styles.onePagePrint}>
      <img src={logo} className={styles.logo} alt="" />

      <div className={styles.wrapper}>
        <p style={{ marginTop: '0px', marginBottom: '10px', fontSize: '13px' }}>
          Work order number {getValue(data, 'workOrder.realId')}, Accident ID{' '}
          {getValue(data, 'realId')}
        </p>

        <div className={styles.flex}>
          <div className={styles.column}>
            <div className={styles.card}>
              <h2>Client info</h2>
              <table>
                <tbody>
                  <tr>
                    <th>First Name:</th>
                    <td>{getValue(data, 'clientB2b.firstName')}</td>
                  </tr>
                  <tr>
                    <th>Last Name:</th>
                    <td>{getValue(data, 'clientB2b.lastName')}</td>
                  </tr>
                  <tr>
                    <th>Phone number:</th>
                    <td>{getValue(data, 'clientB2b.phoneNumber')}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={styles.card}>
              <h2>Intervention info</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Created at:</th>
                    <td>
                      {moment(
                        getValue(data, 'workOrder.createdAt.date')
                      ).format(DATETIME_FORMAT)}
                    </td>
                  </tr>
                  <tr>
                    <th>Type of info:</th>
                    <td>{getValue(data, 'accidentType.name')}</td>
                  </tr>
                  <tr>
                    <th>Client location:</th>
                    <td>{clientLocation}</td>
                  </tr>
                  <tr>
                    <th>Fault code:</th>
                    <td>
                      {getValue(data, 'workOrder.interventionFaultCode.name')}
                    </td>
                  </tr>
                  <tr>
                    <th>Client comment:</th>
                    <td>{getValue(data, 'workOrder.interventionComment')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.card}>
              <h2>Vehicle info</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Brand:</th>
                    <td>
                      {getValue(data, 'workOrder.companyVehicle.carBrand')}
                    </td>
                  </tr>
                  <tr>
                    <th>Type:</th>
                    <td>
                      {getValue(data, 'workOrder.companyVehicle.carModel')}
                    </td>
                  </tr>
                  <tr>
                    <th>Registration number:</th>
                    <td>
                      {getValue(
                        data,
                        'workOrder.companyVehicle.registrationPlate'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>VIN number:</th>
                    <td>
                      {getValue(data, 'workOrder.companyVehicle.vinNumber')}
                    </td>
                  </tr>
                  <tr>
                    <th>Mileage:</th>
                    <td>{getValue(data, 'accidentInfo.mileage')}</td>
                  </tr>
                  <tr>
                    <th>Date of first registration:</th>
                    <td>
                      {getValueOrNull(
                        data,
                        'companyVehicle.firstRegistrationAt.date'
                      )
                        ? moment(
                            getValueOrNull(
                              data,
                              'companyVehicle.firstRegistrationAt.date'
                            )
                          ).format(DATE_FORMAT)
                        : 'Nema podataka'}
                    </td>
                  </tr>
                  <tr>
                    <th>Last service:</th>
                    <td>
                      {getValue(data, 'accidentInfo.lastService.name')}
                      {getValueOrNull(data, 'accidentInfo.lastServiceDate')
                        ? `, ${moment(
                            getValue(data, 'accidentInfo.lastServiceDate.date')
                          ).format(DATE_FORMAT)}`
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <th>Destination repairer:</th>
                    <td>{getValue(data, 'destinationRepairer.name')}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={styles.card}>
              <h2>Damage on vehicle</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Car parts:</th>
                    <td>
                      {getValueOrNull(data, 'workOrder.damagedVehicleParts') &&
                      getValueOrNull(data, 'workOrder.damagedVehicleParts')
                        .length
                        ? getValueOrNull(
                            data,
                            'workOrder.damagedVehicleParts'
                          ).map((part: string, index: number) =>
                            index ? `, ${part}` : part
                          )
                        : 'Nema oštećenja na vozilu'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.column}>
            <div className={styles.card}>
              <h2>Loading</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Comment:</th>
                    <td>{getValue(data, 'workOrder.loadingComment')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.card}>
              <h2>Unloading</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Comment:</th>
                    <td>{getValue(data, 'workOrder.unloadingComment')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className={styles.napomena}>
          <p style={{ marginBottom: '5px' }}>
            Napomena za klijenta i saglasnost za anketiranje
          </p>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              columnGap: '30px'
            }}
          >
            <div>
              <p>
                <b>
                  <u>Napomena:</u>
                </b>
              </p>

              <p>
                Potvrđujem da ću snositi sve troškove usluge asistencije,
                ukoliko servis utvrdi da je otkaz na vozilu posledica
                nepoštovanja upustva za upotrebu, neredovnog održavanja vozila
                ili je prouzrokovan ličnom nepažnjom. Potvrđujem da sam
                upoznat/a sa obavezom potpisivanja radnog naloga servisu.
              </p>
            </div>
            <div>
              <div style={{ display: 'flex' }}>
                <input
                  type="checkbox"
                  checked={getValueOrNull(data, 'workOrder.consent')}
                  style={{
                    alignSelf: 'flex-start',
                    width: '22px',
                    height: '22px',
                    margin: '-3px 10px 0 0'
                  }}
                />
                <div>
                  <p>
                    <b>
                      <u>Saglasnost korisnika za anketiranje:</u>
                    </b>
                  </p>
                  <p>
                    Potvrđujem da me u okviru ispitivanja zadovoljstva korisnika
                    kontaktira ovlašćena agencija u vezi sa pruženim uslugama
                    asistencije.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.signatures}>
          <h2 style={{ margin: '8px 0' }}>Signatures</h2>

          <div>
            <div>
              <p style={{ margin: '0px' }}>Client Signature:</p>
              <div
                style={{
                  width: '100px',
                  height: '115px',
                  marginTop: '-30px'
                }}
              >
                {findImage('signature_client') && (
                  <img
                    src={data.workOrder && findImage('signature_client')}
                    alt=""
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                      objectPosition: 'top left'
                    }}
                  />
                )}
              </div>
            </div>

            <div>
              <p style={{ margin: '0px' }}>Driver Signature:</p>
              <div
                style={{
                  width: '100px',
                  height: '115px',
                  marginTop: '-30px'
                }}
              >
                {findImage('signature_driver') && (
                  <img
                    src={data.workOrder && findImage('signature_driver')}
                    alt=""
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                      objectPosition: 'top left'
                    }}
                  />
                )}
              </div>
            </div>

            <div>
              <p style={{ margin: '0px' }}>Repairer Signature:</p>
              <div
                style={{
                  width: '100px',
                  height: '115px',
                  marginTop: '-30px'
                }}
              >
                {findImage('signature_repairer') && (
                  <img
                    src={data.workOrder && findImage('signature_repairer')}
                    alt=""
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                      objectPosition: 'top left'
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            borderTop: '1px solid black',
            margin: '30px 30px 0 30px'
          }}
        />
        <p
          style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}
        >
          VOZZi Holding B. V.
        </p>
      </div>
    </div>
  );
};

export default PrintTemplate;
