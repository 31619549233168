import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { WarningDialog } from 'lib/dialogs';
import { translationStore } from 'data/stores/TranslationStore';

// import { authStore } from 'data/stores/AuthStore';
// import checkRoles from 'lib/checkRoles';
// import { ROLE_AFFILIATE_MARKETPLACE } from 'lib/constants';

import DataTable from 'components/DataTable';
import Loader from 'components/Loader';
// import Button from 'vendors/components/CustomButton/CustomButton.jsx';
// import FixedTwoDecimalsCell from 'components/TableCells/FixedTwoDecimalsCell';

import PayslipLink from 'components/PayslipLink';
import DigitalAffiliatePayslipStatus from 'components/TableCells/DigitalAffiliatePayslipStatus';
import FixedTwoDecimalsCell from 'components/TableCells/FixedTwoDecimalsCell';
import { payslipStatsStore } from './PayslipStatsStore';

@observer
class PayslipStatsPage extends Component {
  componentDidMount() {
    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  renderColumns = () => {
    const { translations } = translationStore;

    const columns = [
      {
        Header: translations.payslipStatsIdLabel,
        id: 'currentBundle.id',
        accessor: 'digitalAffiliatePayslip.payslipNumber',
        sortable: false,
        filterable: true
      },
      {
        Header: translations.payslipStatsNameLabel,
        accessor: 'client.user.firstName'
      },
      {
        Header: translations.payslipStatsLastNameLabel,
        accessor: 'client.user.lastName'
      },
      {
        Header: translations.statisticsTableMobileNumber,
        accessor: 'userMobileNumber',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableBundleName,
        id: 'currentBundle.name',
        accessor: (clientBundle: any) => {
          if (clientBundle.currentBundle) {
            return clientBundle.currentBundle.name;
          }

          return clientBundle.bundleName;
        },
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableDate,
        accessor: 'purchasedAt',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTablePrice,
        accessor: 'price',
        Cell: FixedTwoDecimalsCell,
        sortable: false,
        filterable: false
      },
      {
        Header: translations.status,
        accessor: 'digitalAffiliatePayslip.status',
        Cell: DigitalAffiliatePayslipStatus,
        sortable: false,
        filterable: false
      },
      {
        Header: translations.dataTableActions,
        accessor: 'actions',
        filterable: false,
        sortable: false
      }
    ];

    return columns;
  };

  deactivatePackage = (bundleData: any) => {
    const { translations } = translationStore;

    WarningDialog.fire({
      title: translations.deactivatePackageDialogTitle,
      text: `${translations.deactivatePackageDialogText} ${bundleData.userMobileNumber}`,
      confirmButtonText: translations.deactivatePackageDialogConfirmButton,
      cancelButtonText: translations.dialogCancelButton,
      customClass: {
        confirmButton: 'btn btn-danger btn-fill m-r-8',
        cancelButton: 'btn btn-default btn-fill'
      },
      preConfirm: () => payslipStatsStore.deactivateClientBundle(bundleData.id)
    });
  };

  itemAction = (bundleData: any) => {
    // const { translations } = translationStore;

    return (
      <PayslipLink
        href={
          bundleData.digitalAffiliatePayslip
            ? bundleData.digitalAffiliatePayslip.filePath
            : ''
        }
        approved={bundleData.digitalAffiliatePayslip.status === 'paid'}
        notPaid={bundleData.digitalAffiliatePayslip.status === 'not_paid'}
      />
    );
  };

  render() {
    const { translations } = translationStore;

    if (!translations) {
      return <Loader />;
    }

    return (
      <div>
        <h3 style={{ marginTop: 0 }}>Pregled i status svih uplata</h3>
        <DataTable
          dateRangeFilter
          columns={this.renderColumns()}
          fetchData={payslipStatsStore.fetchStats}
          data={payslipStatsStore.attachCustomColumn(
            'actions',
            this.itemAction
          )}
          meta={payslipStatsStore.meta}
          loading={payslipStatsStore.loading}
          defaultSort={[{ id: 'createdAt', desc: true }]}
        />
      </div>
    );
  }
}

export default PayslipStatsPage;
