import React from 'react';
import moment from 'moment';
import { CellInfo } from 'react-table';

import { DATETIME_FORMAT } from 'lib/constants';

const DateCell = (format = DATETIME_FORMAT) => ({ value }: CellInfo) => {
  if (!value) {
    return null;
  }

  let date = value;
  if (typeof value !== 'string' && value.date) {
    date = value.date;
  }

  return <div>{moment(date).format(format)}</div>;
};

export default DateCell;
