import React from 'react';
import { Field } from 'formik';

import FormFieldContainer from './FormFieldContainer';

type Component = 'input' | 'textarea';
interface FormFieldProps {
  errors: string | undefined;
  touched: boolean | undefined;
  type: string;
  name: string;
  placeholder?: string;
  label: string;
  autoFocus?: boolean;
  inline?: boolean;
  component: Component;
  disabled?: boolean;
  requiredClass?: boolean;
  onBlur?: (e?: any) => void;
}

const FormField = ({
  errors,
  touched,
  type,
  name,
  placeholder,
  label,
  autoFocus,
  inline,
  component,
  disabled,
  requiredClass,
  onBlur
}: FormFieldProps) => (
  <FormFieldContainer
    errors={errors}
    touched={touched}
    label={label}
    inline={inline}
    requiredClass={requiredClass}
  >
    <Field
      className="form-control"
      placeholder={placeholder}
      type={type}
      name={name}
      autoFocus={autoFocus}
      component={component}
      rows={component === 'textarea' ? '5' : null}
      disabled={disabled}
      onBlur={onBlur}
    />
  </FormFieldContainer>
);

FormField.defaultProps = {
  type: 'text',
  inline: true,
  component: 'input'
};

export default FormField;
