import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import { BiCalendarWeek, BiWrench } from 'react-icons/bi';
import { RouterProps } from 'react-router';

import { ReactComponent as UpgradeIcon } from 'assets/upgrade-icon.svg';

import ActionCard from 'components/ActionCard';
// import Loader from 'components/Loader';
import RenderIf from 'components/RenderIf';

import { navigate } from 'lib/routerHelper';

import { bundlesStore } from 'data/stores/BundlesStore';
import { authStore } from 'data/stores/AuthStore';
import { translationStore } from 'data/stores/TranslationStore';
import { buyPackageStore } from 'data/stores/BuyPackageStore';

@observer
class HomePage extends Component<RouterProps> {
  componentDidMount() {
    const { mapObject } = authStore;

    bundlesStore.getBundles(mapObject.id);

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  navigateToPage = (key: string) => {
    const { history } = this.props;
    navigate(key, { history });
  };

  render() {
    const { history } = this.props;
    const { translations } = translationStore;
    const { promoBundles } = bundlesStore;
    const {
      isTypeAffiliate,
      isTypeInsurance,
      isTypePayslipPayment,
      isVulco,
      country
    } = authStore;

    const isTechnicalInspection = authStore.mapObjectTypes.some(
      (key: string) => key === 'technical_inspection'
    );

    // if (
    //   !bundlesStore.bundles ||
    //   !translations ||
    //   bundlesStore.bundlesWithoutPromo.length === 0
    // ) {
    //   return <Loader />;
    // }

    return (
      <>
        <Row>
          <Col md={4}>
            <RenderIf condition={isVulco}>
              <ActionCard
                color="orange"
                description={translations.cardBuyPackageDescription}
                buttonText={translations.cardBuyPackageButton}
                onClick={() => this.navigateToPage('vulcoCampaign')}
              />
            </RenderIf>
            <RenderIf condition={!isVulco}>
              <ActionCard
                color="orange"
                description={translations.cardBuyPackageDescription}
                buttonText={translations.cardBuyPackageButton}
                onClick={() => this.navigateToPage('choosePackages')}
              />
            </RenderIf>
            <RenderIf condition={!!isTypeInsurance}>
              <ActionCard
                color="green"
                description={translations.cardDamageReportDescription}
                buttonText={translations.cardDamageReportButton}
                onClick={() => this.navigateToPage('insurance')}
              />
            </RenderIf>
            <RenderIf condition={!!isTechnicalInspection}>
              <ActionCard
                color="ligthBlue"
                description={translations.technicalInspectionDescription}
                buttonText="Pokreni prijavu"
                onClick={() => this.navigateToPage('technicalInspection')}
                icon={<BiWrench />}
              />
            </RenderIf>
          </Col>

          <RenderIf
            condition={!!isTypeAffiliate && !(country === 'hr' && isVulco)}
          >
            <Col md={4}>
              <ActionCard
                color="green"
                description={translations.cardUpgradePackageDescription}
                buttonText={translations.buttonLookOption}
                onClick={() => this.navigateToPage('chooseUpgradePackages')}
                icon={<UpgradeIcon />}
              />
            </Col>
          </RenderIf>
        </Row>

        <Row>
          {promoBundles.map((bundle, index) => {
            return (
              <Col md={4} key={bundle.id}>
                <ActionCard
                  color="blue"
                  description={`${translations.assign} ${bundle.name} (${bundle.price} ${bundle.currency.displayName}) ${translations.bundle}`}
                  onClick={() => {
                    buyPackageStore.setSelectedBundleIndex(bundle, true);
                    navigate(
                      'buyPackagePage',
                      { history },
                      { bundleId: bundle!.id }
                    );
                  }}
                  buttonText={translations.cardPromoBundleButton}
                />
              </Col>
            );
          })}
          <RenderIf condition={!!isTypePayslipPayment}>
            <Col md={4}>
              <ActionCard
                color="yellow"
                description={translations.cardPayslipStatsDescription}
                onClick={() => this.navigateToPage('payslipStats')}
                buttonText={translations.cardPayslipStatsButton}
                icon={<BiCalendarWeek />}
              />
            </Col>
          </RenderIf>
        </Row>
      </>
    );
  }
}

export default HomePage;
