import { observable, action, runInAction } from 'mobx';
import { sortBy } from 'lodash';

import { trimWhitespaces } from 'lib/formHelpers';
import { navigate } from 'lib/routerHelper';
import { Toast, WarningDialog } from 'lib/dialogs';
import to from 'lib/awaitTo';
import {
  Bundle,
  ClientBundle,
  CompanyType,
  GetCurrentAndUpgradableBundlesFromData
} from "data/types";

import upgradeClientBundleRepo from 'data/repositories/UpgradeClientBundleRepo';

import { translationStore } from './TranslationStore';
import { authStore } from './AuthStore';
import TableStore from './TableStore';

class UpgradeClientBundleStore extends TableStore<any> {
  @observable currentClientBundle: any = {};

  @observable currentBundle: any = {};

  @observable activeAdditionalBundles = [] as any;

  @observable upgradableBundles: any = [];

  @observable upgradableDomesticBundles: any = [];

  @observable upgradableInoBundles: any = [];

  @observable user: any = {};

  @observable formData = {} as any;

  @observable clientMobileNum = '';

  @observable payslipFile = {} as any;

  @observable upgradedClientBundle?: ClientBundle;

  @action
  getCurrentAndUpgradableBundles = async (
    formData: GetCurrentAndUpgradableBundlesFromData,
    history?: any
  ) => {
    const { translations } = translationStore;

    const noWhiteSpacesFormData = {
      ...formData,
      userMobileNumber: trimWhitespaces(formData.userMobileNumber)
    };

    const [response] = await to(
      upgradeClientBundleRepo.getCurrentClientBundleAndUpgradableBundles({
        params: noWhiteSpacesFormData
      })
    );

    if (response) {
      runInAction(() => {
        this.user = response.client.user;
        this.currentBundle = response.currentBundle;
        this.currentClientBundle = response;
        this.activeAdditionalBundles = sortBy(
          response.activeAdditionalBundles,
          'createdAt.date'
        );
      });

      if (response.currentBundle.status === 4) {
        // @ts-ignore
        WarningDialog.fire({
          type: 'info',
          title: 'Clients active bundle is partially used.',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-fill btn-info'
          }
        });
      }

      if (response.additionalBundles) {
        this.setUpgradableBundles(response.additionalBundles);

        if (history) {
          navigate(
            'upgradablePackagesPage',
            { history },
            { mobileNum: formData.userMobileNumber }
          );
        }
      } else {
        // @ts-ignore
        WarningDialog.fire({
          type: 'info',
          title: translations.dialogNoOptionsForUpgradeTitle,
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-fill btn-info'
          }
        });
      }
    }
  };

  @action
  setUpgradableBundles = (bundles: any[]) => {
    const { mapObject } = authStore;

    this.upgradableBundles = bundles.filter(
      (bundle: any) => bundle.type === 'upgrade'
    );

    const isTechnical = mapObject
      .mapObjectTypes
      .filter((type: CompanyType) => type.keyName === 'technical_inspection')
      .length > 0;

    if (!isTechnical) {
      this.upgradableBundles = this.upgradableBundles
        .filter((bundle: Bundle) => bundle
          .keyName
          .toLowerCase()
          .indexOf("maximum") === -1
        )
    }

    this.setUpgradableBundlesByBundleType(this.upgradableBundles);
  };

  @action
  setUpgradableBundlesByBundleType = (bundles: any[]) => {
    this.upgradableInoBundles = bundles.filter(
      (bundle: any) => bundle.bundleType === 'ino'
    );
    this.upgradableDomesticBundles = bundles.filter(
      (bundle: any) => bundle.bundleType === 'domestic'
    );
  };

  @action
  upgradeClientBundle = async (data: any, history: any, mobileNum: string) => {
    const { translations } = translationStore;
    const { company, isTypePayslipPayment } = authStore;
    const { bundleId } = data;

    this.formData = {
      ...data,
      smsMessage: translations.smsMessageUpgrade,
      isPayslipPayment: isTypePayslipPayment
    };

    this.clientMobileNum = mobileNum; // in case of multiple companies

    const [response] = await to(
      upgradeClientBundleRepo.upgradeClientBundle({
        ...this.formData,
        companyId: company.id
      })
    );

    if (response) {
      Toast.fire({
        type: 'success',
        title: translations.dialogUpgradeSuccessfulTitle
      });

      runInAction(() => {
        this.upgradedClientBundle = response;
      });

      // For New Payslip Payment
      if (response.digitalAffiliatePayslip) {
        runInAction(() => {
          this.payslipFile = {
            fileName: response.digitalAffiliatePayslip.fileName,
            filePath: response.digitalAffiliatePayslip.filePath
          };
        });
      }

      navigate(
        'boughtUpgradePackagePage',
        { history },
        { bundleId, mobileNum }
      );
    }
  };

  @action
  clearStore = () => {
    this.currentBundle = null;
    this.user = null;
    this.upgradableBundles = [];
    this.activeAdditionalBundles = [];
    this.upgradableDomesticBundles = [];
    this.upgradableInoBundles = [];
    this.upgradedClientBundle = undefined;
  };
}

export const upgradeClientBundleStore = new UpgradeClientBundleStore(
  upgradeClientBundleRepo
);
export default UpgradeClientBundleStore;
