import { action } from 'mobx';

import to from 'lib/awaitTo';

import clientBundlesRepo from 'data/repositories/ClientBundlesRepo';
import TableStore from 'data/stores/TableStore';
import { authStore } from 'data/stores/AuthStore';
import { Toast } from 'lib/dialogs';
import { ROLE_AFFILIATE_COMPANY } from 'lib/constants';
import { translationStore } from 'data/stores/TranslationStore';

class ReferrerStatsStore extends TableStore<any> {
  @action
  fetchStats = async (params?: any) => {
    if (params) {
      this.queryParams = {
        ...params,
        include: ['currentBundle', 'client.user']
      };
    }

    this.getData();
  };

  getData = async () => {
    const { roles, company } = authStore;

    if (roles) {
      if (roles.find((role: any) => role.name === ROLE_AFFILIATE_COMPANY)) {
        const [response] = await to(
          clientBundlesRepo.getReferreredClientBundlesByCompanyId(company.id, {
            params: this.queryParams
          })
        );

        if (response) {
          this.onFetch(response.data, response.meta);
        }
      } else {
        const [response] = await to(
          clientBundlesRepo.getClientBundlesByMapObjectId(
            authStore.authUserData.mapObject.id,
            this.queryParams
          )
        );
        if (response) {
          this.onFetch(response.data, response.meta);
        }
      }
    }
  };

  @action
  deactivateClientBundle = async (bundleId: string) => {
    const { translations } = translationStore;
    const [response] = await to(
      clientBundlesRepo.deactivateClientBundle(bundleId)
    );

    if (response) {
      this.fetchStats(this.queryParams);

      Toast.fire({
        type: 'success',
        title: translations.successDeactivationPackage
      });
    }
  };
}

export const referrerStatsStore = new ReferrerStatsStore(clientBundlesRepo);
export default ReferrerStatsStore;
