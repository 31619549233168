import axios, { AxiosRequestConfig } from 'axios';

interface OrderMoreCardsData {
  mapObjectId: string;
  numberOfCards: number;
}

export const fetchWarehouseData = (mapObjectId: string) => {
  return axios.get(`promotion-codes/map-objects/${mapObjectId}`);
};

export const orderMoreCards = (data: OrderMoreCardsData) => {
  return axios.post('digital-affiliates/order-codes-cards', data);
};

export const getPromoCardBySerialNum = (
  serialNum: string,
  config: AxiosRequestConfig
) => {
  return axios.get(`promotion-code-by-serial-num/${serialNum}`, config);
};
