/* eslint-disable import/prefer-default-export */
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import pick from 'lodash/pick';
import FormField from 'components/FormField';

type ValidationRule = (data: object) => object | null;

export const validate = (data: object, validationRules: ValidationRule[]) => {
  let errors = {};

  validationRules.forEach((rule: ValidationRule) => {
    const error = rule(data);
    errors = { ...errors, ...(error || {}) };
  });

  return !isEmpty(errors) && errors;
};

export const getDirtyFields = (initialValues: object, formValue: object) => {
  const diff = reduce(
    initialValues,
    (result: string[], value, key: string) =>
      isEqual(value, formValue[key]) ? result : result.concat(key),
    []
  );

  const newValues = difference(
    Object.keys(formValue),
    Object.keys(initialValues)
  );

  return pick(formValue, [...diff, ...newValues]);
};

export const trimWhitespaces = (str: string) => {
  return str.split(' ').join('');
};

export interface Field {
  key: string;
  component: React.ComponentType<any>;
  size: number;
  props: object;
}

export interface GeneratorProps {
  key: string;
  label: string;
  errors: string;
  touched: boolean;
  size?: number;
  type?: string;
  autoFocus?: boolean;
  requiredClass?: boolean;
}

export const generateField = ({
  key,
  label,
  errors,
  touched,
  autoFocus,
  size = 6,
  type = 'text',
  requiredClass
}: GeneratorProps): Field => ({
  key,
  component: FormField,
  props: {
    label,
    name: key,
    errors: errors[key],
    touched: touched[key],
    inline: false,
    type,
    autoFocus,
    requiredClass
  },
  size
});
