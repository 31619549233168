import React from 'react';

import bgImage from 'assets/img/road.jpg';
import logo from 'assets/logo-v2-0.svg';
import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
  children: React.ReactChild;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => (
  <div>
    <div className="wrapper wrapper-full-page">
      <div
        className="full-page login-page"
        data-color="blue"
        data-image={bgImage}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: '150px',
            height: 'auto',
            position: 'fixed',
            top: '30px',
            left: '30px',
            zIndex: 10
          }}
        />
        <div className="content">{children}</div>
        <div
          // className="full-page-background"
          className={styles.backgroundImageGradient}
          style={{ backgroundImage: `url(${bgImage})` }}
        />
      </div>
    </div>
  </div>
);

export default AuthLayout;
