import React, { Component } from 'react';

class Log extends Component {
  render() {
    // console.log('REACT_APP_API_URL: ', process.env.REACT_APP_API_URL);
    return <div id="log-element-holder" />;
  }
}

export default Log;
