import React, { PureComponent } from 'react';
import ReactTable, { TableProps, Filter, SortingRule } from 'react-table';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import debounce from 'lodash/debounce';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Card from 'vendors/components/Card/Card.jsx';
import 'react-table-hoc-fixed-columns/lib/styles.css';

import queryBuilder, { Query } from 'lib/queryBuilder';
import { DEBOUNCE_TIME, REQUEST_DATE_FORMAT } from 'lib/constants';
import { Meta } from 'data/types';
import { dataTableStore } from 'data/stores/DataTableStore';
import { translationStore } from 'data/stores/TranslationStore';

import DateTimeField from 'components/DateTimeField';

interface DataTableProps extends Partial<TableProps> {
  fetchData: (query?: Query) => void;
  meta?: Meta;
  enableCaching: boolean;
  defaultSort?: SortingRule[];
  dateRangeFilter?: boolean;
  dateRangeFilterPlaceholder?: string;
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class DataTable extends PureComponent<DataTableProps> {
  static defaultProps = {
    showPageJump: true,
    manual: true,
    filterable: true,
    className: '-striped -highlight',
    enableCaching: true
  };

  debounceFilter = debounce((filtered: Filter[]) => {
    dataTableStore.setFilters(filtered);
    const defaultPage = 1;
    this.onFetchData(defaultPage);
  }, DEBOUNCE_TIME);

  componentDidMount() {
    const { enableCaching, defaultSort } = this.props;
    dataTableStore.initTableStore(enableCaching, { sorted: defaultSort });
    this.onFetchData();
  }

  onFetchData = async (pageDefault?: number) => {
    const { fetchData, defaultFiltered } = this.props;
    const {
      page,
      limit = 15,
      filtered,
      sorted,
      from,
      to
    } = dataTableStore.query;

    const query = queryBuilder({
      sorted,
      filtered: [...(filtered || []), ...(defaultFiltered || [])],
      from: from ? moment(from).format(REQUEST_DATE_FORMAT) : '',
      to: to ? moment(to).format(REQUEST_DATE_FORMAT) : '',
      page: pageDefault || page,
      limit
    });
    fetchData(query);
  };

  onSortChange = (sorted: SortingRule[]) => {
    dataTableStore.setSorting(sorted);
    this.onFetchData();
  };

  extractPaginationData = () => {
    const { meta } = this.props;

    if (meta && meta.pagination) {
      const { pagination } = meta;
      return {
        page: pagination.currentPage,
        pages: pagination.totalPages
      };
    }

    return { page: 0, pages: 0 };
  };

  onPageChange = (page: number) => {
    dataTableStore.setPage(page + 1);
    this.onFetchData();
  };

  onPageSizeChange = (limit: number) => {
    dataTableStore.setLimit(limit);
    this.onFetchData();
  };

  setDate = (dateType: string, date: any) => {
    if (dateType === 'from') {
      dataTableStore.setFrom(date);
    }

    if (dateType === 'to') {
      dataTableStore.setTo(date);
    }

    this.onFetchData();
  };

  render() {
    const { page, pages } = this.extractPaginationData();
    const { translations } = translationStore;
    const {
      className,
      meta,
      dateRangeFilter,
      dateRangeFilterPlaceholder
    } = this.props;

    if (!dataTableStore.query) return null;

    const { limit = 15, filtered, sorted, from, to } = dataTableStore.query;

    return (
      <>
        <Card
          content={
            <>
              {dateRangeFilter && (
                <Row>
                  <Col sm={3}>
                    <DateTimeField
                      value={from}
                      onChange={(val: any) => this.setDate('from', val)}
                      isValidDate={currentDate => currentDate.isBefore(to)}
                      inline={false}
                      inputProps={{
                        placeholder: `${
                          translations.dateFromPlaceholder
                        } ${dateRangeFilterPlaceholder || ''}`
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <DateTimeField
                      value={to}
                      onChange={(val: any) => this.setDate('to', val)}
                      isValidDate={currentDate => currentDate.isAfter(from)}
                      inline={false}
                      inputProps={{
                        placeholder: `${
                          translations.dateToPlaceholder
                        } ${dateRangeFilterPlaceholder || ''}`
                      }}
                    />
                  </Col>
                </Row>
              )}

              <ReactTableFixedColumns
                pageSizeOptions={[5, 10, 15, 30, 50]}
                defaultPageSize={limit}
                defaultFiltered={filtered}
                defaultSorted={sorted}
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                pages={pages}
                page={page - 1}
                {...this.props}
                onFilteredChange={this.debounceFilter}
                onSortedChange={this.onSortChange}
                className={`react-data-table -striped -highlight ${className ||
                  ''}`}
              />
            </>
          }
        />
        {meta && meta.pagination ? (
          <p>
            {translations.dataTableTotalNumber} <b>{meta.pagination.total}</b>
          </p>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default DataTable;
