import React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import Card from 'vendors/components/Card/Card.jsx';
import uniqueId from 'lodash/uniqueId';

import { DATETIME_FORMAT, DATE_FORMAT } from 'lib/constants';
import getValue, { getValueOrNull } from 'lib/getValue';
import { Accident, Files, VehiclePart, WorkOrder } from 'data/types';
import styles from './WorkOrder.module.scss';

interface WorkOrderComponentProps {
  accident: Accident;
  workOrder: WorkOrder;
}

const WorkOrderComponent = ({
  workOrder,
  accident
}: WorkOrderComponentProps) => {
  const findImage = (type: string) => {
    if (workOrder && workOrder.media) {
      const mediaObj = workOrder.media.find(
        (obj: Files) => obj.mediaTag === type
      );

      if (mediaObj) {
        return mediaObj.path;
      }
    }

    return '';
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <Card
            tableFullWidth
            title="Client Details"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>First name:</td>
                      <td>{getValue(accident, 'clientB2b.firstName')}</td>
                    </tr>
                    <tr>
                      <td>Last name:</td>
                      <td>{getValue(accident, 'clientB2b.lastName')}</td>
                    </tr>
                    <tr>
                      <td>Phone number:</td>
                      <td>{getValue(accident, 'clientB2b.phoneNumber')}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />
          <Card
            tableFullWidth
            title="Intervention information"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Created at:</td>
                      <td>
                        {moment(getValue(workOrder, 'createdAt.date')).format(
                          DATETIME_FORMAT
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Type of information:</td>
                      <td>{getValue(accident, 'accidentType.name')}</td>
                    </tr>
                    <tr>
                      <td>Client location:</td>
                      <td>
                        {getValueOrNull(accident, 'bLocation.address.street') &&
                        getValueOrNull(
                          accident,
                          'bLocation.address.streetNumber'
                        ) &&
                        getValueOrNull(accident, 'bLocation.address.city') &&
                        getValueOrNull(accident, 'bLocation.address.country')
                          ? `${getValueOrNull(
                              accident,
                              'bLocation.address.street'
                            )} ${getValueOrNull(
                              accident,
                              'bLocation.address.streetNumber'
                            )}, ${getValueOrNull(
                              accident,
                              'bLocation.address.city'
                            )}, ${getValueOrNull(
                              accident,
                              'bLocation.address.country'
                            )}`
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td>Fault code:</td>
                      <td>
                        {getValue(workOrder, 'interventionFaultCode.name')}
                      </td>
                    </tr>
                    <tr>
                      <td>Client comment:</td>
                      <td>{getValue(workOrder, 'interventionComment')}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />
        </Col>
        <Col md={6}>
          <Card
            tableFullWidth
            title="Vehicle information"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Brand:</td>
                      <td>{getValue(workOrder, 'companyVehicle.carBrand')}</td>
                    </tr>
                    <tr>
                      <td>Type:</td>
                      <td>{getValue(workOrder, 'companyVehicle.carModel')}</td>
                    </tr>
                    <tr>
                      <td>Registration number:</td>
                      <td>
                        {getValue(
                          workOrder,
                          'companyVehicle.registrationPlate'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>VIN number:</td>
                      <td>{getValue(workOrder, 'companyVehicle.vinNumber')}</td>
                    </tr>
                    <tr>
                      <td>Mileage:</td>
                      <td>{getValue(accident, 'accidentInfo.mileage')}</td>
                    </tr>
                    <tr>
                      <td>Date of first registration:</td>
                      <td>
                        {getValueOrNull(
                          workOrder,
                          'companyVehicle.firstRegistrationAt.date'
                        )
                          ? moment(
                              getValueOrNull(
                                workOrder,
                                'companyVehicle.firstRegistrationAt.date'
                              )
                            ).format(DATE_FORMAT)
                          : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>Last service:</td>
                      <td>
                        {getValue(accident, 'accidentInfo.lastService.name')}
                        {', '}
                        {moment(
                          getValue(
                            accident,
                            'accidentInfo.lastServiceDate.date'
                          )
                        ).format(DATE_FORMAT)}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Service Mileage</td>
                      <td>
                        {getValue(accident, 'accidentInfo.lastServiceMileage')}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />
          <Card
            tableFullWidth
            title="Damage on vehicle"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Car parts:</td>
                      <td>
                        {workOrder && workOrder.damagedVehicleParts
                          ? workOrder.damagedVehicleParts.map(
                              (damagedPart: VehiclePart) => (
                                <div key={uniqueId()}>
                                  {damagedPart}
                                  <br />
                                </div>
                              )
                            )
                          : null}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card
            tableFullWidth
            title="Loading"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Comment:</td>
                      <td>{getValue(workOrder, 'loadingComment')}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />
        </Col>
        <Col md={6}>
          <Card
            tableFullWidth
            title="Unloading"
            content={
              <>
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td>Comment:</td>
                      <td>{getValue(workOrder, 'unloadingComment')}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            }
          />
        </Col>
      </Row>
      <div className={styles.consentCard}>
        <p style={{ marginBottom: '10px' }}>
          Napomena za klijenta i saglasnost za anketiranje
        </p>

        <div className={styles.columns}>
          <div>
            <p>
              <b>
                <u>Napomena:</u>
              </b>
            </p>

            <p>
              Potvrđujem da ću snositi sve troškove usluge asistencije, ukoliko
              servis utvrdi da je otkaz na vozilu posledica nepoštovanja upustva
              za upotrebu, neredovnog održavanja vozila ili je prouzrokovan
              ličnom nepažnjom. Potvrđujem da sam upoznat/a sa obavezom
              potpisivanja radnog naloga servisu.
            </p>
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <input checked={workOrder.consent} disabled type="checkbox" />
              <div>
                <p>
                  <b>
                    <u>Saglasnost korisnika za anketiranje:</u>
                  </b>
                </p>
                <p>
                  Potvrđujem da me u okviru ispitivanja zadovoljstva korisnika
                  kontaktira ovlašćena agencija u vezi sa pruženim uslugama
                  asistencije.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <Card
            tableFullWidth
            title="Signatures"
            content={
              <Row style={{ padding: '15px' }}>
                <Col md={4}>
                  <p>Client Signature:</p>
                  <img
                    src={workOrder ? findImage('signature_client') : ''}
                    alt=""
                    width="250px"
                  />
                </Col>
                <Col md={4}>
                  <p>Driver Signature:</p>
                  <img
                    src={workOrder ? findImage('signature_driver') : ''}
                    alt=""
                    width="250px"
                  />
                </Col>
                <Col md={4}>
                  <p>Repairer Signature:</p>
                  <img
                    src={workOrder ? findImage('signature_repairer') : ''}
                    alt=""
                    width="250px"
                  />
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default observer(WorkOrderComponent);
