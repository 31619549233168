import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { CheckClientForm } from 'data/types';
import { translationStore } from 'data/stores/TranslationStore';
import { upgradeClientBundleStore } from 'data/stores/UpgradeClientBundleStore';

import Loader from 'components/Loader';
import Card from 'components/Card';

import CheckClientFormComponent from './components/CheckClientForm';
import { checkClientStore } from './CheckClientStore';
import ClientInfoModal from './components/ClientInfoModal';

@observer
class CheckClientPage extends Component<RouterProps> {
  componentDidMount() {
    upgradeClientBundleStore.clearStore();
  }

  onSubmit = (formData: CheckClientForm) => {
    checkClientStore.fetchClientInfo(formData.mobileNumber);
  };

  render() {
    const { translations } = translationStore;
    const { showModal, closeModal, client } = checkClientStore;

    if (!translations) {
      return <Loader />;
    }

    return (
      <>
        <div>
          <h2>{translations.checkClientTitle}</h2>
          <Row className="m-t-24">
            <Col md={5}>
              <Card>
                <h3>{translations.chooseUpgradablePackageCardTitle}</h3>

                <CheckClientFormComponent onSubmit={this.onSubmit} />
              </Card>
            </Col>
          </Row>
          <ClientInfoModal
            show={showModal}
            onHide={closeModal}
            translations={translations}
            client={client}
          />
        </div>
      </>
    );
  }
}

export default CheckClientPage;
