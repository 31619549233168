import React from 'react';
import Checkbox from 'vendors/components/CustomCheckbox/CustomCheckbox.jsx';
import { Field } from 'formik';
import FormFieldContainer from '../FormField/FormFieldContainer';

const CheckboxComponent = ({
  name,
  label,
  requiredClass = false,
  onChange,
  checked,
  errors,
  touched,
  inline
}: any) => {
  return (
    <FormFieldContainer
      label={label}
      inline={inline}
      errors={errors}
      touched={touched}
      requiredClass={requiredClass}
    >
      <Field
        component={Checkbox}
        number={name}
        name={name}
        onChange={onChange}
        checked={checked}
      />
    </FormFieldContainer>
  );
};

export default CheckboxComponent;
