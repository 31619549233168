import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { observer } from 'mobx-react';

import { Query } from 'lib/queryBuilder';
import { WarningDialog } from 'lib/dialogs';
import { translationStore } from 'data/stores/TranslationStore';

import DataTable from 'components/DataTable';
import Loader from 'components/Loader';
import VulcoCampaignStatusCell from 'components/TableCells/VulcoCampaignStatusCell';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import ThumbnailCell from 'components/TableCells/ThumbnailCell';
import { RowInfo } from 'react-table';
import { statsVulcoCampaignStore } from './StatsVulcoCampaignStore';

const approve = <Tooltip id="approve">Approve</Tooltip>;
const reject = <Tooltip id="reject">Reject</Tooltip>;

@observer
class StatsVulcoCampaign extends Component {
  renderColumns = () => {
    const { translations } = translationStore;
    const columns = [
      {
        Header: translations.statisticsTableBundleName,
        id: 'currentBundle.name',
        accessor: 'clientBundle.currentBundle.name',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.statisticsTableObject,
        id: 'clientbundle.mapobject.name',
        accessor: 'clientBundle.mapObject.name',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.billNumberLabel,
        accessor: 'billNumber',
        sortable: false,
        filterable: false
      },
      {
        Header: translations.status,
        accessor: 'status',
        Cell: VulcoCampaignStatusCell,
        sortable: false,
        filterable: false
      },
      {
        Header: '',
        accessor: 'media[0].path',
        Cell: ThumbnailCell,
        sortable: false,
        filterable: false
      },
      {
        Header: translations.dataTableActions,
        accessor: 'actions',
        filterable: false,
        sortable: false
      }
    ];

    return columns;
  };

  approveButtonEnabled = (item: any) => {
    if (
      item.clientBundle &&
      (item.clientBundle.currentBundle.keyName.includes('VULCO') ||
        item.clientBundle.currentBundle.keyName.includes('Premio'))
    ) {
      return item.status === 'approved' || item.clientBundle.status !== 2;
    }
    if (item.status === 'waiting') {
      return false;
    }

    return true;
  };

  itemAction = (item: any) => {
    const { translations } = translationStore;
    return (
      <div
        onClick={e => e.stopPropagation()}
        role="presentation"
        className="td-actions text-right"
      >
        <OverlayTrigger placement="top" overlay={approve}>
          <Button
            simple
            bsStyle="success"
            bsSize="lg"
            disabled={this.approveButtonEnabled(item)}
            onClick={() =>
              WarningDialog.fire({
                title: translations.dialogConfirmation,
                text: translations.dialogBillConfirmationTitle,
                confirmButtonText: translations.dialogConfirmButtonText,
                cancelButtonText: translations.dialogCancelButtonText,
                customClass: {
                  confirmButton: 'btn btn-success btn-fill m-r-8',
                  cancelButton: 'btn btn-default btn-fill'
                },
                preConfirm: () =>
                  statsVulcoCampaignStore.updateBill(item.id, 'approved')
              })
            }
          >
            <i className="fa fa-check-circle" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={reject}>
          <Button
            simple
            bsStyle="danger"
            bsSize="lg"
            disabled={this.approveButtonEnabled(item)}
            onClick={() =>
              WarningDialog.fire({
                title: translations.dialogConfirmation,
                text: translations.dialogBillCancelationTitle,
                confirmButtonText: translations.dialogConfirmButtonText,
                cancelButtonText: translations.dialogCancelButtonText,
                customClass: {
                  confirmButton: 'btn btn-danger btn-fill m-r-8',
                  cancelButton: 'btn btn-default btn-fill'
                },
                preConfirm: () =>
                  statsVulcoCampaignStore.updateBill(item.id, 'rejected')
              })
            }
          >
            <i className="fa fa-times-circle" />
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: {
        cursor: 'pointer'
      },
      onClick: () => {
        if (row && row.original) {
          statsVulcoCampaignStore.downloadPDF({
            filePath: row.original.media[0].path,
            fileName: row.original.media[0].filename
          });
        }
      }
    };
  };

  addFilterToDoneStatus = (state: any, rowInfo: any) => {
    if (
      rowInfo &&
      rowInfo.original &&
      rowInfo.original.clientBundle &&
      this.approveButtonEnabled(rowInfo.original)
      // rowInfo.original.clientBundle.status === 3
    ) {
      return {
        style: {
          filter: 'grayscale(1) opacity(0.5)'
        }
      };
    }

    return {};
  };

  render() {
    const { translations } = translationStore;

    if (!translations) {
      return <Loader />;
    }

    return (
      <div>
        <DataTable
          columns={this.renderColumns()}
          fetchData={(params?: Query) =>
            statsVulcoCampaignStore.fetch({ ...params, from: '2022-10-15' })
          }
          data={statsVulcoCampaignStore.attachCustomColumn(
            'actions',
            this.itemAction
          )}
          meta={statsVulcoCampaignStore.meta}
          loading={statsVulcoCampaignStore.loading}
          getTdProps={this.getTdProps}
          getTrProps={this.addFilterToDoneStatus}
          defaultSort={[{ id: 'createdAt', desc: true }]}
        />
      </div>
    );
  }
}

export default StatsVulcoCampaign;
