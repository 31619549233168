import React from 'react';

import { translationStore } from 'data/stores/TranslationStore';
import { authStore } from 'data/stores/AuthStore';
import styles from './BundleWithFeaturesInfo.module.scss';

interface BundleWithFeaturesInfoProps {
  selectedBundle: any;
  categoryTitles: any;
  upgrade?: boolean;
}

const BundleWithFeaturesInfo = ({
  selectedBundle,
  categoryTitles,
  upgrade
}: BundleWithFeaturesInfoProps) => {
  const { translations } = translationStore;
  const { company, isVulco } = authStore;
  const sindikatMup = company.name === 'Nezavisni Sindikat MUP';
  const sindikatGvozdeniPuk = company.name === 'Sindikat Gvozdeni Puk';

  return (
    <>
      <h2>
        <b>{selectedBundle.name}</b> -{' '}
        {/* eslint-disable-next-line no-nested-ternary */}
        {sindikatMup || sindikatGvozdeniPuk
          ? (selectedBundle.price * 0.8).toFixed(2)
          : isVulco
          ? 0
          : selectedBundle.price}{' '}
        {selectedBundle.currency.displayName}
      </h2>

      {!!selectedBundle.backgroundThumbnail && (
        <img
          src={selectedBundle.backgroundThumbnail.path}
          alt="backgroundThumbnail"
          width="500px"
          style={{ margin: '0px 0px 10px 0px' }}
        />
      )}

      {!upgrade && (
        <p>
          {translations.bundleDuration}{' '}
          <b>
            {selectedBundle.duration} {translations.bundleDurationMonth}
          </b>
        </p>
      )}

      {categoryTitles.map((title: string) => (
        <div key={`div-key-${title}`}>
          <h4 className={styles.categoryTitle}>{title}</h4>
          {selectedBundle.features.map((feature: any) => {
            if (title === feature.meta.category_title) {
              return (
                <div key={feature.id} style={{ marginBottom: 30 }}>
                  <p style={{ marginBottom: 5 }}>
                    <b>
                      {feature.noFeatures}x {feature.title}{' '}
                      {/* {feature.meta.additional_title ? (
                        <span>{feature.meta.additional_title}</span>
                      ) : null} */}
                    </b>
                  </p>

                  <p>{feature.description}</p>
                  {feature.thumbnail ? (
                    <img
                      src={feature.thumbnail.path}
                      style={{ margin: '10px 0 10px 0' }}
                      alt="img"
                    />
                  ) : null}
                </div>
              );
            }
            return null;
          })}
        </div>
      ))}
    </>
  );
};

export default BundleWithFeaturesInfo;
