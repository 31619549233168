import React, { useState, ReactNode } from 'react';
import { Collapse } from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';

import styles from './Accordion.module.scss';

interface Accordion {
  title: string;
  children: ReactNode;
  className?: string;
}

function Accordion({ title, children, className }: Accordion) {
  const [isCollapseOpen, toggleCollapse] = useState(false);

  return (
    <div className={`${styles.accordionWrap} ${className}`}>
      <div
        onClick={() => toggleCollapse(!isCollapseOpen)}
        className={styles.accordionHeader}
      >
        <h4>{title}</h4>

        <IoIosArrowDown
          className={styles.icon}
          style={{
            transform: isCollapseOpen ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
        />
      </div>

      <Collapse in={isCollapseOpen}>
        <div>
          <div className={styles.accordionBody}>{children}</div>
        </div>
      </Collapse>
    </div>
  );
}

export default Accordion;
