import React from 'react';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

interface PayslipLinkProps {
  href: string;
  approved?: boolean;
  notPaid?: boolean;
}

const PayslipLink = ({ href, approved }: PayslipLinkProps) => (
  <Button
    fill
    pullRight
    bsStyle={approved ? 'success' : 'warning'}
    disabled={approved}
  >
    {approved ? (
      'Uplaceno'
    ) : (
      <a
        href={`https://${href}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'white' }}
      >
        Uplatnica
      </a>
    )}
  </Button>
);

export default PayslipLink;
