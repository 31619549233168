import React from 'react';

import RenderIf from 'components/RenderIf';

interface ErrorMessageProps {
  show: boolean;
  message?: string;
}

const showError = (touched: boolean, message?: string): boolean => {
  return touched && !!message;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ show, message }) => (
  <RenderIf condition={showError(show, message)}>
    <small className="text-danger">{message}</small>
  </RenderIf>
);

ErrorMessage.defaultProps = {
  show: false,
  message: ''
};

export default ErrorMessage;
