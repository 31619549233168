import React from 'react';
import { CellInfo } from 'react-table';

import styles from './TableCells.module.scss';

const ThumbnailCell = ({ value }: CellInfo) => {
  if (!value) return null;

  return (
    <div className={styles.thumbnailCellWrap}>
      <img src={value} alt="" />
    </div>
  );
};

export default ThumbnailCell;
