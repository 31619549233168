import { translationStore } from 'data/stores/TranslationStore';
import React from 'react';
import { CellInfo } from 'react-table';

const VulcoCampaignStatusCell = ({ value }: CellInfo) => {
  const { translations } = translationStore;

  const statuses = {
    approved: translations.billStatusApproved,
    waiting: translations.billStatusWaiting,
    rejected: translations.billStatusRejected
  };

  const statusesClass = {
    waiting: 'text-default',
    approved: 'text-success',
    rejected: 'text-danger'
  };

  return <div className={`${statusesClass[value]}`}>{statuses[value]}</div>;
};

export default VulcoCampaignStatusCell;
