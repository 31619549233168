import { action, observable, runInAction } from 'mobx';

import { WorkOrder } from 'data/types';
import to from 'lib/awaitTo';
import accidentsRepo from 'data/repositories/AccidentsRepo';

class WorkOrderViewStore {
  includes = [
    'accident.clientB2b',
    'accident.companyVehicle',
    'accident.accidentType',
    'accident.accidentFaultClassification',
    'accident.accidentClub',
    'accident.country',
    'accident.driver.company',
    'companyVehicle.company',
    'damagedVehicleParts',
    'media',
    'interventionFaultCode',
    'accident.accidentInfo.lastService',
    'accident.bLocation',
    'accident.cLocation'
  ];

  @observable workOrder?: WorkOrder;

  @observable error = false;

  getWorkOrderById = async (workOrderId: string) => {
    const [res, err] = await to(
      accidentsRepo.getWorkOrderByIdPublic(workOrderId, {
        params: {
          include: this.includes
        }
      })
    );

    if (res) {
      runInAction(() => {
        this.workOrder = res;
        this.error = false;
      });
    } else if (err) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  @action
  clearState = () => {
    this.workOrder = undefined;
    this.error = true;
  };
}

export const workOrderViewStore = new WorkOrderViewStore();
export default WorkOrderViewStore;
