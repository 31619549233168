import React, { useRef } from 'react';
import { MdPrint } from 'react-icons/md';
import ReactToPrint from 'react-to-print';

import { TableActionBtn } from 'components/TableActions';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';
import PrintTemplate from './PrintTemplate';

const ComponentToPrint = React.forwardRef(({ data }: any, ref: any) => {
  return (
    <div style={{ display: 'none' }}>
      <div ref={ref}>
        <PrintTemplate data={data} />
      </div>
    </div>
  );
});

interface PrintComponentProps {
  workOrderData: any;
  onlyIconWithToolTip?: boolean;
}

const PrintComponent = ({
  workOrderData,
  onlyIconWithToolTip
}: PrintComponentProps) => {
  const componentRef = useRef() as any;

  const triggerComponent = () => {
    if (onlyIconWithToolTip) {
      return workOrderData ? (
        <TableActionBtn
          tooltip="Print"
          onClick={() => console.log('PRINT')}
          reactIcon={<MdPrint />}
          iconColor={
            workOrderData.workOrder.status === 'open' ? '#353535' : '#d0d0d0  '
          }
          id="edit-role"
        />
      ) : (
        <div />
      );
    }

    return workOrderData ? (
      <Button fill>
        <i className="fa fa-print" style={{ marginRight: '10px' }} /> Print
      </Button>
    ) : (
      <div />
    );
  };

  return (
    <div>
      <ReactToPrint
        trigger={() => triggerComponent()}
        content={() => componentRef.current}
      />
      <ComponentToPrint data={workOrderData} ref={componentRef} />
    </div>
  );
};

export default PrintComponent;
