import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { buyPackageStore } from 'data/stores/BuyPackageStore';
import { translationStore } from 'data/stores/TranslationStore';

import FormField from 'components/FormField';

interface SendEmailModalProps {
  showModal: boolean;
  closeModalForm: () => void;
}

@observer
class SendEmailModal extends Component<SendEmailModalProps> {
  onSubmit = (data: any) => {
    buyPackageStore.sendEmailWithLink(data);
  };

  render() {
    const { translations } = translationStore;
    const { showSendEmailModal, closeSendEmailModal } = buyPackageStore;

    return (
      <Modal show={showSendEmailModal} onHide={closeSendEmailModal}>
        <Modal.Header closeButton>
          <h3>{translations.sendEmailModalTitle}</h3>
        </Modal.Header>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={(data: any) => this.onSubmit(data)}
          render={({ errors, touched, isSubmitting }) => (
            <Form>
              <Modal.Body>
                <FormField
                  inline={false}
                  name="email"
                  label={translations.clientEmailLabel}
                  errors={errors.email}
                  touched={touched.email}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  bsStyle="success"
                  fill
                  pullRigh
                  disabled={isSubmitting}
                >
                  {translations.boughtPackageAnonymousSendEmailButton}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default SendEmailModal;
