import { observable, action } from 'mobx';

import to from 'lib/awaitTo';
import { territoryFeatureTitles } from 'config/bundles';

import bundlesRepo from 'data/repositories/BundlesRepo';
import { Bundle } from 'data/types';
import { translationStore } from './TranslationStore';
import { authStore } from './AuthStore';

class BundlesStore {
  // Enter here promo packages for all countries (there will always be 1 for every country)
  promoPackageKeyNames = ['vozzi-40-2022', 'vozzi-40-2023', 'vozzi-80'];

  @observable bundles = [] as Bundle[];

  @observable bundlesWithoutPromo = [] as Bundle[];

  @observable promoBundles = [] as Bundle[];

  @observable categoryTitles: string[] = [];

  @observable territoryTitle = '';

  @observable domesticBundles: Bundle[] = [];

  @observable inoBundles: Bundle[] = [];

  @observable clientBundleStatuses = {} as { [key: number]: string };

  @action
  getBundles = async (mapObjectId: string) => {
    const [response] = await to(bundlesRepo.getBundlesByMapObject(mapObjectId));

    if (response && response.data) {
      this.setBundles(response.data);
    }
  };

  @action
  setBundles = (bundles: any) => {
    this.promoBundles = bundles.filter((item: Bundle) =>
      this.promoPackageKeyNames.includes(item.keyName)
    );
    this.bundlesWithoutPromo = bundles.filter(
      (item: Bundle) => !this.promoPackageKeyNames.includes(item.keyName)
    );

    this.bundles = this.bundlesWithoutPromo;

    this.setDomesticBundles();
    this.setInoBundles();
  };

  @action
  setDomesticBundles = () => {
    this.domesticBundles = this.bundlesWithoutPromo.filter(
      (bundle: any) => bundle.bundleType === 'domestic'
    );
  };

  @action
  setInoBundles = () => {
    this.inoBundles = this.bundlesWithoutPromo.filter(
      (bundle: any) => bundle.bundleType === 'ino'
    );
  };

  @action
  splitFeaturesByCategoryTitle = (features: any) => {
    const { country } = authStore;
    const categoryTitles: string[] = [];

    features.forEach((feature: any) => {
      // We use territory feature as seperate
      if (territoryFeatureTitles[country] !== feature.meta.category_title) {
        categoryTitles.push(feature.meta.category_title);
      } else {
        this.territoryTitle = territoryFeatureTitles[country];
      }
    });

    const singleCategoryTitles = categoryTitles.filter(
      (item: any, index: number) => categoryTitles.indexOf(item) === index
    );

    if (singleCategoryTitles) this.categoryTitles = singleCategoryTitles;

    return singleCategoryTitles;
  };

  @action
  setClientBundleStatuses = () => {
    const { translations } = translationStore;

    this.clientBundleStatuses = {
      2: translations.clientBundleActivationStatus,
      3: translations.clientBundleActiveStatus,
      4: translations.clientBundlePartlyUsedStatus,
      5: translations.clientBundleUsedStatus,
      6: translations.clientBundleExpiredStatus
    };
  };
}

export const bundlesStore = new BundlesStore();
export default BundlesStore;
