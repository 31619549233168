import { observable, action, computed } from 'mobx';

class LoadingBarStore {
  @observable navTitle?: string;

  @observable loader?: any;

  @observable activeRequests = 0;

  @computed
  get progress() {
    return 100 / this.activeRequests - 1;
  }

  @action
  setLoader(ref: any) {
    this.loader = ref;
  }

  @action
  add = (value = 10) => {
    if (!this.loader) return;
    this.activeRequests = this.activeRequests + value;
  };

  @action
  decrease = (value = 10) => {
    if (!this.loader) return;
    this.activeRequests = this.activeRequests - value;
  };
}

export const loadingBarStore = new LoadingBarStore();
export default LoadingBarStore;
