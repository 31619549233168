import React from 'react';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';
import classNames from 'classnames';
import { FaFilePdf } from 'react-icons/fa';
import styles from './Uploader.module.scss';

interface File {
  name: string;
  size: number;
  type: string;
  path?: string;
}

interface UploaderProps {
  name: string;
  label?: string;
  file?: any;
  idName?: string;
  multiple?: boolean;
  uploaderChoosePhotoLabel: string;
  uploaderRemovePhotoLabel: string;
  uploaderChoosePhotoWindow: string;
  handleUpload: (e: any) => void;
  handleRemove: () => void;
  pullRight?: boolean;
  requiredClass?: boolean;
}

const Uploader = ({
  name,
  idName,
  label,
  file,
  multiple,
  handleUpload,
  handleRemove,
  pullRight,
  requiredClass,
  uploaderChoosePhotoLabel,
  uploaderRemovePhotoLabel,
  uploaderChoosePhotoWindow
}: UploaderProps) => (
  <div
    className={styles.uploader}
    style={pullRight ? { marginRight: '25px' } : {}}
  >
    {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
    <label
      className={classNames(
        'control-label',
        { 'col-md-3': pullRight },
        { 'field-required': requiredClass }
      )}
    >
      {label || null}
    </label>

    <input
      type="file"
      id={idName || 'uploader'}
      multiple={multiple}
      name={name}
      accept="image/png, image/jpg, image/jpeg, .pdf"
      onChange={(e: any) =>
        multiple
          ? handleUpload(e.currentTarget.files)
          : handleUpload(e.currentTarget.files[0])
      }
    />
    <div className={styles['uploader-container']}>
      <div className={classNames({ 'col-md-8': pullRight })}>
        <label className={styles['uploader-container__box']} htmlFor="uploader">
          {!file || file.length === 0 ? uploaderChoosePhotoLabel : null}

          {file && file.type === 'application/pdf' && !multiple && (
            <FaFilePdf size={60}></FaFilePdf>
          )}

          {file && file.type !== 'application/pdf' && !multiple && (
            <img
              className={styles.image}
              src={file.path ? file.path : URL.createObjectURL(file)}
              alt="Uploader"
            />
          )}

          {file && multiple && file.length > 0 && (
            <img
              className={styles.image}
              src={file[0].path ? file[0].path : URL.createObjectURL(file[0])}
              alt="Uploader"
            />
          )}
        </label>
      </div>

      <div className={styles['uploader-container__buttons']}>
        <label htmlFor={idName || 'uploader'}>
          {uploaderChoosePhotoWindow}
        </label>

        <Button bsStyle="danger" fill onClick={handleRemove}>
          {uploaderRemovePhotoLabel}
        </Button>
      </div>
    </div>
  </div>
);

export default Uploader;
