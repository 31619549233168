import React from 'react';
import { CellInfo } from 'react-table';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { buyPackageStore } from 'data/stores/BuyPackageStore';
import { translationStore } from 'data/stores/TranslationStore';

const AnonymousSendEmailCell = ({ value }: CellInfo) => {
  const { openSendEmailModal } = buyPackageStore;
  const { translations } = translationStore;

  if (!value) {
    return null;
  }

  return (
    <div>
      <Button
        disabled={!!value.client}
        bsStyle="info"
        fill
        onClick={() => openSendEmailModal(value.code)}
      >
        {translations.sendQrCodeButton}
      </Button>
    </div>
  );
};

export default AnonymousSendEmailCell;
