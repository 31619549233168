import axios, { AxiosRequestConfig } from 'axios';
import { UpgradeClientBundleData } from 'data/types';
import BaseRepo from './BaseRepo';

const type = 'digital-affiliate';

const getCurrentClientBundleAndUpgradableBundles = (
  config: AxiosRequestConfig
) => {
  return axios.get(
    `${type}/client-bundle?include[]=bundle&include[]=client.user&include[]=additionalBundles&include[]=activeAdditionalBundles&include[]=currentBundle&include[]=balances&include[]=digitalAffiliatePayslip`,
    config
  );
};

const upgradeClientBundle = (data: UpgradeClientBundleData) => {
  return axios.post(
    `${type}/client-bundle-upgrade?include[]=client.user&include[]=digitalAffiliatePayslip`,
    data
  );
};

const upgradeClientBundleRepo = new BaseRepo(type);

export default {
  ...upgradeClientBundleRepo,
  getCurrentClientBundleAndUpgradableBundles,
  upgradeClientBundle
};
