import { Accident } from 'data/types';
import getValue, { attributesJoin } from 'lib/getValue';
import React from 'react';
import { Table } from 'react-bootstrap';
import Card from 'vendors/components/Card/Card.jsx';

interface AccidentInfoProps {
  accident: Accident;
}

const colorByStatus = {
  Dropped: 'red',
  Expired: 'red',
  Finished: 'green',
  Paid: 'green'
};

const AccidentInfo = ({ accident }: AccidentInfoProps) => {
  const allOperators = () => {
    if (accident.workedOnUsers) {
      const stringOfOperators = accident.workedOnUsers.reduce(
        (string, currUser) =>
          `${string} ${attributesJoin(currUser, 'firstName', 'lastName')}, `,
        ''
      );

      return stringOfOperators;
    }
    return 'N/A';
  };

  return (
    <Card
      title="Accident"
      content={
        <Table responsive striped hover>
          <tbody>
            <tr>
              <td>Accident ID</td>
              <td>{getValue(accident, 'realId')}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td style={{ color: colorByStatus[accident.status] }}>
                {getValue(accident, 'status')}
              </td>
            </tr>
            <tr>
              <td>Active operator</td>
              <td>
                {attributesJoin(
                  accident,
                  'accidentInfo.workingOnUser.firstName',
                  'accidentInfo.workingOnUser.lastName'
                )}
              </td>
            </tr>
            <tr>
              <td>Operators worked</td>
              <td>{allOperators()}</td>
            </tr>
          </tbody>
        </Table>
      }
    />
  );
};
export default AccidentInfo;
