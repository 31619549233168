import axios from 'axios';
import BaseRepo from './BaseRepo';

const type = 'anonymous-client-bundles';

const sendEmailWithLink = (data: any) => {
  return axios.post(`${type}/email`, data);
};

const clientBundlesAnonymousRepo = new BaseRepo(type);

export default { ...clientBundlesAnonymousRepo, sendEmailWithLink };
