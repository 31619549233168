import { observable, action, runInAction } from 'mobx';

import { trimWhitespaces } from 'lib/formHelpers';
import { navigate } from 'lib/routerHelper';
import { WarningDialog } from 'lib/dialogs';
import to from 'lib/awaitTo';
import {
  Balance,
  Client,
  ClientBundle,
  GetCurrentAndUpgradableBundlesFromData
} from 'data/types';

import upgradeClientBundleRepo from 'data/repositories/UpgradeClientBundleRepo';
import ClientBundlesRepo from 'data/repositories/ClientBundlesRepo';

import { Bundle } from 'typescript';
import { translationStore } from './TranslationStore';

class TechnicalInspectionStore {
  @observable currentBundle?: Bundle;

  @observable currentClientBundle?: ClientBundle;

  @observable balances: Balance[] = [];

  @observable client?: Client;

  @observable isTechnicalInspectionFeatureValid = false;

  @observable technicalInspectionBalance?: Balance;

  @action
  updateBalanceStatus = async (
    balanceId: string,
    data: Balance,
    history: any
  ) => {
    let newData = {} as Balance;

    if (data.noFeaturesLeft >= 1) {
      newData = {
        ...data,
        noFeaturesLeft: data.noFeaturesLeft - 1,
        noFeaturesUsed: data.noFeaturesUsed + 1
      };

      if (newData.noFeaturesLeft === 0) {
        newData.status = 'inactive';
      }
    }

    const [response] = await to(
      ClientBundlesRepo.updateTechnicalInspectionStatus(balanceId, newData)
    );

    if (response) {
      navigate('technicalInspectionInformations', { history });
    }
  };

  @action
  getBundleInfo = async (
    formData: GetCurrentAndUpgradableBundlesFromData,
    history?: any
  ) => {
    const { translations } = translationStore;

    const noWhiteSpacesFormData = {
      ...formData,
      userMobileNumber: trimWhitespaces(formData.userMobileNumber)
    };

    // We can use same endpoint for this
    const [response] = await to(
      upgradeClientBundleRepo.getCurrentClientBundleAndUpgradableBundles({
        params: noWhiteSpacesFormData
      })
    );

    if (response) {
      runInAction(() => {
        this.client = response.client;
        this.currentClientBundle = response;
        this.currentBundle = response.currentBundle;
      });
      this.setBalancesValues(response.balances);

      if (history) {
        navigate(
          'technicalInspectionReport',
          { history },
          { mobileNum: formData.userMobileNumber }
        );
      }
    } else {
      WarningDialog.fire({
        type: 'info',
        title: translations.technicalInspectionClientNotActivatePackage,
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn btn-fill btn-info'
        }
      });
    }
  };

  @action
  setBalancesValues = (balances: Balance[]) => {
    this.balances = balances;

    this.technicalInspectionBalance = this.balances.find(
      (balance: Balance) =>
        balance!.accidentServiceType!.name === 'technical_inspection'
    );

    this.isTechnicalInspectionFeatureValid = this.balances.some(
      (balance: Balance) =>
        balance!.accidentServiceType!.name === 'technical_inspection' &&
        balance.status === 'active'
    );
  };
}

export const technicalInspectionStore = new TechnicalInspectionStore();
export default TechnicalInspectionStore;
