import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { RowInfo } from 'react-table';
import { CSVLink } from 'react-csv';
import { MdFlag } from 'react-icons/md';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { navigate } from 'lib/routerHelper';
import { getValueOrNull } from 'lib/getValue';
import { ROLE_AFFILIATE_COMPANY, ROLE_B2B_CLUB } from 'lib/constants';

import DataTable from 'components/DataTable';
import { DateCell } from 'components/TableCells';
import PageActions from 'components/PageActions';
import RoleGuard from 'components/RoleGuard/RoleGuard';
import { authStore } from 'data/stores/AuthStore';
import styles from './AccidentListPage.module.scss';
import { accidentsListStore } from './AccidentsListStore';

const columns = [
  {
    Header: 'Accident ID',
    id: 'id',
    accessor: 'realId',
    sortable: false,
    filterable: true
  },
  {
    Header: 'Date',
    accessor: 'timeAccidentCreatedAt',
    Cell: DateCell(),
    width: 150,
    filterable: true
  },
  {
    Header: 'First Name',
    id: 'clientb2b.first_name',
    accessor: 'clientB2b.firstName',
    sortable: false,
    filterable: true
  },
  {
    Header: 'Last Name',
    id: 'clientb2b.last_name',
    accessor: 'clientB2b.lastName',
    sortable: false,
    filterable: true
  },
  {
    Header: 'Country of accident',
    id: 'country.shortname',
    accessor: 'country.shortname',
    sortable: false,
    filterable: true
  },
  {
    Header: 'Accident type',
    id: 'accidenttype.name',
    accessor: 'accidentType.name',
    filterable: true,
    sortable: false
  },
  {
    Header: 'Destination repairer',
    accessor: 'destinationRepairer.name',
    id: 'destinationrepairer.name',
    filterable: true,
    sortable: false
  },
  {
    Header: 'Status',
    accessor: 'status',
    filterable: true
  },
  {
    Header: 'More features',
    filterable: false,
    Cell: (data: any) => {
      if (data.original && data.original.accidentOffices.length > 1)
        return <MdFlag style={{ color: 'red' }} />;
      return '';
    }
  },
  {
    Header: 'Bill uploaded',
    filterable: false,
    Cell: (data: any) => {
      if (getValueOrNull(data, 'original.accidentInfo.billUploaded'))
        return (
          <span role="img" aria-label="Invoice" style={{ margin: 0 }}>
            💰
          </span>
        );
      return '';
    }
  },
  {
    Header: 'Company',
    accessor: 'companyVehicle.company.name',
    sortable: true,
    filterable: false
  },
  {
    Header: 'Car brand',
    id: 'companyvehicle.carmodel.carbrand.name',
    accessor: 'companyVehicle.carBrand',
    sortable: false,
    filterable: true
  },
  {
    Header: 'Car model',
    id: 'companyvehicle.carmodel.name',
    accessor: 'companyVehicle.carModel',
    sortable: false,
    filterable: true
  },
  {
    Header: 'Client Vehicles Plates',
    id: 'companyvehicle.registration_plate',
    accessor: 'companyVehicle.registrationPlate',
    filterable: true,
    sortable: false
  },
  {
    Header: 'Client Vehicles VIN',
    id: 'companyvehicle.vin_number',
    accessor: 'companyVehicle.vinNumber',
    filterable: true,
    sortable: false
  }
];

const AccidentsListPage = ({ history }: RouteComponentProps) => {
  const { clearExportData, changeTab } = accidentsListStore;

  const { roles } = authStore;
  const isClub = roles.find((role: any) => role.name === ROLE_B2B_CLUB);

  useEffect(() => {
    if (isClub) {
      changeTab('isClub');
    } else {
      changeTab('vwAudiSeat');
    }

    return () => {
      changeTab('');
    };
  }, [isClub, changeTab]);

  useEffect(() => {
    return () => {
      clearExportData();
    };
  }, [clearExportData]);

  const getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: {
        cursor: 'pointer'
      },
      onClick: () => {
        if (row && row.original) {
          navigate(
            'forceAccidentDetails',
            { history },
            { id: row.original.id }
          );
        }
      }
    };
  };

  const getCsvData = () => {
    accidentsListStore.exportCsv();
  };

  const headersForReturn = () => {
    return [
      { label: 'Id', key: 'acccidentId' },
      { label: 'Datum slučaja', key: 'date' },
      { label: 'Ime klijenta', key: 'firstName' },
      { label: 'Prezime klijenta', key: 'lastName' },
      { label: 'Država', key: 'countryOfAccident' },
      { label: 'Tip slučaja', key: 'accidentType' },
      { label: 'Status', key: 'status' },
      { label: 'Dodaci', key: 'features' },
      { label: 'Račun postavljen', key: 'billUploaded' },
      { label: 'Brend vozila', key: 'carBrand' },
      { label: 'Model vozila', key: 'carModel' },
      { label: 'Tablice', key: 'vehiclePlates' },
      { label: 'Vin', key: 'vin' },
      { label: 'B do C km', key: 'distanceBToC' }
    ];
  };

  const { exportResults, activeTab } = accidentsListStore;

  return (
    <>
      <RoleGuard visible={[ROLE_B2B_CLUB]}>
        <PageActions leftAlign>
          <Button bsStyle="warning" fill onClick={() => getCsvData()}>
            Generate CSV data
          </Button>
          <CSVLink
            filename="Accidents.csv"
            data={exportResults}
            headers={headersForReturn()}
          >
            <Button
              className="m-l-16 btn-success btn-fill"
              disabled={exportResults.length === 0}
            >
              Download CSV
            </Button>
          </CSVLink>
        </PageActions>
      </RoleGuard>
      <RoleGuard visible={[ROLE_AFFILIATE_COMPANY]}>
        <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
          <Button
            style={{ width: 'unset' }}
            className={
              activeTab === 'vwAudiSeat'
                ? styles.activeButtonTab
                : styles.buttonTab
            }
            onClick={() => activeTab && changeTab('vwAudiSeat')}
          >
            VW-Audi-Seat asistencija
          </Button>

          <Button
            style={{ width: 'unset' }}
            className={
              activeTab === 'mobilityFleet'
                ? styles.activeButtonTab
                : styles.buttonTab
            }
            onClick={() => activeTab && changeTab('mobilityFleet')}
          >
            Mobility Fleet asistencija
          </Button>

          <Button
            style={{ width: 'unset' }}
            className={
              activeTab === 'additionalCompanyServices'
                ? styles.activeButtonTab
                : styles.buttonTab
            }
            onClick={() => activeTab && changeTab('additionalCompanyServices')}
          >
            Additional company services
          </Button>
        </div>
      </RoleGuard>
      <DataTable
        className="withDetailsCell"
        dateRangeFilter
        columns={columns}
        getTdProps={getTdProps}
        fetchData={accidentsListStore.fetch}
        data={accidentsListStore.data}
        meta={accidentsListStore.meta}
        loading={accidentsListStore.loading}
      />
    </>
  );
};

export default observer(AccidentsListPage);
