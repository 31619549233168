import React from 'react';

import styles from './PageActions.module.scss';

interface PageActionsProps {
  children: React.ReactChild | React.ReactChild[];
  spaceBetween?: boolean;
  spaceBetweenNoMargin?: boolean;
  leftAlign?: boolean;
}

const PageActions: React.FC<PageActionsProps> = ({
  children,
  spaceBetween,
  spaceBetweenNoMargin,
  leftAlign
}: PageActionsProps) => {
  const setRightClassName = () => {
    if (spaceBetween) return styles.containerBetween;

    if (spaceBetweenNoMargin) return styles.containerBetweenNoMargin;

    if (leftAlign) return styles.containerLeftAlign;

    return styles.container;
  };

  return <div className={setRightClassName()}>{children}</div>;
};

export default PageActions;
