import React from 'react';
import { Table } from 'react-bootstrap';
import Card from 'vendors/components/Card/Card.jsx';

import { AccidentOffice } from 'data/types';
import getValue, { getDate } from 'lib/getValue';

interface AccidentOfficesListProps {
  accidentOffices?: AccidentOffice[];
}

const AccidentOfficesBody = ({ accidentOffices }: AccidentOfficesListProps) => {
  if (!accidentOffices || !accidentOffices.length) {
    return <h4>Non features used</h4>;
  }
  return (
    <Table responsive striped hover>
      <tbody>
        <tr>
          <th>Feature</th>
          <th>Date</th>
          <th>Item</th>
          <th>Quantity</th>
          <th>Description</th>
        </tr>
        {accidentOffices.map((accOffice: AccidentOffice) => (
          <tr>
            <td>{getValue(accOffice, 'accidentOfficeType.name')}</td>
            <td>{getDate(getValue(accOffice, 'createdAt.date'))}</td>
            <td>{getValue(accOffice, 'netsuiteItem.name')}</td>
            <td>{getValue(accOffice, 'netsuiteItem.quantity')}</td>
            <td>{getValue(accOffice, 'description')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const AccidentOfficesList = ({ accidentOffices }: AccidentOfficesListProps) => (
  <Card
    title="Features"
    content={<AccidentOfficesBody accidentOffices={accidentOffices} />}
  />
);

export default AccidentOfficesList;
