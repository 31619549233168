import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { Bundle, Client, ClientBundle } from 'data/types';

import Card from 'components/Card';
import PrintComponent from 'components/PrintComponent';
import BundleWithFeaturesInfoPrint from 'components/BundleWithFeaturesInfoPrint';

interface ActivatedPackageProps {
  translations: any;
  client?: Client;
  enteredPhoneNumber: string;
  activatedBundle: Bundle;
  clientBundle: ClientBundle;
  categoryTitles: string[];
}

const ActivatedPackage = ({
  translations,
  client,
  activatedBundle,
  categoryTitles,
  enteredPhoneNumber,
  clientBundle
}: ActivatedPackageProps) => {
  const styleLi = {
    marginBottom: '15px',
    fontSize: '16px'
  };

  return (
    <Row>
      <Col md={8}>
        <h2>
          {translations.package} <b>{activatedBundle!.name}</b>{' '}
          {translations.packageActivatedOnNumber}{' '}
          <b>{client ? client.user.mobileNumber : enteredPhoneNumber}</b>
        </h2>
        <ul>
          <li style={styleLi}>{translations.packageActivatedDescription}</li>
          <li style={styleLi}>
            {translations.packageActivatedPrintDocs}
            <ul style={{ marginTop: '15px' }}>
              <li style={styleLi}>
                <PrintComponent
                  pageOne={
                    <BundleWithFeaturesInfoPrint
                      selectedBundle={activatedBundle}
                      categoryTitles={categoryTitles}
                      categoryTitleIndex={0}
                      clientBundle={clientBundle}
                    />
                  }
                  buttonText={translations.printLinkPackageContent}
                />
              </li>
            </ul>
          </li>
        </ul>
      </Col>
      <Col md={4}>
        <Card>
          <p>{translations.packageActivatedDeactivateText}</p>
          <Link to="/dashboard/stats">
            <Button bsStyle="danger" fill wd style={{ width: '100%' }}>
              {translations.deactivateButton}
            </Button>
          </Link>
        </Card>
      </Col>
    </Row>
  );
};

export default ActivatedPackage;
