/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import flow from 'lodash/flow';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import * as Sentry from '@sentry/react';

import { authStore } from 'data/stores/AuthStore';
import { Toast, genericError, WarningDialog } from 'lib/dialogs';
import { loadingBarStore } from 'data/stores/LoadingBarStore';
import { translationStore } from 'data/stores/TranslationStore';
import Errors from "../lib/errors";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const token = Cookies.get('token');

if (token) {
  authStore.setAuthToken(token);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mergeAttributes = ({ attributes, type, ...rest }: any) => ({
  ...JSON.parse(JSON.stringify(attributes, (_k, v) => (v === null ? '' : v))),
  ...rest
});

const mergeAttributesList = (data: object[]) => data.map(mergeAttributes);

const withIncluded = (data: any) => {
  const keys = Object.keys(data);
  keys.forEach(key => {
    const datum = data[key];
    if (datum && datum.data && datum.data.attributes) {
      data[key] = mergeAttributes(datum.data);
      return withIncluded(data[key]);
    }
    if (datum && datum.data && Array.isArray(datum.data)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const parseData = flow([mergeAttributesList, withIncludedList]);
      data[key] = parseData(datum.data);
    }
  });
  return data;
};

const withIncludedList = (data: object[]) => {
  return data.map(item => withIncluded(item));
};

const responseSuccessHandler = (response: any) => {
  loadingBarStore.decrease();

  if (
    response.data &&
    response.data.data &&
    Array.isArray(response.data.data)
  ) {
    const { data, meta } = response.data;
    const parseData = flow([mergeAttributesList, withIncludedList]);
    return { data: parseData(data), meta };
  }

  if (response.data && response.data.data && response.data.data.attributes) {
    const parseData = flow([
      mergeAttributes,
      (data: object) => withIncluded(data)
    ]);

    return parseData(response.data.data);
  }
  return response;
};

const errorInfoToast = (error: any) => {
  const { translations } = translationStore;

  let validationHandler: Errors = new Errors();
  let code: string | undefined;
  let message: string | undefined;

  if (error.data.errors.message) {
    message = get(error, 'data.errors.message');
  } else {
    message = get(error, 'data.message');
  }

  // Translated errros we want to show only title, not error code
  const errorsData = get(error, 'data.errors');
  const translatedError = translations[errorsData.key];

  if (errorsData.key === 'CANT_FIND_CLIENT_INVALID_PHONE_NUMBER') {
    return null;
  }

  // If none packages are sold, do nothing
  if (errorsData.key === 'CANT_FIND_SOLD_CLIENT_BUNDLES') {
    return null;
  }

  if (error.status) {
    code = get(error, 'status');
  } else if (error.data.errors.responseCode) {
    code = get(error, 'data.errors.responseCode');
  } else if (error.data.errors.response_code) {
    code = get(error, 'data.errors.response_code');
  } else if (error.data.errors.statusCode) {
    code = get(error, 'data.errors.statusCode');
  } else if (error.data.errors.status_code) {
    code = get(error, 'data.errors.status_code');
  }

  if (code === '422') {
    validationHandler.record(error.data.errors.data)
    setTimeout(
      () =>
        WarningDialog.fire({
          type: 'warning',
          title: 'Invalid data',
          html: generateValidationHtml(),
          confirmButtonText: 'Okay',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-warning btn-fill m-r-8'
          }
        }),
      500
    );
  }
  // Check if translated error exists
  else if (translatedError && errorsData.key === 'TWILIO_SERVICE_ERROR') {
    setTimeout(
      () =>
        WarningDialog.fire({
          type: 'warning',
          title: translations.TWILIO_SERVICE_ERROR_TITLE,
          text: translatedError,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-warning btn-fill m-r-8'
          }
        }),
      500
    );
  } else if (translatedError) {
    setTimeout(
      () =>
        WarningDialog.fire({
          type: 'error',
          text: translatedError,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill'
          }
        }),
      500
    );
  } else if (message && !isUndefined(code)) {
    setTimeout(
      () =>
        WarningDialog.fire({
          type: 'error',
          title: `Code: ${code}`,
          text: message,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill m-r-8'
          }
        }),
      500
    );
  } else {
    genericError();
  }

  const generateValidationHtml = () => {
    let result = '<ul style="text-align: left;">';
    validationHandler
      .invalidFields()
      .forEach((field) => {
        result += "<li>";
        result += `<b>${field}:</b>`
        result += '<ul>'
        result += `<li> ${validationHandler.get(field)} </li>`
        result += '</ul>'
        result += "</li>";
      });
    result += '</ul>';
    return result;
  }
};

const responseErrorHandler = (error: AxiosError) => {
  const { requestCounter } = authStore;

  loadingBarStore.decrease();

  // if (error.response && error.response.status === 403) {
  //   Toast.fire({
  //     type: 'error',
  //     title: 'Please, try to login again'
  //   });
  //   Sentry.captureException(error);

  //   authStore.logout();
  //   return window.location.reload();
  // }

  if (error.response && error.response.status === 401) {
    authStore.increaseRequestCounter();
    Sentry.captureException(error.response);

    if (
      error.response.data &&
      error.response.data.message ===
        'The user credentials were incorrect. (invalid_credentials)'
    ) {
      Sentry.captureMessage(error.response.data.message);
      Toast.fire({
        type: 'error',
        title: 'Username or password is not valid'
      });
      return authStore.logout();
    }

    if (requestCounter > 10) {
      return authStore.logout();
    }

    const remmberMe = Cookies.get('remember_me');

    if (isUndefined(remmberMe)) {
      Toast.fire({
        type: 'error',
        title: 'Logged out! Your session has expired please login again!'
      });
      return authStore.logout();
    }
    error.config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
    // error.config.headers.Accept = 'application/json';

    const { config } = error;
    return new Promise((resolve, reject) => {
      axios
        .request(config)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }
  if (error.response && error.response.data) {
    errorInfoToast(error.response);
    Sentry.captureException(error.response);
    return Promise.reject(error.response.data);
  }

  return Promise.reject(error);
};

const requestSuccessHandler = (request: any) => {
  loadingBarStore.add();
  const refreshToken = Cookies.get('refresh_token');
  const remmberMe = Cookies.get('remember_me');

  if (authStore.hasTokenExpired()) {
    authStore.clearAuthCookieData();

    if (remmberMe === 'RememberMe' && !isUndefined(refreshToken)) {
      return authStore
        .getNewToken({
          refresh_token: refreshToken
        })
        .then(response => {
          const { data } = response;

          Cookies.set('refresh_token', data.refresh_token);
          Cookies.set('token', data.access_token);
          authStore.setExpiresIn(data.expires_in);

          request.headers.Authorization = `Bearer ${data.access_token}`;
          axios.defaults.headers.Authorization = `Bearer ${data.access_token}`;

          authStore.resetRequestCounter();

          return request;
        })
        .catch(err => {
          Sentry.captureException(err);
          return Promise.reject(err);
        });
    }

    Toast.fire({
      type: 'error',
      title: 'Logged out! Your session has expired please login again!'
    });
    return authStore.logout();
  }

  return request;
};

const requestErrorHandler = (error: AxiosError) => {
  Sentry.captureException(error.response);
  return Promise.reject(error);
};

axios.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

axios.interceptors.request.use(requestSuccessHandler, requestErrorHandler);
