import axios, { AxiosRequestConfig } from 'axios';
import BaseRepo from './BaseRepo';

const type = 'accidents-for-company-repairer';

const getAccidentById = (accId: string, params?: AxiosRequestConfig) => {
  return axios.get(`accidents/${accId}`, params);
};

const uploadAccidentFile = (data: any, id: string) => {
  const formData = new FormData();
  formData.append('files[]', data.file);

  return axios.post(`${type}/${id}/admins/upload/files`, formData);
};

const deleteAccidentFile = (accidentId: string, fileId: string) => {
  return axios.delete(`${type}/${accidentId}/files/${fileId}`);
};

const getAccidentLocations = (
  driverId: string,
  accidentId: string,
  data: any
) => {
  return axios.get(
    `/drivers/${driverId}/locations/accidents/${accidentId}`,
    data
  );
};

const getWorkOrderByIdPublic = (
  workOrderId: string,
  params?: AxiosRequestConfig
) => {
  return axios.get(`accident-work-orders-public/${workOrderId}`, params);
};

const fetchByCompany = (params?: AxiosRequestConfig) => {
  return axios.get('accidents-for-company-repairers', params);
};

const fetchByClub = (params?: AxiosRequestConfig) => {
  return axios.get('accidents-for-b2b-club', params);
};

const fetchAdditionalCompanyRepairers = (params?: AxiosRequestConfig) => {
  return axios.get('accidents-for-additional-company-repairers', params);
};

const accidentsRepo = new BaseRepo(type);

export default {
  ...accidentsRepo,
  getAccidentById,
  deleteAccidentFile,
  uploadAccidentFile,
  getAccidentLocations,
  getWorkOrderByIdPublic,
  fetchByCompany,
  fetchByClub,
  fetchAdditionalCompanyRepairers
};
