import React, { useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { RowInfo } from 'react-table';

import { DATE_FORMAT } from 'lib/constants';
import getValue, { getValueOrNull } from 'lib/getValue';
import { translationStore } from 'data/stores/TranslationStore';
import { authStore } from 'data/stores/AuthStore';

import DataTable from 'components/DataTable';
import { BooleanToYesOrNoCell, DateCell } from 'components/TableCells';

import { invoicesStore } from '../InvoicesStore';

const SpecificationsListPage = () => {
  const { translations } = translationStore;
  const columns = [
    {
      Header: translations.invoiceTableCompany,
      accessor: 'company.name',
      filterable: false,
      sortable: false
    },
    {
      Header: translations.invoiceTableFilename,
      accessor: 'fileName',
      filterable: false,
      sortable: false
    },
    {
      Header: translations.invoiceTableIsPaid,
      accessor: 'isPaid',
      Cell: BooleanToYesOrNoCell,
      filterable: false,
      sortable: false
    },
    {
      Header: translations.invoiceTableDateIssued,
      accessor: 'dateIssued',
      Cell: DateCell(DATE_FORMAT),
      sortable: false,
      filterable: false
    },
    {
      Header: translations.invoiceTableBillingPeriod,
      accessor: 'billingPeriod',
      Cell: DateCell(DATE_FORMAT),
      filterable: false,
      sortable: false
    },
    {
      Header: translations.invoiceTablePaymentDeadline,
      accessor: 'paymentDeadline',
      Cell: DateCell(DATE_FORMAT),
      sortable: false,
      filterable: false
    },
    {
      Header: translations.dataTableActions,
      accessor: 'actions',
      sortable: false,
      filterable: false
    }
  ];

  const pdf = (
    <Tooltip id="viewPdf">{translations.invoicePageActionTooltip}</Tooltip>
  );

  useEffect(() => {
    invoicesStore.clearStore();
  }, []);

  const itemAction = () => {
    return (
      <div
        onClick={e => e.stopPropagation()}
        role="presentation"
        className="td-actions text-right"
      >
        <OverlayTrigger placement="top" overlay={pdf}>
          <span className="text-info">
            {translations.invoicePageActionText}
          </span>
        </OverlayTrigger>
      </div>
    );
  };

  const getTdProps = (_state: any, row?: RowInfo): object => {
    const { mapObject, companyTypes } = authStore;

    if (mapObject.isBlocked) {
      return {
        style: {
          cursor: 'not-allowed'
        }
      };
    }
    /** Temp solution, until we organize type of sales */
    if (companyTypes.includes('payslip_payment')) {
      return {
        style: {
          cursor: 'pointer'
        },
        onClick: () =>
          invoicesStore.downloadPDF({
            filePath: getValue(row, 'original.filePath'),
            fileName: getValue(row, 'original.fileName')
          })
      };
    }
    return {
      style: {
        cursor: getValueOrNull(row, 'original.affiliateInvoice.isPaid')
          ? 'pointer'
          : 'not-allowed'
      },
      onClick: () => {
        if (getValueOrNull(row, 'original.affiliateInvoice.isPaid')) {
          invoicesStore.downloadPDF({
            filePath: getValue(row, 'original.filePath'),
            fileName: getValue(row, 'original.fileName')
          });
        }
      }
    };
  };

  // Add gray filter to whole row
  const addFilterToPaidStatus = (state: any, rowInfo: any) => {
    const { mapObject, companyTypes } = authStore;
    if (mapObject.isBlocked) {
      return {
        style: {
          filter: 'grayscale(1) opacity(0.5)'
        }
      };
    }
    /** Temp solution, until we organize type of sales */
    if (companyTypes.includes('payslip_payment')) {
      return {
        style: {
          cursor: 'pointer'
        }
      };
    }
    if (!getValueOrNull(rowInfo, 'original.affiliateInvoice.isPaid')) {
      return {
        style: {
          filter: 'grayscale(1) opacity(0.5)'
        }
      };
    }
    if (getValueOrNull(rowInfo, 'original.isPaid')) {
      return {
        style: {
          color: 'green',
          filter: 'grayscale(1) opacity(0.5)'
        }
      };
    }

    return {};
  };

  return (
    <>
      <DataTable
        columns={columns}
        fetchData={invoicesStore.fetchSpecifications}
        data={invoicesStore.attachCustomColumnSpecifications(
          'actions',
          itemAction
        )}
        loading={invoicesStore.loading}
        meta={invoicesStore.meta}
        getTdProps={getTdProps}
        getTrProps={addFilterToPaidStatus}
        defaultSort={[{ id: 'createdAt', desc: true }]}
      />
    </>
  );
};

export default observer(SpecificationsListPage);
