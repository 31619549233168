import { observable, action } from 'mobx';

import clientsRepo from 'data/repositories/ClientsRepo';
import to from 'lib/awaitTo';
import { Client } from 'data/types';
import { WarningDialog } from 'lib/dialogs';
import { translationStore } from 'data/stores/TranslationStore';

class CheckClientStore {
  @observable client = {} as Client;

  @observable showModal = false;

  includes = ['clientBundle.mapObject', 'clientBundle.currentBundle'];

  fetchClientInfo = async (mobileNum: string) => {
    const { translations } = translationStore;

    const queryParams = {
      include: this.includes
    };

    const [res] = await to(
      clientsRepo.checkClientByMobileNum(mobileNum, { params: queryParams })
    );

    if (res) {
      this.setClient(res);

      if (res.clientBundle) {
        this.openModal();
      } else {
        WarningDialog.fire({
          type: 'info',
          title: translations.checkClientDialogNoPackage,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-info btn-fill'
          }
        });
      }
    } else {
      WarningDialog.fire({
        type: 'info',
        title: translations.checkClientDialogNoClient,
        confirmButtonText: 'Okay',
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn btn-info btn-fill'
        }
      });
    }
  };

  @action
  setClient = (res: Client) => {
    this.client = res;
  };

  @action
  openModal = () => {
    this.showModal = true;
  };

  @action
  closeModal = () => {
    this.showModal = false;
  };
}

export const checkClientStore = new CheckClientStore();
export default CheckClientStore;
