import { authStore } from 'data/stores/AuthStore';
import { translationStore } from 'data/stores/TranslationStore';
import { Bundle, ClientBundle } from 'data/types';
import getValue, { attributesJoin, getDate } from 'lib/getValue';
import React from 'react';

import styles from '../BundleWithFeaturesInfo/BundleWithFeaturesInfo.module.scss';

interface BundleWithFeaturesInfoPrintProps {
  selectedBundle: Bundle;
  categoryTitles: string[];
  categoryTitleIndex: number;
  territoryTitle?: string;
  clientBundle?: ClientBundle;
  pageTwo?: boolean;
  upgrade?: boolean;
}

const BundleWithFeaturesInfoPrint = ({
  selectedBundle,
  categoryTitles,
  categoryTitleIndex,
  pageTwo,
  upgrade,
  clientBundle,
  territoryTitle
}: BundleWithFeaturesInfoPrintProps) => {
  const { translations } = translationStore;
  const { language, company, isVulco } = authStore;
  const sindikatMup = company.name === 'Nezavisni Sindikat MUP';
  const sindikatGvozdeniPuk = company.name === 'Sindikat Gvozdeni Puk';

  return (
    <>
      {!pageTwo && (
        <>
          <h2>
            {selectedBundle.name} -{' '}
            {/* eslint-disable-next-line no-nested-ternary */}
            {sindikatMup || sindikatGvozdeniPuk
              ? (selectedBundle.price * 0.8).toFixed(2)
              : isVulco
              ? 0
              : selectedBundle.price}{' '}
            {selectedBundle.currency.displayName}
          </h2>
          {!upgrade && (
            <p>
              {translations.bundleDuration}{' '}
              <b>
                {selectedBundle.duration} {translations.bundleDurationMonth}
              </b>
            </p>
          )}
          <p>
            {translations.printDocOC} <b>{translations.ocPhone1}</b>{' '}
            {language === 'sr' ? <b>ili {translations.ocPhone2}</b> : null}
          </p>
          {clientBundle && clientBundle.client && (
            <p>
              {translations.clientName}{' '}
              <b>
                {attributesJoin(
                  clientBundle,
                  'client.user.firstName',
                  'client.user.lastName'
                )}
              </b>{' '}
              {translations.clientPhoneNumber}{' '}
              <b>{getValue(clientBundle, 'client.user.mobileNumber')}</b>{' '}
              {translations.clientDateOfActivation}{' '}
              <b>{getDate(clientBundle.purchasedAt)}</b>{' '}
              {translations.clientDateOfExpiration}{' '}
              <b>
                {upgrade
                  ? getDate(clientBundle.expiredAt.date)
                  : getDate(clientBundle.expiredAt)}
              </b>
            </p>
          )}
        </>
      )}

      {territoryTitle && (
        <>
          <h4 className={styles.categoryTitle}>{territoryTitle}</h4>
          {selectedBundle.features &&
            selectedBundle.features.map((feature: any) => {
              if (territoryTitle === feature.meta.category_title) {
                return (
                  <div key={feature.id}>
                    <p style={{ marginBottom: 5 }}>
                      <b>
                        {feature.noFeatures}x {feature.title}{' '}
                      </b>
                    </p>

                    <p>{feature.description}</p>
                  </div>
                );
              }
              return null;
            })}
        </>
      )}

      <h4 className={styles.categoryTitle}>
        {categoryTitles[categoryTitleIndex]}
      </h4>

      {selectedBundle.features &&
        selectedBundle.features.map((feature: any) => {
          if (
            categoryTitles[categoryTitleIndex] === feature.meta.category_title
          ) {
            return (
              <div key={feature.id}>
                <p style={{ marginBottom: 5 }}>
                  <b>
                    {feature.noFeatures}x {feature.title}{' '}
                    {/* {feature.meta.additional_title ? (
                      <span>{feature.meta.additional_title}</span>
                    ) : null} */}
                  </b>
                </p>

                <p>{feature.description}</p>
              </div>
            );
          }
          return null;
        })}
    </>
  );
};

export default BundleWithFeaturesInfoPrint;
