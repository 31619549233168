type Sort = {
  id: string;
  desc: boolean;
};

type Filter = {
  id: string;
  value: string;
};

interface QueryBuilderParams {
  sorted?: Sort[];
  filtered?: Filter[];
  from?: string;
  to?: string;
  include?: string | string[];
  page?: number;
  limit?: number;
  format?: string;
  clientId?: string;
}

export interface Query {
  orderBy?: string;
  sortedBy?: 'desc' | 'asc';
  search?: string;
  include?: string | string[];
  searchJoin?: 'and' | 'or';
  page?: number;
  format?: string;
  limit?: number;
  clientId?: string;
  from?: string;
  to?: string;
  [key: string]: any;
}

function queryBuilder({
  sorted,
  filtered,
  from,
  to,
  include,
  page,
  limit,
  clientId,
  format
}: QueryBuilderParams): Query {
  const query: Query = {
    include
  };

  if (sorted && sorted[0] && sorted[0].id) {
    const { id, desc } = sorted[0];
    query.orderBy = id;
    query.sortedBy = desc ? 'desc' : 'asc';
  }

  if (page) {
    query.page = page;
  }

  if (clientId) {
    query.clientId = clientId;
  }

  if (limit) {
    query.limit = limit;
  }

  if (format) {
    query.format = format;
  }

  if (from) {
    query.from = from;
  }

  if (to) {
    query.to = to;
  }

  if (filtered && filtered.length > 0) {
    filtered.forEach(filter => {
      if (
        filter.id === 'purchasedAt' ||
        filter.id === 'last4Digits' ||
        filter.id === 'cardBin' ||
        filter.id === 'authId' ||
        filter.id === 'accident_id' ||
        filter.id === 'accidentOfficeType' ||
        filter.id === 'intervention' ||
        filter.id === 'paymentPlan' ||
        filter.id === 'dateFrom' ||
        filter.id === 'dateTo'
      ) {
        query[filter.id] = filter.value;
      } else if (query.search) {
        query.search = `${query.search};${filter.id}:${filter.value}`;
        query.searchJoin = 'and';
      } else {
        query.search = `${filter.id}:${filter.value}`;
        query.searchJoin = 'and';
      }
    });
  } else {
    delete query.search;
    delete query.searchJoin;
  }
  return query;
}

export default queryBuilder;
