/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */

import axios from 'axios';
import BaseRepo from './BaseRepo';

const type = 'bundles';

const parseResponse = (response: any) => {
  try {
    return response.data[0].translations;
  } catch (e) {
    console.log('Error parsing translations.');
    throw e;
  }
};

const transformToObject = (translations: any) => {
  return translations.reduce((map: any, transWithAttributes: any) => {
    const trans = transWithAttributes;
    map[trans.key] = trans.value;

    return map;
  }, {});
};

const getTranslationsByLocale = async (lang: string) => {
  const response = await axios.get(
    `/mobile-translations/?locales[]=${lang}&groups[]=digitalAffiliate`
  );

  const translations = parseResponse(response);

  const translationsObject = transformToObject(translations);

  return translationsObject;
};

const translationsRepo = new BaseRepo(type);

export default { ...translationsRepo, getTranslationsByLocale };
