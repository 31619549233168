import React from 'react';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { authStore } from 'data/stores/AuthStore';

const HeaderLinks = () => (
  <div className="hidden">
    <Nav pullRight>
      <NavDropdown
        eventKey={4}
        title={
          <div>
            <i className="fa fa-list" />
            <p className="hidden-md hidden-lg">
              More
              <b className="caret" />
            </p>
          </div>
        }
        noCaret
        id="basic-nav-dropdown-3"
        bsClass="dropdown-with-icons dropdown"
      >
        <MenuItem eventKey={4.3}>
          <i className="pe-7s-tools" /> Settings
        </MenuItem>
        <MenuItem divider />

        <MenuItem eventKey={4.5} onClick={authStore.logout}>
          <div className="text-danger">
            <i className="pe-7s-power" /> Log out
          </div>
        </MenuItem>
      </NavDropdown>
    </Nav>
  </div>
);
export default HeaderLinks;
