import React, { Component } from 'react';
import { observer } from 'mobx-react';

import imageSrc from 'assets/img/welcome-page-text.png';
import { authStore } from 'data/stores/AuthStore';
// import styles from './WelcomePage.module.scss';
import styles from './WelcomePage.module.scss';

@observer
class WelcomePage extends Component<{}> {
  render() {
    const { mapObject, company, language } = authStore;

    return (
      <>
        <div className={styles.contentWrapper}>
          <img src={imageSrc} className={styles.welcomeImage} alt="Welcome" />
          <p className={styles.email}>
            {mapObject && mapObject.name} {company && company.name}
          </p>
          {language === 'sr' && (
            <p className={styles.notification}>
              Poštovani korisnici i saradnici, <br />
              <br />
              Ovim putem vas obaveštavamo da VOZZi aplikacija, prodaja paketa i
              pružanje usluga pomoći na putu prestaje sa radom i biva
              suspendovana. <br />
              <br />
              Nakon vrlo mučne borbe za opstanak na domaćem tržištu, tim
              Kompanije zajedno sa njenim osnivačem, morao je da donese ovu, za
              nas vrlo bolnu odluku. Na žalost, mi nismo više u stanju da se
              borimo protiv zloupotreba koje su prisutne u ovom poslu i sa
              činjenicom da naš region jednostavno nije spreman za
              digitalizaciju i transparentno poslovanje. <br />
              <br />
              Nadamo se da nam svima dolaze neka bolja i srećnija vremena.{' '}
              <br />
              <br />
              Za dalje informacije o daljem toku razvoja, molimo vas da nas
              kontaktirate putem mejla{' '}
              <a href="mailto:support@vozzi.app" rel="noreferrer">
                support@vozzi.app
              </a>
            </p>
          )}
        </div>
      </>
    );
  }
}
export default WelcomePage;
