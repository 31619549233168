import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

import Loader from 'components/Loader';

import { authStore } from 'data/stores/AuthStore';
import { translationStore } from 'data/stores/TranslationStore';
import { warehouseStore } from './WarehousePageStore';

import NumOfCardsAndOrder from './components/NumOfCardsAndOrder/NumOfCardsAndOrder';
import CheckCardValidation from './components/CheckCardValidation/CheckCardValidation';
import CardInfoModal from './components/CheckCardValidation/CardInfoModal';

@observer
class WarehousePage extends Component {
  componentDidMount() {
    const { mapObject } = authStore;

    warehouseStore.fetchWarehouseData(mapObject.id);

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  orderMoreCards = () => {
    const { translations } = translationStore;

    Swal.fire({
      title: translations.warehouseOrderDialogTitle,
      input: 'number',
      showCancelButton: false,
      confirmButtonText: translations.warehouseOrderDialogConfirmButton,
      showLoaderOnConfirm: true,
      preConfirm: (numberOfCards: any) => {
        warehouseStore.orderMoreCards(numberOfCards);
      }
    });
  };

  render() {
    const { translations } = translationStore;
    const { warehouseData, showModal, closeModal } = warehouseStore;

    if (!warehouseData || !translations) {
      return <Loader />;
    }

    return (
      <>
        <Row>
          <Col md={4}>
            <NumOfCardsAndOrder
              translations={translations}
              warehouseData={warehouseData}
            />
          </Col>
          <Col md={4}>
            <CheckCardValidation translations={translations} />
          </Col>
        </Row>
        <CardInfoModal
          show={showModal}
          onHide={closeModal}
          translations={translations}
        />
      </>
    );
  }
}

export default WarehousePage;
