import { action, observable } from 'mobx';
import moment from 'moment';

import accidentsRepo from 'data/repositories/AccidentsRepo';
import commentsRepo from 'data/repositories/CommentsRepo';

import { DATETIME_FORMAT, REQUEST_DATETIME_FORMAT } from 'lib/constants';
import { Accident, Comments, Location, WorkOrder } from 'data/types';
import to from 'lib/awaitTo';
import { Toast } from 'lib/dialogs';

class AccidentDetailsStore {
  @observable accident?: Accident;

  @observable woDataFromAccident?: Accident;

  includes = [
    'clientB2b',
    'clientB2b.country',
    'companyVehicle.country',
    'accidentType',
    'accidentFaultClassification',
    'accidentClub',
    'country',
    'driver.company',
    'files',
    'accidentOffices.accidentOfficeType',
    'accidentOffices.netsuiteItem',
    'accidentWorkOrders.companyVehicle.company',
    'accidentWorkOrders.damagedVehicleParts',
    'accidentWorkOrders.media',
    'accidentWorkOrders.interventionFaultCode',
    'accidentInfo.createdBy',
    'accidentInfo.workingOnUser',
    'accidentInfo.lastService',
    'workedOnUsers',
    'aLocation',
    'bLocation',
    'cLocation',
    'comments.createdBy',
    'files',
    'b2bSentMessages.userSender',
    'b2bSentMessages.sentEmail',
    'sentEmail',
    'destinationRepairer'
  ];

  async getAccident(accidentId: string) {
    const [res, err] = await to(
      accidentsRepo.getAccidentById(accidentId, {
        params: { include: this.includes }
      })
    );

    if (err) {
      Toast.fire({
        type: 'error',
        title: 'Something went wrong with fetching the Accident'
      });
    }
    if (res) {
      this.setAccident(res);
      this.setWoDataFromAccident(res);

      if (res.driver) {
        this.getAccidentLocations(
          res.driver.id,
          res.id,
          res.timeAccidentCreatedAt,
          res.timeAccidentFinishedAt
        );
      }
    }
  }

  @action
  setAccident(acc: Accident) {
    this.accident = acc;
  }

  @action
  setWoDataFromAccident(acc: Accident) {
    this.woDataFromAccident = acc;
  }

  @observable choosenWorkOrder?: WorkOrder;

  @action
  setChoosenWorkOrder(workOrder?: WorkOrder) {
    this.choosenWorkOrder = workOrder;
  }

  uploadFile = async (data: any, id: string) => {
    const [response] = await to(accidentsRepo.uploadAccidentFile(data, id));

    if (response) {
      Toast.fire({
        type: 'success',
        title: 'You successfully uploaded file'
      });
      this.getAccident(id);
    }
  };

  deleteFile = async (accidentId: string, fileId: string) => {
    const [response] = await to(
      accidentsRepo.deleteAccidentFile(accidentId, fileId)
    );

    if (response) {
      Toast.fire({
        type: 'success',
        title: 'You successfully deleted file'
      });
      this.getAccident(accidentId);
    }
  };

  @observable locationHistory?: Location[];

  getAccidentLocations = async (
    driverId: string,
    accidentId: string,
    fromDate: string,
    toDate: string
  ) => {
    const data = {
      fromDateTime: moment(fromDate).format(REQUEST_DATETIME_FORMAT),
      toDateTime: moment(toDate).format(REQUEST_DATETIME_FORMAT)
    };

    const [res] = await to(
      accidentsRepo.getAccidentLocations(driverId, accidentId, data)
    );

    if (res) {
      this.setLocation(res);
    }
  };

  @action
  setLocation(res: any) {
    this.locationHistory = res.data.map((location: any) => ({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng),
      createdAt: moment(location.createdAt.date).format(DATETIME_FORMAT)
    }));
  }

  addComment = async (data: any, accidentId: string) => {
    const [response] = await to(
      commentsRepo.addAccidentCommentRepo(data, accidentId)
    );

    if (response) {
      Toast.fire({
        type: 'success',
        title: 'Comment has been added'
      });

      this.getAccident(accidentId);
    }
  };

  deleteComment = async (comment: Comments, accidentId: string) => {
    const [response] = await to(commentsRepo.delete(comment.id));

    if (response) {
      Toast.fire({
        type: 'success',
        title: 'Comment has been removed'
      });
      this.getAccident(accidentId);
    }
  };

  @action
  clearState = () => {
    this.accident = undefined;
  };

  @action
  clearWoData = () => {
    this.woDataFromAccident = undefined;
  };
}

export const accidentDetailsStore = new AccidentDetailsStore();
export default AccidentDetailsStore;
