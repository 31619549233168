import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { navigate } from 'lib/routerHelper';

import { bundlesStore } from 'data/stores/BundlesStore';
import { warehouseStore } from 'pages/Warehouse/WarehousePageStore';
import { buyPackageStore } from 'data/stores/BuyPackageStore';
import { authStore } from 'data/stores/AuthStore';
import { translationStore } from 'data/stores/TranslationStore';

import Card from 'components/Card';
import BundleWithFeaturesInfoPrint from 'components/BundleWithFeaturesInfoPrint';
import PrintComponent from 'components/PrintComponent';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';
import Loader from 'components/Loader';

import SendEmailModal from './components/SendEmailModal';

const styleLi = {
  marginBottom: '15px',
  fontSize: '16px'
};
@observer
class BoughtPackageAnonymousPage extends Component<RouterProps> {
  componentDidMount() {
    const { mapObject } = authStore;
    const { boughtBundle } = buyPackageStore;
    const { history } = this.props;

    if (!boughtBundle) {
      navigate('choosePackages', { history });
    }

    if (!warehouseStore.warehouseData) {
      warehouseStore.fetchWarehouseData(mapObject.id);
    }

    if (!translationStore.translations) {
      translationStore.checkForTranslations();
    }
  }

  componentWillUnmount() {
    buyPackageStore.clearAnonymousState();
  }

  navigateBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { translations } = translationStore;
    const { bundles, categoryTitles, territoryTitle } = bundlesStore;
    const { warehouseData } = warehouseStore;
    const {
      createdClientBundle,
      showSendEmailModal,
      openSendEmailModal,
      closeSendEmailModal,
      anonymousInformations,
      boughtBundle,
      isItIno
    } = buyPackageStore;

    if (bundles.length === 0 || !warehouseData || !translations) {
      return <Loader />;
    }

    if (!boughtBundle) {
      return <h2>{translations.somethingWentWrong}</h2>;
    }

    return (
      <>
        <Row>
          <Col md={8}>
            <h2>
              {translations.package} <b>{boughtBundle.name}</b>{' '}
              {translations.createdPackageText}
            </h2>
            <ul>
              <li style={styleLi}>{translations.boughtPackageAnonymousInfo}</li>
              <li style={styleLi}>
                {translations.packageActivatedPrintDocs}
                <ul style={{ marginTop: '15px' }}>
                  <li style={styleLi}>
                    <PrintComponent
                      pageOne={
                        <BundleWithFeaturesInfoPrint
                          selectedBundle={boughtBundle}
                          categoryTitles={categoryTitles}
                          territoryTitle={territoryTitle}
                          categoryTitleIndex={isItIno ? 1 : 0}
                          clientBundle={createdClientBundle}
                        />
                      }
                      pageTwo={
                        isItIno && (
                          <BundleWithFeaturesInfoPrint
                            pageTwo
                            selectedBundle={boughtBundle}
                            categoryTitles={categoryTitles}
                            categoryTitleIndex={isItIno ? 0 : 1}
                            clientBundle={createdClientBundle}
                          />
                        )
                      }
                      buttonText={translations.printLinkPackageContent}
                    />
                  </li>
                </ul>
              </li>

              <li style={styleLi}>
                {translations.boughtPackageAnonymousQRInfo}
              </li>
              {anonymousInformations && (
                <img
                  alt="qr-code"
                  src={anonymousInformations.promotionCodeUrl}
                />
              )}
            </ul>
          </Col>

          <Col md={4}>
            <Card>
              <p>{translations.boughtPackageAnonymousSendEmail}</p>
              <Button
                onClick={() =>
                  openSendEmailModal(anonymousInformations.promotionCode)
                }
                bsStyle="success"
                fill
                wd
                style={{ width: '100%' }}
              >
                {translations.boughtPackageAnonymousSendEmailButton}
              </Button>
            </Card>
            <Card>
              <p>{translations.packageActivatedDeactivateText}</p>
              <Link to="/dashboard/stats">
                <Button bsStyle="danger" fill wd style={{ width: '100%' }}>
                  {translations.deactivateButton}
                </Button>
              </Link>
            </Card>
          </Col>
        </Row>
        <SendEmailModal
          showModal={showSendEmailModal}
          closeModalForm={closeSendEmailModal}
        />
      </>
    );
  }
}

export default BoughtPackageAnonymousPage;
